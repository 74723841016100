import styled from 'styled-components';
import { SuccessMessage, ErrorMessage } from '../../../components/Layout/Auth/styles';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
`;

interface IPageContainerProps {
  centered: boolean;
}

export const PageContainer = styled.div<IPageContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ centered }) => centered ? 'center' : 'flex-start'};
  flex: 1;
  width: 100%;

  ${ErrorMessage} {
    margin-top: 1.6rem;
    margin-bottom: -1.6rem;
  }
  ${SuccessMessage} {
    margin-top: 1.6rem;
    margin-bottom: -1.6rem;
  }
`;
