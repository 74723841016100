import { useState } from 'react';
import { shallowEqual } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store';
import { TypeForm, resetAll, setArtistsResults } from '../../../store/formSlice';
import useAlert from '../../../hooks/useAlert';
import Api from '../../../helpers/Api';
import { DateValue, IArtist, IBulkCreateResponse, IShow, IShowType, IVenue } from '../../../types';

import Loader from '../../Elements/Loader';

import { ErrorMessage } from '../Search/styles';
import { GreenButton, GreyButton } from '../../Elements/Buttons/styles';
import {
  CommentTextarea,
  DatePickerContainer,
  Actions,
  SeatedContainer,
  CheckboxContainer,
  SeatedCheckbox,
  CheckboxChoice,
  PriceInput,
} from './styles';

interface IAdditionalInfoFormProps {
  goToPreviousStep: () => void;
  showSaved: (show: IShow, typeForm: TypeForm) => void;
}

const AdditionalInfoForm = ({ goToPreviousStep, showSaved }: IAdditionalInfoFormProps) => {
  const { setAlert } = useAlert();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch() as AppDispatch;
  const {
    artistsResults,
    venueResults,
    venueSelectedResult,
    searchResults,
    searchSelectedResult,
    searchFormValues,
    typeForm,
  } = useAppSelector(
    (state) => ({
      artistsResults: state.form.artists.results,
      venueResults: state.form.venue.results,
      venueSelectedResult: state.form.venue.selectedResult,
      searchResults: state.form.search.results,
      searchSelectedResult: state.form.search.selectedResult,
      searchFormValues: state.form.search.values,
      typeForm: state.form.type,
    }),
    shallowEqual,
  );
  const dateFromSearch =
    searchResults && searchSelectedResult > -1 && searchResults[searchSelectedResult].date
      ? searchResults[searchSelectedResult].date
      : '';
  const [date, setDate] = useState<DateValue | string>(searchFormValues.date || dateFromSearch);
  const [comment, setComment] = useState(searchResults[searchSelectedResult]?.comment);
  const [seated, setSeated] = useState(!!searchResults[searchSelectedResult]?.seated);
  const [price, setPrice] = useState(searchResults[searchSelectedResult]?.price);

  const saveUserShow = async () => {
    const show = searchResults[searchSelectedResult];
    let venueId: number = -1;

    if (venueResults.length > 0 && venueSelectedResult > -1 && !date) {
      setError('You have to provide the date of the show.');
      return;
    }

    setLoading(true);

    try {
      let newArtistsResults: IArtist[] = [...artistsResults];
      // STEP 1 : Create artists that are not already in the database
      let artistsToCreate = artistsResults.filter((artist) => !artist.id);
      if (artistsToCreate.length) {
        const responseArtists = await Api.request<IArtist[]>(
          '/artists',
          {
            artists: artistsToCreate,
          },
          'post',
        );

        if (responseArtists.length === artistsToCreate.length) {
          artistsToCreate = artistsToCreate.map((artist, index) => ({
            ...artist,
            id: responseArtists[index].id,
          }));
          const artistsResultsWithIds = artistsResults.map((artist) => {
            const artistToReturn = { ...artist };
            const artistJustCreatedFound = artistsToCreate.find(
              (artistToCreate) =>
                artistToCreate.setlistFmId === artist.setlistFmId && artistToCreate.name === artist.name,
            );
            if (artistJustCreatedFound) {
              artistToReturn.id = artistJustCreatedFound.id;
            }
            return artistToReturn;
          });
          newArtistsResults = artistsResultsWithIds;
          dispatch(setArtistsResults(artistsResultsWithIds));
        } else {
          setLoading(false);
          setError('There was an issue creating the artists in the database.');
          // @TODO: Fetch again the artists and update the list with the ones that has been found
          return;
        }
      }

      // STEP 2 : Create venue if doesn't exist in database
      let venue;

      if (venueResults.length && venueSelectedResult > -1 && venueResults[venueSelectedResult]) {
        venue = venueResults[venueSelectedResult];
      } else {
        venue = show.venue;
      }

      const responseVenues = await Api.request<IVenue[]>('/venues', {
        name: venue.name,
        setlistFmId: venue.setlistFmId,
        cityName: venue.cityName,
        country: venue.countryCode,
      });

      if (responseVenues?.length === 0 || (responseVenues?.length > 0 && responseVenues[0].source === 'setlist.fm')) {
        // Create the venue
        const responseCreateVenue = await Api.request<IVenue>(
          '/venues',
          {
            name: venue.name,
            setlistFmId: venue.setlistFmId,
            cityName: venue.cityName,
            countryName: venue.countryName,
            countryCode: venue.countryCode,
            state: venue.state,
            coords: venue.coords,
          },
          'post',
        );

        if (responseCreateVenue?.id) {
          venueId = responseCreateVenue.id;
        }
      } else {
        venueId = responseVenues[0].id;
      }

      // STEP 3 : Create the show
      // Fetch the show types
      const responseShowTypeConcert = await Api.request<{
        data: IShowType[];
      }>('/show-types', {
        filters: {
          name: 'Concert',
        },
      });
      const showTypeId = responseShowTypeConcert?.data.length === 1 ? responseShowTypeConcert.data[0].id : 1;

      if (venueId && showTypeId && date) {
        const requestMethod = typeForm === 'edit' ? 'put' : 'post';
        const requestUrl = typeForm === 'edit' && show.id ? `/shows/${show.id}` : '/shows';
        const isFutureShow = moment(date as string).isAfter(moment());

        const responseRequestShow = await Api.request<IShow>(
          requestUrl,
          {
            data: {
              venue: venueId,
              showType: showTypeId, // @TODO: Add festival support,
              date: moment(date as string).format('YYYY-MM-DD'),
              artists: [...newArtistsResults.filter((artist) => artist.seen).map((artist) => artist.id)],
              comment,
              pending: isFutureShow,
              price,
              seated,
            },
          },
          requestMethod,
        );

        if (responseRequestShow) {
          // Reset all data and close the modal¸
          setAlert(typeForm === 'edit' ? 'Show updated!' : 'Show added to your list!', 'success');
          showSaved(responseRequestShow, typeForm);
          dispatch(resetAll());
        } else {
          setError('An error occured. Try again and if the issue persist, please contact the administrator.');
        }
      } else {
        throw new Error(
          'Missing information to create show. Please refresh the page and try again. If the problem persist, please contact the administrator.',
        );
      }
      setLoading(false);
    } catch (e: any) {
      if (e.message) {
        setError(e.message);
      } else {
        setError(
          'An error occured trying to save this show. Try again and if the issue persist, please contact the administrator.',
        );
      }
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <h3>Additional information</h3>
      <form>
        <CommentTextarea
          placeholder="Comment about the show (optional)"
          name="comment"
          onInput={(e) => setComment(e.currentTarget.value)}
          value={comment || ''}
        />
        <div>
          <SeatedContainer>
            <div>Seated</div>
            <SeatedCheckbox
              placeholder="Seated"
              name="seated"
              onChange={(e) => setSeated(e.currentTarget.checked)}
              checked={seated || false}
              type="checkbox"
            />
            <CheckboxContainer>
              <CheckboxChoice selected={seated}>Yes</CheckboxChoice>
              <CheckboxChoice selected={!seated}>No</CheckboxChoice>
            </CheckboxContainer>
          </SeatedContainer>
        </div>
        <label>
          <p>
            Price (optional)
            <br />
            <i>(just the number, no currency needed)</i>
          </p>
          <PriceInput type="number" value={price || ''} onChange={(e) => setPrice(e.currentTarget.valueAsNumber)} />
        </label>
        {venueResults.length > 0 && venueSelectedResult > -1 && (
          <DatePickerContainer>
            <p>Confirm the date of the show</p>
            <DatePicker
              onChange={setDate}
              value={date ? moment(date as unknown as string).toDate() : null}
              disabled={loading}
              dayPlaceholder={'DD'}
              monthPlaceholder={'MM'}
              yearPlaceholder={'YYYY'}
              format={'y-MM-dd'}
              openCalendarOnFocus={false}
              showLeadingZeros
            />
          </DatePickerContainer>
        )}
      </form>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Actions>
        <GreyButton onClick={goToPreviousStep}>Back</GreyButton>&nbsp;
        <GreenButton onClick={saveUserShow}>Save</GreenButton>
      </Actions>
    </div>
  );
};

export default AdditionalInfoForm;
