import { shallowEqual } from 'react-redux';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { useAppSelector } from '../../../store';
import { IShow, Theme } from '../../../types';
import { months } from '../../../constants';
import { formatPriceWithCurrency, getCountryCurrency } from '../../../utils/price';

import { CommentIcon, DeleteIcon, EditIcon } from '../../Elements/Icons';
import {
  ShowCardContainer,
  CardOuterContent,
  CardInnerContent,
  ArtistsNames,
  ShowInfo,
  Details,
  AdditionalDetails,
  Price,
  VenueInfo,
  VenueName,
  City,
  DateContainer,
  Day,
  Month,
  Year,
  DeleteButton,
  CommentSection,
  CommentButton,
  CommentDetails,
  EditButton,
  BackgroundImage,
} from './styles';

interface IShowCardProps {
  editUserShow?: (userShow: IShow) => void;
  index?: number;
  readonly?: boolean;
  setDeleteUserShow?: React.Dispatch<React.SetStateAction<IShow | null>>;
  setDetailsUserShow?: React.Dispatch<React.SetStateAction<IShow | null>>;
  userShow: IShow;
}

const ShowCard = ({
  editUserShow,
  index = 0,
  readonly = false,
  setDeleteUserShow,
  setDetailsUserShow,
  userShow,
}: IShowCardProps) => {
  const theme = useTheme() as Theme;
  const { countries } = useAppSelector(
    (state) => ({
      countries: state.api.countries,
    }),
    shallowEqual,
  );
  const [year, month, day] = userShow.date.split('-');

  const { artists, comment, date, pending, price, seated, venue } = userShow;
  const isToday = moment(date).isSame(moment(), 'day');

  return (
    <ShowCardContainer>
      <CardOuterContent even={index % 2}>
        <CardInnerContent
          as={readonly ? 'div' : 'button'}
          even={index % 2}
          readonly={readonly}
          onClick={() => !readonly && setDetailsUserShow && setDetailsUserShow(userShow)}
          pending={pending}
          isToday={isToday}
        >
          {artists[0]?.cover && (
            <BackgroundImage pending={pending} coverImage={!!artists[0]?.cover}>
              <img src={`${process.env.REACT_APP_API_URL}${artists[0].cover.url}`} loading="lazy" alt="" />
            </BackgroundImage>
          )}
          <ShowInfo>
            <DateContainer>
              <Day>{day}</Day>
              <Month>{months[parseInt(month) - 1].substring(0, 3)}</Month>
              <Year>{year}</Year>
            </DateContainer>
            <ArtistsNames>
              {artists.map((artist, index: number) => `${artist.name}${index < artists.length - 1 ? ' + ' : ''}`)}
            </ArtistsNames>
          </ShowInfo>
          <Details>
            <AdditionalDetails>
              {price && (
                <Price>{formatPriceWithCurrency(price, getCountryCurrency(countries, venue.countryCode))}</Price>
              )}
              <p>{seated ? 'Seated' : 'General Admission'}</p>
            </AdditionalDetails>
            <VenueInfo>
              <VenueName>{venue.name}</VenueName>
              <City>
                {venue.cityName} ({venue.countryCode})
              </City>
            </VenueInfo>
            {comment && (
              <CommentSection>
                <CommentButton tabIndex={0}>
                  <CommentIcon />
                </CommentButton>
                <CommentDetails>{comment}</CommentDetails>
              </CommentSection>
            )}
          </Details>
        </CardInnerContent>
        {!readonly && editUserShow && (
          <EditButton onClick={() => editUserShow(userShow)} aria-label="Edit the show">
            <EditIcon color={theme.colors['GoldTips']} />
          </EditButton>
        )}
        {!readonly && setDeleteUserShow && (
          <DeleteButton onClick={() => setDeleteUserShow(userShow)} aria-label="Delete the show">
            <DeleteIcon color={theme.colors['GoldTips']} />
          </DeleteButton>
        )}
      </CardOuterContent>
    </ShowCardContainer>
  );
};

export default ShowCard;
