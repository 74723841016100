import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const CheckIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 74 74" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-13,-965.36218)">
        <path
          d="m 50,965.36218 c -2.2092,0 -4,1.7909 -4,4 v 29.0001 H 17 c -2.2091,0 -4,1.79082 -4,4.00002 0,2.2092 1.7909,4 4,4 h 29 v 28.9999 c 0,2.2091 1.7908,4 4,4 2.2092,0 4,-1.7909 4,-4 v -28.9999 h 29 c 2.2091,0 4,-1.7908 4,-4 0,-2.2092 -1.7909,-4.00002 -4,-4.00002 H 54 v -29.0001 c 0,-2.2091 -1.7908,-4 -4,-4 z"
          fill={color || theme.colors['Black']}
        />
      </g>
    </svg>
  );
};

export default CheckIcon;
