import styled, { keyframes } from 'styled-components';
import { min } from '../../../theme/breakpoints';

export const glowPluseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

export const Details = styled.div`
  padding: 2rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors['Casper']};

  > * {
    z-index: 2;
    position: relative;
    margin: 1rem 0;

    svg {
      opacity: 0.5;
      position: absolute;
      min-width: 1.6rem;
      height: 1.6rem;
      transform: translate(-120%, -50%);

      ${min('tablet')} {
        min-width: 2.4rem;
        height: 2.4rem;
        transform: translate(-150%, -50%);
      }
    }
  }
`;

export const DateFormatted = styled.div`
  font-family: ${({ theme }) => theme.fonts.young};
  color: ${({ theme }) => theme.colors['GoldTips']};
  font-size: 1.6rem;

  ${min('mobile')} {
    font-size: 2.4rem;
  }
  ${min('tablet')} {
    font-size: 3.2rem;
  }
`;

export const Artists = styled.ul`
  list-style: none;
  padding: 0;
`;

interface IArtistProps {
  seen: boolean;
}

export const Artist = styled.li<IArtistProps>`
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  text-transform: uppercase;
  font-size: ${({ seen }) => seen ? '2.4rem' : '2rem'};
  color: ${({ theme, seen }) => seen ? theme.colors['White'] : theme.colors['WhiteRock']};

  ${min('tablet')} {
    font-size: ${({ seen }) => seen ? '3.2rem' : '2.8rem'};
  }
  ${min('desktop')} {
    font-size: ${({ seen }) => seen ? '3.6rem' : '3rem'};
  }
`;

export const Venue = styled.div`
  font-family: ${({ theme }) => theme.fonts.young};
  text-transform: uppercase;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors['LightRed']};

  ${min('mobile')} {
    font-size: 1.8rem;
  }
  ${min('tablet')} {
    font-size: 2.4rem;
  }
  ${min('desktop')} {
    font-size: 3.2rem;
  }
`;

export const Location = styled.div`
`;

export const City = styled.div`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 900;
  text-transform: uppercase;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors['LightGreen']};

  ${min('mobile')} {
    font-size: 1.8rem;
  }
  ${min('tablet')} {
    font-size: 2.4rem;
  }
`;

export const Country = styled.div`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors['LightGreen']};

  ${min('tablet')} {
    font-size: 2rem;
  }
`;

export const CommentSection = styled.div`
  max-width: 60rem;
`;

export const Comment = styled.div`
  margin-top: 1.6rem;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors['WhiteRock']};

  ${min('tablet')} {
    font-size: 1.8rem;
  }
`;

export const SetlistLink = styled.a`
  margin-left: 1rem;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors['SilverChalice']};
  text-transform: initial;
  display: flex;
  justify-content: center;

  svg {
    position: relative;
    transform: none;
    height: 1.6rem;
    min-width: auto;
    margin-left: 0.4rem;
  }
`;

export const FireworksContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
`;

const fireworkAnimation = keyframes`
  0% { transform: translate(var(--x), var(--initialY)); width: var(--initialSize); opacity: 1; }
  50% { width: 0.5vmin; opacity: 1; }
  100% { width: var(--finalSize); opacity: 0; }
`;

export const Fireworks = styled.div`
  &,
  &::before,
  &::after {
    --initialSize: 0.5vmin;
    --finalSize: 45vmin;
    --particleSize: 0.2vmin;
    --color1: yellow;
    --color2: khaki;
    --color3: white;
    --color4: lime;
    --color5: gold;
    --color6: mediumseagreen;
    --y: -30vmin;
    --x: -50%;
    --initialY: 60vmin;
    content: "";
    animation: ${fireworkAnimation} 2s infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, var(--y));
    width: var(--initialSize);
    aspect-ratio: 1;
    background: 
      /*
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 0%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 0%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 100%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 100%,
      */
      
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 50% 0%,
      radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
      radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
      radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%,
      
      /* bottom right */
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 80% 90%,
      radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 95% 90%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 90% 70%,
      radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 60%,
      radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 55% 80%,
      radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 70% 77%,
      
      /* bottom left */
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 22% 90%,
      radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 45% 90%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 70%,
      radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 10% 60%,
      radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 31% 80%,
      radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 28% 77%,
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 13% 72%,
      
      /* top left */
      radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 80% 10%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 95% 14%,
      radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 90% 23%,
      radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 100% 43%,
      radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 85% 27%,
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 77% 37%,
      radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 60% 7%,
      
      /* top right */
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 22% 14%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 45% 20%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 34%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 10% 29%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 31% 37%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 28% 7%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 13% 42%
      ;
    background-size: var(--initialSize) var(--initialSize);
    background-repeat: no-repeat;
  }

  &::before {
    --x: -50%;
    --y: -50%;
    --initialY: -50%;
  /*   transform: translate(-20vmin, -2vmin) rotate(40deg) scale(1.3) rotateY(40deg); */
    transform: translate(-50%, -50%) rotate(40deg) scale(1.3) rotateY(40deg);
  /*   animation: fireworkPseudo 2s infinite; */
  }
  &::after {
    --x: -50%;
    --y: -50%;
    --initialY: -50%;
  /*   transform: translate(44vmin, -50%) rotate(170deg) scale(1.15) rotateY(-30deg); */
    transform: translate(-50%, -50%) rotate(170deg) scale(1.15) rotateY(-30deg);
  /*   animation: fireworkPseudo 2s infinite; */
  }

  &:nth-child(2) {
    --x: 30vmin;
  }

  &:nth-child(2),
  &:nth-child(2)::before,
  &:nth-child(2)::after {
    --color1: pink;
    --color2: violet;
    --color3: fuchsia;
    --color4: orchid;
    --color5: plum;
    --color6: lavender;  
    --finalSize: 40vmin;
    left: 30%;
    top: 60%;
    animation-delay: -0.25s;
  }
  &:nth-child(3) {
    --x: -30vmin;
    --y: -50vmin;
  } 
  &:nth-child(3),
  &:nth-child(3)::before,
  &:nth-child(3)::after {
    --color1: cyan;
    --color2: lightcyan;
    --color3: lightblue;
    --color4: PaleTurquoise;
    --color5: SkyBlue;
    --color6: lavender;
    --finalSize: 35vmin;
    left: 70%;
    top: 60%;
    animation-delay: -0.4s;
  }
`;

export const MoreInfo = styled.div``;

export const MoreInfoLink = styled.a``;

export const MoreInfoContainer = styled.ul`
  list-style: none;
  padding: 0;
`;
