import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../utils/AuthContext';

import LayoutAuthPage from '../../../components/Layout/Auth';
import Loader from '../../../components/Elements/Loader';

import { ErrorMessage, FormContainer, SubmitButton, SuccessMessage } from '../../../components/Layout/Auth/styles';
import { PasswordInput, TextInput, EmailInput } from './styles';

const RegisterPage = () => {
  const { registerUser } = useAuth();
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessageDisplayed, setSuccessMessageDisplayed] = useState(false);

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    if (password !== passwordConfirmation) {
      setError("Passwords don't match");
      return;
    }
    setLoading(true);
    registerUser(firstName, lastName, username, email, password)
      .then(() => {
        setLoading(false);
        setSuccessMessageDisplayed(true);
      })
      .catch((response: any) => {
        const errorMessage = response?.data?.error?.message;
        if (errorMessage) {
          setError(errorMessage);
        }
        setLoading(false);
      });
  };

  return (
    <LayoutAuthPage title="Sign up">
      {loading && <Loader />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <FormContainer onSubmit={submitForm}>
        {successMessageDisplayed ? (
          <SuccessMessage>
            Account created!
            <br />
            Click on the link we just sent you to be able to login.
          </SuccessMessage>
        ) : (
          <>
            <TextInput
              type="text"
              name="username"
              placeholder="Username"
              required
              value={username}
              onInput={(e) => setUsername(e.currentTarget.value)}
            />
            <TextInput
              type="text"
              name="firstName"
              placeholder="First name"
              required
              value={firstName}
              onInput={(e) => setFirstName(e.currentTarget.value)}
            />
            <TextInput
              type="text"
              name="lastName"
              placeholder="Last name"
              required
              value={lastName}
              onInput={(e) => setLastName(e.currentTarget.value)}
            />
            <EmailInput
              type="email"
              name="email"
              placeholder="E-mail"
              required
              value={email}
              onInput={(e) => setEmail(e.currentTarget.value)}
            />
            <PasswordInput
              type="password"
              name="password"
              placeholder="Password"
              required
              value={password}
              onInput={(e) => setPassword(e.currentTarget.value)}
            />
            <PasswordInput
              type="password"
              name="passwordConfirmation"
              placeholder="Confirm Password"
              required
              value={passwordConfirmation}
              onInput={(e) => setPasswordConfirmation(e.currentTarget.value)}
            />
            <SubmitButton type="submit">Create</SubmitButton>
          </>
        )}
        <Link to={'/login'}>Back to login</Link>
      </FormContainer>
    </LayoutAuthPage>
  );
};

export default RegisterPage;
