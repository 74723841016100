import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const SadCactusIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 382.9 510">
      <path
        d="M357.1,306.7h-12v-57.5h18.4c5.5,0,10-4.5,10-10s-4.5-10-10-10h-18.4v-45.9c0-10.3-1-20.5-3-30.5l16.5-6
	c5.1-2.2,7.4-8.1,5.2-13.1c-2-4.6-7.1-7.1-12-5.7l-14.9,5.4c-7.9-23.3-21.1-44.4-38.6-61.7L309.3,59c3.5-4.3,2.8-10.6-1.5-14.1
	c-4.1-3.4-10.2-2.9-13.7,1.1l-10.8,12.6c-24.6-18.8-54.1-29.8-85-31.7V10c0-5.5-4.5-10-10-10s-10,4.5-10,10v16.9
	c-30.8,2-60.4,13-84.9,31.8L82.5,46c-3.7-4.1-10-4.4-14.1-0.7c-4,3.6-4.4,9.6-1.1,13.7l11,12.8C60.8,89,47.6,110.2,39.8,133.4
	L24.7,128c-5.2-1.9-10.9,0.8-12.8,6c-1.9,5.2,0.8,10.9,6,12.8l0,0l16.7,6c-2,10-3,20.3-3,30.5v45.9H13.1c-5.5,0-10,4.5-10,10
	s4.5,10,10,10h18.6v57.5h-5.8C11.5,306.7,0,318.2,0,332.4v29.2c0,14.2,11.6,25.8,25.8,25.8h5.4l27.6,93.8
	c5.1,17,20.7,28.7,38.4,28.7h182.4c17.7-0.1,33.3-11.7,38.4-28.7l27.6-93.8h11.7c14.2,0,25.8-11.5,25.8-25.8v-29.2
	C382.9,318.2,371.4,306.7,357.1,306.7z M51.6,183.3c0-75.4,61.4-136.8,136.8-136.8c63,0,116.2,42.8,132,100.9
	c0.1,0.8,0.3,1.6,0.5,2.4c0.1,0.2,0.1,0.4,0.2,0.6c2.7,10.8,4,21.9,4,33v54.1c-0.2,1.2-0.2,2.3,0,3.5v65.7H51.6V183.3z M298.7,475.6
	c-2.5,8.5-10.3,14.3-19.2,14.4H97.1c-8.9,0-16.7-5.9-19.2-14.4L52,387.4h272.6L298.7,475.6z M362.9,361.6c0,3.2-2.6,5.8-5.8,5.8
	H25.8c-3.2,0-5.8-2.6-5.8-5.8c0,0,0,0,0,0v-29.2c0-3.2,2.6-5.8,5.8-5.8h331.4c3.2,0,5.8,2.6,5.8,5.8V361.6z"
        fill={color || theme.colors['Black']}
      />
      <circle cx="113.2" cy="183.2" r="19.3" fill={color || theme.colors['Black']} />
      <circle cx="263.4" cy="183.2" r="19.3" fill={color || theme.colors['Black']} />
      <path
        d="M231.3,237.7c-0.4-1.1-9.1-27.3-43-27.3s-42.7,26.2-43,27.3l0,0c-0.3,1-0.5,2.1-0.5,3.2c0,5.5,4.4,10,9.9,10.1
	c4.4,0,8.3-2.8,9.6-7.1c0.7-1.8,5.8-13.4,24-13.4s23.3,11.6,24,13.4c1.6,5.3,7.2,8.2,12.5,6.6c4.2-1.3,7.1-5.2,7.1-9.6
	C231.9,239.8,231.7,238.7,231.3,237.7L231.3,237.7z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default SadCactusIcon;
