import { createGlobalStyle } from 'styled-components';
import { min } from './theme/breakpoints';
import { Theme } from './types/theme';

const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  html {
    font-size: 62.5%;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors['WoodsmokePrimary']};
    color: ${({ theme }) => theme.colors['White']};
    padding: 0;
    text-align: center;
    font-size: 1.6rem;
  }

  * {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.montserrat};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors['Casper']};
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: ${({ theme }) => theme.colors['Casper']};
  }

  a,
  button,
  .button,
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="checkbox"],
  input[type="radio"],
  input[type="number"],
  textarea,
  span {
    &:focus-visible {
      &,
      + label {
        outline: 2px solid ${({ theme }) => theme.colors['GoldTips']};
        outline-offset: 2px;

        svg path, svg rect {
          fill: ${({ theme }) => theme.colors['White']} !important;
        }
      }
    }
  }

  button,
  input[type="text"],
  input[type="password"],
  input[type="number"]:not(.react-date-picker__inputGroup__input),
  input[type="email"],
  textarea,
  .button {
    font-size: 1.4rem;
    line-height: 1.8rem;
    appearance: none;
    border: 0;
    padding: 1rem 1.6rem;
    border-radius: 5rem;

    &:disabled {
      cursor: not-allowed;
      color: rgba(255, 255, 255, 0.3);
      background-color: ${({ theme }) => theme.colors['MineShaft']};
    }
    ${min('mobile')} {
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 1.6rem 2rem;
    }
  }

  button:disabled::placeholder,
  .button:disabled::placeholder,
  input[type="text"]:disabled::placeholder,
  input[type="password"]:disabled::placeholder,
  input[type="email"]:disabled::placeholder,
  input[type="number"]:disabled::placeholder,
  textarea:disabled::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"]:not(.react-date-picker__inputGroup__input),
  textarea {
    width: 100%;
    color: ${({ theme }) => theme.colors['White']};
    background-color: ${({ theme }) => theme.colors['Black']};

    ${min('tablet')} {
      width: 20rem;
    }
  }

  textarea {
    border-radius: 2.5rem;
    resize: vertical;
    width: 100%;

    ${min('tablet')} {
      width: 50rem;
    }
  }

  button,
  .button {
    display: inline-block;
    background-color: ${({ theme }) => theme.colors['GoldTips']};
    color: ${({ theme }) => theme.colors['WoodsmokePrimary']};
    cursor: pointer;
  }

  a {
    color: ${({ theme }) => theme.colors['GoldTips']};
  }

  h1, h2, h3, button, .button {
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.young};
  }

  h1, h2, h3 {
    margin-top: 0;
    margin-bottom: 1.6rem;
    text-align: center;
    line-height: 1.15;

    ${min('tablet')} {
      margin-bottom: 3.2rem;
    }
  }

  h1 {
    font-size: 3rem;
    color: ${({ theme }) => theme.colors['Casper']};

    ${min('tablet')} {
      font-size: 5rem;
    }
  }

  h2 {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors['White']};

    ${min('tablet')} {
      font-size: 4rem;
    }
  }

  h3 {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors['GoldTips']};

    ${min('tablet')} {
      font-size: 3rem;
    }
  }

  p, label {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors['SilverChalice']};

    ${min('tablet')} {
      font-size: 1.4rem;
    }
  }

  .custom-select {
    width: 100%;
    
    ${min('tablet')} {
      width: 20rem;
    }
    &.multiple {
      ${min('tablet')} {
        min-height: 6rem;
      }
      .multi-value {
        border-radius: 3rem;
        background-color: ${({ theme }) => theme.colors['GoldTips']};
        padding: 0;

        ${min('tablet')} {
          padding: 0.5rem;
        }
      }
      .multi-value-label {
        font-weight: 900;
        padding-right: 0.5rem;
      }
      .multi-value-remove {
        cursor: pointer;
        color: ${({ theme }) => theme.colors['Black']};
        border-radius: 3rem;
        background-color: ${({ theme }) => theme.colors['LightRed']};
        width: 2.5rem;
        height: 2.5rem;
        justify-content: center;

        svg {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
      .placeholder {
        padding-left: 1rem;
      }
    }
    .selected {
      color: ${({ theme }) => theme.colors['Black']};
      background-color: ${({ theme }) => theme.colors['GoldTips']};
    }
    > div:first-of-type {
      background-color: ${({ theme }) => theme.colors['Black']};
      border-radius: 3rem;
      height: 100%;
      border: 0;
      min-width: 20rem;
      text-align: left;
      
      &[aria-disabled] {
        background-color: ${({ theme }) => theme.colors['MineShaft']};

        > div:first-child {
          > div.placeholder {
            color: rgba(255, 255, 255, 0.3);
          }
        }
      }
      > div:first-child {
        font-size: 1.4rem;
        line-height: 1.8rem;
        padding: 1rem 1.6rem;
        height: 3.8rem;

        ${min('mobile')} {
          font-size: 1.6rem;
          line-height: 2rem;
          padding: 1.6rem 2rem;
          height: 5.2rem;
        }
        &.multi-value-container {
          padding: 0.4rem;

          ${min('mobile')} {
            padding: 1rem 1.6rem;
          }
          ${min('tablet')} {
            padding: 0.6rem 1.6rem;
          }
        }
        > div:first-child {
          color: ${({ theme }) => theme.colors['White']};
        }
        > div.placeholder {
          color: ${({ theme }) => theme.colors['Casper']};
        }
        > div:last-child {
          margin: 0;
          padding: 0;
        }
      }
    }
    > div:last-of-type:not(.control),
    .menu-list {
      z-index: 5;
      color: ${({ theme }) => theme.colors['White']};
      background-color: ${({ theme }) => theme.colors['Black']};
      text-align: left;
    }
  }

  .react-date-picker {
    width: 100%;

    ${min('tablet')} {
      width: auto;
    }

    &--disabled {
      background-color: transparent;

      button:disabled {
        background: transparent;
      }
    }
    .react-date-picker__inputGroup {
      text-align: left;

      .react-date-picker__inputGroup__input {
        font-size: 1.4rem;
        line-height: 1.8rem;
        border-radius: 5rem;

        ${min('tablet')} {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }
    }
    .react-date-picker__wrapper {
      height: 3.8rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
      appearance: none;
      border: 0;
      padding: 1rem 1.6rem;
      border-radius: 5rem;
      width: 100%;
      color: ${({ theme }) => theme.colors['White']};
      background-color: ${({ theme }) => theme.colors['Black']};

      ${min('mobile')} {
        height: 5.2rem;
        font-size: 1.6rem;
        line-height: 2rem;
        padding: 1.6rem 2rem;
      }
      ${min('tablet')} {
        width: 22rem;
      }
    }
    .react-date-picker__inputGroup {
      margin-right: auto;
    }
    .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
    .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
      stroke: ${({ theme }) => theme.colors['Casper']};
    }
    .react-date-picker__button {
      padding: 0 2px;
    }
    .react-date-picker__button svg {
      stroke: ${({ theme }) => theme.colors['White']};
      height: 1.8rem;
    }
    .react-calendar {
      padding: 1rem;
      border: 2px solid ${({ theme }) => theme.colors['Casper']};
      border-radius: 2.5rem;
      color: ${({ theme }) => theme.colors['White']};
      background-color: ${({ theme }) => theme.colors['Black']};

      .react-calendar__navigation button {
        padding: 1rem;
        color: ${({ theme }) => theme.colors['White']};
        background-color: ${({ theme }) => theme.colors['WoodsmokeSecondary']};

        &:disabled {
          cursor: not-allowed;
          color: ${({ theme }) => theme.colors['SilverChalice']};
          background-color: ${({ theme }) => theme.colors['WoodsmokePrimary']};
        }
      }
      .react-calendar__tile {
        color: ${({ theme }) => theme.colors['White']};
        background-color: ${({ theme }) => theme.colors['WoodsmokeSecondary']};

        &.react-calendar__month-view__days__day--weekend {
          color: ${({ theme }) => theme.colors['Casper']};
        }
        &:enabled {
          &:hover,
          &:focus {
            color: ${({ theme }) => theme.colors['Black']};
            background-color: ${({ theme }) => theme.colors['Casper']};
          }
        }
        &:disabled {
          cursor: not-allowed;
          color: ${({ theme }) => theme.colors['SilverChalice']};
          background-color: ${({ theme }) => theme.colors['WoodsmokePrimary']};
        }
      }
    }
  }
`;

export default GlobalStyles;
