import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const CommentIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 82 68.4">
      <path
        d="M71,0H11C4.9,0,0,4.9,0,11v35c0,6.1,4.9,11,11,11h33.8l12,10.2c0.9,0.8,2.1,1.2,3.2,1.2c0.7,0,1.4-0.2,2.1-0.5
   c1.8-0.8,2.9-2.6,2.9-4.5V57h6c6.1,0,11-4.9,11-11V11C82,4.9,77.1,0,71,0z M58.3,38.1L58.3,38.1l-34.5,0v0c-1.7,0-3-1.3-3-3
   s1.3-3,3-3h34.5c1.7,0,3,1.3,3,3C61.3,36.8,59.9,38.1,58.3,38.1z M63.9,23.7L63.9,23.7H18.1h0c-1.7,0-3-1.3-3-3s1.3-3,3-3h0h45.9h0
   c1.7,0,3,1.3,3,3S65.6,23.7,63.9,23.7z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default CommentIcon;
