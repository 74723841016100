import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const MenuMobileAdd = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 75 75">
      <path
        d="M62.5,31.5h-19v-19c0-1.7-1.3-3-3-3h-6c-1.7,0-3,1.3-3,3v19h-19c-1.7,0-3,1.3-3,3v6c0,1.7,1.3,3,3,3h19v19c0,1.7,1.3,3,3,3
	h6c1.7,0,3-1.3,3-3v-19h19c1.7,0,3-1.3,3-3v-6C65.5,32.8,64.2,31.5,62.5,31.5z M35.5,34.5v-21h4v21c0,0.6,0.4,1,1,1h21v4h-21
	c-0.6,0-1,0.4-1,1v21h-4v-21c0-0.6-0.4-1-1-1h-21v-4h21C35.1,35.5,35.5,35.1,35.5,34.5z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default MenuMobileAdd;
