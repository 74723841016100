import styled from 'styled-components';
import { min } from '../../../theme/breakpoints';

export const SearchFormWrapper = styled.div`
  form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    margin-top: 3.2rem;
  }
`;

export const FormActions = styled.div`
  flex: 1 1 100%;
  margin-top: 1rem;
`;

export const ErrorMessage = styled.p`
  color: red;
`;
