import styled from 'styled-components';

export const UsernameInput = styled.input`
  &[type="text"] {
    border-radius: 2.5rem 2.5rem 0 0;
  }
`;

export const PasswordInput = styled.input`
  &[type="password"] {
    border-radius: 0 0 2.5rem 2.5rem;
  }
`;

