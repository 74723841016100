import { CloseIcon } from '../../Elements/Icons';

import { Wrapper, Container, Content, CloseButton } from './styles';

interface IFullscreenCenteredProps {
  visible?: boolean;
  children: React.ReactNode;
  withClose?: boolean;
  handleClose?: () => void;
  zIndex?: number;
}

const ModalContainer = ({
  visible = true,
  handleClose,
  children,
  withClose = false,
  zIndex,
}: IFullscreenCenteredProps) => {
  return (
    <Wrapper visible={visible} zIndex={zIndex || 10}>
      {withClose && (
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      )}
      <Container>
        <Content>{children}</Content>
      </Container>
    </Wrapper>
  );
};

export default ModalContainer;
