import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const SeenIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 95 71.1">
      <path
        d="M91.2,26.9L71.4,9.2C64.9,3.3,56.3,0,47.5,0c-8.9,0-17.4,3.3-24,9.2L3.8,26.9C1.4,29.1,0,32.3,0,35.6c0,3.3,1.4,6.4,3.8,8.6
	l19.7,17.7c6.6,5.9,15.1,9.2,24,9.2c8.9,0,17.4-3.3,24-9.2l19.7-17.7c2.4-2.2,3.8-5.3,3.8-8.6C95,32.3,93.6,29.1,91.2,26.9z
	 M86.3,38.8L86.3,38.8L66.6,56.5c-5.2,4.7-12,7.4-19.1,7.4s-13.9-2.6-19.1-7.4L8.7,38.8c-0.9-0.8-1.4-2-1.4-3.2
	c0-1.2,0.5-2.4,1.4-3.2l19.7-17.7c5.2-4.7,12-7.4,19.1-7.4c7.1,0,13.9,2.6,19.1,7.4l19.7,17.7c0.9,0.8,1.4,2,1.4,3.2
	C87.7,36.8,87.2,38,86.3,38.8z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M47.5,17.4c-4.8,0-9.4,1.9-12.8,5.3c-3.4,3.4-5.3,8-5.3,12.8c0,4.8,1.9,9.4,5.3,12.8c3.4,3.4,8,5.3,12.8,5.3
	c4.8,0,9.4-1.9,12.8-5.3c3.4-3.4,5.3-8,5.3-12.8c0-4.8-1.9-9.4-5.3-12.8C56.9,19.3,52.3,17.4,47.5,17.4z M55.2,43.3
	c-2,2-4.8,3.2-7.7,3.2c-2.9,0-5.7-1.1-7.7-3.2c-2-2-3.2-4.8-3.2-7.7c0-2.9,1.1-5.7,3.2-7.7c2-2,4.8-3.2,7.7-3.2
	c2.9,0,5.7,1.1,7.7,3.2c2,2,3.2,4.8,3.2,7.7C58.4,38.4,57.2,41.2,55.2,43.3z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default SeenIcon;
