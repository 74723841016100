import styled from 'styled-components';

export const ArtistsList = styled.div`
  width: 100%;
  max-width: 30rem;
  margin: auto;
`;

export const DragIconContainer = styled.span`
  padding: 1rem;
  
  svg {
    display: block;
    width: 2rem;
  }
`;

interface IWithSelectedProps {
  selected?: boolean;
}

export const CheckIconContainer = styled.span<IWithSelectedProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  padding: 0.5rem;
  background-color: ${({ selected, theme }) => selected ? theme.colors['Black'] : 'transparent'};

  svg {
    display: block;
    width: 2rem;

    path {
      fill: ${({ theme }) => theme.colors['Casper']};
    }
  }
`;

export const ArtistLabel = styled.div<IWithSelectedProps>`
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  color: ${({ theme, selected }) => selected ? theme.colors['Black'] : theme.colors['White']};
  border: 2px solid ${({ theme, selected }) => selected ? theme.colors['Casper'] : theme.colors['Black']};
  background-color: ${({ theme, selected }) => selected ? theme.colors['Casper'] : theme.colors['Black']};
  border-radius: 5rem;
  padding: 5px;
  margin: 0 auto 1.6rem;
  transition: .2s background-color ease-in-out;

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
  ${DragIconContainer} {
    svg path {
      fill: ${({ theme, selected }) => selected ? theme.colors['Black'] : theme.colors['Casper']};
    }
  }
  label {
    cursor: pointer;
  }
`;

export const ArtistName = styled.span`
  flex: 1;
  display: block;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1;
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  font-weight: 700;
  padding: 1rem 0;
`;
