import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const MenuMobileStats = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 75 75">
      <g>
        <path
          d="M10.5,33.5h-6c-1.7,0-3,1.3-3,3v26c0,1.7,1.3,3,3,3h6c1.7,0,3-1.3,3-3v-26C13.5,34.8,12.2,33.5,10.5,33.5z M9.5,37.5v24h-4
        v-24H9.5z"
          fill={color || theme.colors['Black']}
        />
        <path
          d="M30.5,17.5h-6c-1.7,0-3,1.3-3,3v42c0,1.7,1.3,3,3,3h6c1.7,0,3-1.3,3-3v-42C33.5,18.8,32.2,17.5,30.5,17.5z M29.5,21.5v40
        h-4v-40H29.5z"
          fill={color || theme.colors['Black']}
        />
        <path
          d="M50.9,25.5h-6.4c-1.7,0-3,1.3-3,3v34c0,1.7,1.3,3,3,3h6.4c1.7,0,3-1.3,3-3v-34C53.9,26.8,52.6,25.5,50.9,25.5z M49.9,29.5
        v32h-4.4v-32H49.9z"
          fill={color || theme.colors['Black']}
        />
        <path
          d="M70.5,9.5h-6c-1.7,0-3,1.3-3,3v50c0,1.7,1.3,3,3,3h6c1.7,0,3-1.3,3-3v-50C73.5,10.8,72.2,9.5,70.5,9.5z M69.5,13.5v48h-4
        v-48H69.5z"
          fill={color || theme.colors['Black']}
        />
      </g>
    </svg>
  );
};

export default MenuMobileStats;
