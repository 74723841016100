import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../utils/AuthContext';

import FullscreenCentered from '../FullscreenCentered';

import { LayoutContainer, FullContainer } from './styles';

interface ILayoutAuthPageProps {
  children?: React.ReactNode;
  title: string;
}

const LayoutAuthPage = ({ children, title }: ILayoutAuthPageProps) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);

  return (
    <LayoutContainer>
      <FullscreenCentered withMenu={false} visible>
        <h1>{process.env.REACT_APP_NAME}</h1>
        <FullContainer>
          <h2>{title}</h2>
          {children}
        </FullContainer>
      </FullscreenCentered>
    </LayoutContainer>
  );
};

export default LayoutAuthPage;
