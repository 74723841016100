import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const ListTicketsIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 75 75">
      <g>
        <path
          d="M70.3,4C70.7,4,71,4.3,71,4.7v4.7v3.6v7v2.6v5.5c0,0.4-0.3,0.7-0.7,0.7H4.7c-0.4,0-0.7-0.3-0.7-0.7v-5.5V20v-7V9.4V4.7
        C4,4.3,4.3,4,4.7,4H70.3 M70.3,0H4.7C2.1,0,0,2.1,0,4.7v4.7v3.6v7v2.6v5.5c0,2.6,2.1,4.7,4.7,4.7h65.6c2.6,0,4.7-2.1,4.7-4.7v-5.5
        V20v-7V9.4V4.7C75,2.1,72.9,0,70.3,0L70.3,0z"
          fill={color || theme.colors['Black']}
        />
        <path
          d="M70.3,46.2c0.4,0,0.7,0.3,0.7,0.7v4.7v3.6v7v2.6v5.5c0,0.4-0.3,0.7-0.7,0.7H4.7C4.3,71,4,70.7,4,70.3v-5.5v-2.6v-7v-3.6
        v-4.7c0-0.4,0.3-0.7,0.7-0.7H70.3 M70.3,42.2H4.7c-2.6,0-4.7,2.1-4.7,4.7v4.7v3.6v7v2.6v5.5C0,72.9,2.1,75,4.7,75h65.6
        c2.6,0,4.7-2.1,4.7-4.7v-5.5v-2.6v-7v-3.6v-4.7C75,44.2,72.9,42.2,70.3,42.2L70.3,42.2z"
          fill={color || theme.colors['Black']}
        />
      </g>
    </svg>
  );
};

export default ListTicketsIcon;
