import styled from 'styled-components';
import { min } from '../../../../theme/breakpoints';

export const VenueCards = styled.div`
  margin: 1.6rem auto 0;
  width: 100%;
  max-width: 75rem;
`;

export const NumberResults = styled.div`
  color: ${({ theme }) => `${theme.colors['Casper']} !important;`};
  font-size: 1.4rem;

  ${min('tablet')} {
    font-size: 1.6rem;
  }
`;

export const VenueCardContainer = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: auto;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
  }
`;

export const VenueCard = styled.label`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors['Black']};
  display: block;
  text-align: left;
  background-color: ${({ theme }) => theme.colors['Casper']};
  padding: 1rem 5rem 1rem 1rem;
  border-radius: 5px;
  margin-top: 1.6rem;
  width: 100%;
  cursor: pointer;

  ${min('tablet')} {
    font-size: 1.4rem;
    padding-right: 5.6rem;
  }
  svg,
  &::after {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    transform: translateY(-50%);

    ${min('tablet')} {
      width: 4rem;
      height: 4rem;
    }
  }
  &::after {
    display: block;
    content: '';
    border-radius: 4rem;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors['Black']} inset;
  }
`;

export const VenueName = styled.div`
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;

  ${min('tablet')} {
    font-size: 2rem;
  }
`;
