import styled, { keyframes } from 'styled-components';
import { min } from '../../../theme/breakpoints';

export const AppActions = styled.div`
  display: none;
  margin-top: 0.8rem;
  
  ${min('tablet')} {
    margin-top: 0;
    display: block;
  }
  a {
    margin: 0 0.8rem;
    background-color: ${({ theme }) => theme.colors['MineShaft']};
    color: ${({ theme }) => theme.colors['White']};
    text-decoration: none;

    &.selected {
      background-color: ${({ theme }) => theme.colors['GoldTips']};
      color: ${({ theme }) => theme.colors['WoodsmokePrimary']};
    }
  }
`;

const pulseAnimation = () => keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
`;

export const PlusButton = styled.button`
  padding: 1.6rem;
  width: 7rem;
  height: 7rem;
  position: fixed;
  bottom: 3.2rem;
  right: 3.2rem;
  z-index: 8;
  box-shadow: 0 0 16px rgba(242, 203, 64, 0.4);
  animation: ${pulseAnimation} 0.5s ease-out infinite alternate;
`;
