import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import moment from 'moment';
import { useTheme } from 'styled-components';
import Api from '../../../helpers/Api';
import { useAppSelector } from '../../../store';
import { IArtist, IShow, Theme } from '../../../types';
import { getCountryCurrency, formatPriceWithCurrency } from '../../../utils/price';

import ModalContainer from '../Container';
import Loader from '../../Elements/Loader';
import { ArtistIcon, CommentIcon, DateIcon, ExternalIcon, LocationIcon, VenueIcon } from '../../Elements/Icons';

import { GreyButton, RedButton } from '../../Elements/Buttons/styles';
import {
  Details,
  DateFormatted,
  Artists,
  Artist,
  Venue,
  Location,
  City,
  Country,
  CommentSection,
  Comment,
  SetlistLink,
  FireworksContainer,
  Fireworks,
  MoreInfo,
  MoreInfoLink,
  MoreInfoContainer,
} from './styles';

interface IModalDetailsUserShowProps {
  close: () => void;
  details: IShow | null;
  editUserShow: (show: IShow) => void;
  setDeleteUserShow: Dispatch<SetStateAction<IShow | null>>;
}

const ModalDetailsUserShow = ({ close, details, editUserShow, setDeleteUserShow }: IModalDetailsUserShowProps) => {
  const theme = useTheme() as Theme;
  const [loading, setLoading] = useState(false);
  const [artists, setArtists] = useState<IArtist[]>([]);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const { countries } = useAppSelector(
    (state) => ({
      countries: state.api.countries,
    }),
    shallowEqual,
  );

  useEffect(() => {
    const fetchShowInfo = async () => {
      if (details) {
        setArtists([]);
        setLoading(true);
        const { id } = details;
        if (id) {
          Api.request<IShow>(`/shows/${id}`).then((response) => {
            if (response) {
              setArtists(response.artists);
            }
            setLoading(false);
          });
        } else {
          close();
        }
      }
    };

    fetchShowInfo();
  }, [details, close]);

  const seenArtists = artists.filter((artist) => artist.id);
  const notSeenArtists = artists.filter((artist) => !artist.id);

  const handleDisplayMoreInfo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowMoreInfo(!showMoreInfo);
  };

  return (
    <ModalContainer visible={!!details} zIndex={9} withClose handleClose={close}>
      {loading && <Loader />}
      {details && (
        <Details>
          {moment(details.date).isSame(moment(), 'day') && (
            <FireworksContainer>
              <Fireworks />
              <Fireworks />
              <Fireworks />
            </FireworksContainer>
          )}
          <DateFormatted>
            <DateIcon color={theme.colors['GoldTips']} />
            {moment(details.date).isSame(moment(), 'day') ? 'Today' : moment(details.date).format('LL')}
          </DateFormatted>
          {moment(details.date).isSame(moment(), 'day')
            ? 'is the day!'
            : moment(details.date).isAfter(moment())
            ? 'you will see'
            : 'you saw'}
          <Artists>
            {seenArtists.map((artist, index) => (
              <Artist key={`show_details_artist_seen_${artist.name}`} seen>
                {index === 0 && <ArtistIcon color={theme.colors['White']} />}
                {artist.name}
                {artist.setlistUrl && (
                  <SetlistLink href={artist.setlistUrl} target="_blank">
                    (setlist <ExternalIcon color={theme.colors['Casper']} />)
                  </SetlistLink>
                )}
              </Artist>
            ))}
          </Artists>
          {notSeenArtists.length > 0 && (
            <>
              but there were also
              <Artists>
                {notSeenArtists.map((artist, index) => (
                  <Artist key={`show_details_artist_not_seen_${artist.name}`} seen={false}>
                    {index === 0 && <ArtistIcon color={theme.colors['White']} />}
                    {artist.name}
                    {artist.setlistUrl && (
                      <SetlistLink href={artist.setlistUrl} target="_blank">
                        (setlist <ExternalIcon color={theme.colors['Casper']} />)
                      </SetlistLink>
                    )}
                  </Artist>
                ))}
              </Artists>
            </>
          )}
          at the
          <Venue>
            <VenueIcon color={theme.colors['LightRed']} />
            {details.venue.name}
          </Venue>
          in
          <Location>
            <City>
              <LocationIcon color={theme.colors['LightGreen']} />
              {details.venue.cityName}
            </City>
            <Country>
              {details.venue.state}, {details.venue.countryName}
            </Country>
          </Location>
          {(details.seated !== null || !!details.price) && (
            <MoreInfo>
              <MoreInfoLink href="" onClick={handleDisplayMoreInfo}>
                {showMoreInfo ? 'Hide' : 'Show'} more info
              </MoreInfoLink>
              {showMoreInfo && (
                <MoreInfoContainer>
                  {details.seated !== null && (
                    <li>
                      Seated: <strong>{details.seated ? 'Yes' : 'No'}</strong>
                    </li>
                  )}
                  {!!details.price && getCountryCurrency(countries, details.venue.countryCode) && (
                    <li>
                      Price:{' '}
                      <strong>
                        {formatPriceWithCurrency(
                          details.price,
                          getCountryCurrency(countries, details.venue.countryCode),
                        )}
                      </strong>
                    </li>
                  )}
                </MoreInfoContainer>
              )}
            </MoreInfo>
          )}
          {details.comment && (
            <CommentSection>
              You left this comment:
              <Comment>
                <CommentIcon color={theme.colors['WhiteRock']} />
                {details.comment}
              </Comment>
            </CommentSection>
          )}
          <GreyButton onClick={() => editUserShow(details)}>Edit</GreyButton>&nbsp;
          <RedButton onClick={() => setDeleteUserShow(details)}>Delete</RedButton>
        </Details>
      )}
    </ModalContainer>
  );
};

export default ModalDetailsUserShow;
