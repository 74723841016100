import styled, { keyframes } from 'styled-components';

export const pulseAnimation = keyframes`
  0% {
    width: 0;
    height: 0;
    border-width: 20px;
    opacity: 1;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
    border-width: 0;
  }
`;

export const LoaderContainer = styled.span`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-color: rgba(17, 17, 18, 0.9);
`;

export const LoaderInner = styled.span`
  position: relative;
  width: 150px;
  height: 150px;

  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border: 20px solid ${({ theme }) => theme.colors['GoldTips']};
    border-radius: 50%;
    animation: ${pulseAnimation} 2s ease-in-out infinite;

    &:nth-child(2) {
      animation-delay: -1s;
    }
  }
`;
