import { shallowEqual } from 'react-redux';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { useAppSelector } from '../../../store';
import { IShow, Theme } from '../../../types';
import { formatDate } from '../../../utils/date';
import { formatPriceWithCurrency, getCountryCurrency } from '../../../utils/price';

import { CommentIcon, DeleteIcon, EditIcon } from '../../Elements/Icons';
import {
  ShowTicketContainer,
  TicketOuterContent,
  TicketInnerContent,
  ArtistsNames,
  Details,
  VenueInfo,
  VenueName,
  City,
  DeleteButton,
  CommentSection,
  CommentButton,
  CommentDetails,
  EditButton,
  Stub,
  AdditionalInfo,
  InfoContainer,
  InfoTitle,
  InfoDetail,
  BackgroundImage,
  DotsLine,
} from './styles';

interface IShowTicketProps {
  userShow: IShow;
  index?: number;
  setDeleteUserShow?: React.Dispatch<React.SetStateAction<IShow | null>>;
  setDetailsUserShow?: React.Dispatch<React.SetStateAction<IShow | null>>;
  editUserShow?: (userShow: IShow) => void;
  readonly?: boolean;
}

const ShowTicket = ({
  userShow,
  index = 0,
  setDeleteUserShow,
  setDetailsUserShow,
  editUserShow,
  readonly = false,
}: IShowTicketProps) => {
  const theme = useTheme() as Theme;
  const { countries } = useAppSelector(
    (state) => ({
      countries: state.api.countries,
    }),
    shallowEqual,
  );

  const { artists, comment, date, pending, price, seated, venue } = userShow;
  const isToday = moment(date).isSame(moment(), 'day');

  const showDetails = (stub?: boolean) => (
    <div>
      <ArtistsNames>
        {artists.map((artist, index: number) => `${artist.name}${index < artists.length - 1 ? ' + ' : ''}`)}
      </ArtistsNames>
      <VenueInfo>
        <VenueName>{venue.name}</VenueName>
        <City>
          {venue.cityName}, {stub ? venue.countryCode : venue.countryName}
          {!stub && ` (${venue.state})`}
        </City>
      </VenueInfo>
      <AdditionalInfo>
        <InfoContainer>
          <InfoTitle>Date</InfoTitle>
          <InfoDetail>{isToday ? 'Today' : formatDate(date, stub)}</InfoDetail>
        </InfoContainer>
        <InfoContainer>
          <InfoTitle>Section</InfoTitle>
          <InfoDetail>{seated ? 'Seated' : 'General Admission'}</InfoDetail>
        </InfoContainer>
        <InfoContainer>
          <InfoTitle>Price</InfoTitle>
          <InfoDetail>
            {price ? formatPriceWithCurrency(price, getCountryCurrency(countries, venue.countryCode)) : 'N/A'}
          </InfoDetail>
        </InfoContainer>
      </AdditionalInfo>
    </div>
  );

  return (
    <ShowTicketContainer>
      <TicketOuterContent>
        <TicketInnerContent
          as={readonly ? 'div' : 'button'}
          even={index % 2}
          readonly={readonly}
          onClick={() => !readonly && setDetailsUserShow && setDetailsUserShow(userShow)}
          pending={pending}
          isToday={isToday}
        >
          {artists[0]?.cover && (
            <BackgroundImage pending={pending} coverImage={!!artists[0]?.cover} desktop={true}>
              <img src={`${process.env.REACT_APP_API_URL}${artists[0].cover.url}`} loading="lazy" alt="" />
            </BackgroundImage>
          )}
          <Details>
            {showDetails()}
            {comment && (
              <CommentSection>
                <CommentButton tabIndex={0}>
                  <CommentIcon />
                </CommentButton>
                <CommentDetails>{comment}</CommentDetails>
              </CommentSection>
            )}
          </Details>
          <Stub pending={pending}>
            <DotsLine />
            {pending && showDetails(true)}
            {artists[0]?.cover && (
              <BackgroundImage pending={pending} coverImage={!!artists[0]?.cover} desktop={false}>
                <img src={`${process.env.REACT_APP_API_URL}${artists[0].cover.url}`} loading="lazy" alt="" />
              </BackgroundImage>
            )}
          </Stub>
        </TicketInnerContent>
        {!readonly && editUserShow && (
          <EditButton onClick={() => editUserShow(userShow)} aria-label="Edit the show">
            <EditIcon color={theme.colors['GoldTips']} />
          </EditButton>
        )}
        {!readonly && setDeleteUserShow && (
          <DeleteButton onClick={() => setDeleteUserShow(userShow)} aria-label="Delete the show">
            <DeleteIcon color={theme.colors['GoldTips']} />
          </DeleteButton>
        )}
      </TicketOuterContent>
    </ShowTicketContainer>
  );
};

export default ShowTicket;
