import { IShow } from '../../../types/model';
import { userShowsGroupedPerYear } from '../../../utils';

import ShowCard from '../../ShowItem/Card';

import { HeaderYear, NumberShowsPerYear } from '../styles';
import { GridWrapper, Grid } from './styles';

interface IListGridProps {
  items: IShow[];
  setDeleteUserShow: React.Dispatch<React.SetStateAction<IShow | null>>;
  setDetailsUserShow: React.Dispatch<React.SetStateAction<IShow | null>>;
  editUserShow: (userShow: IShow) => void;
}

const ListGrid = ({ items, setDeleteUserShow, setDetailsUserShow, editUserShow }: IListGridProps) => {
  const groupedUserShows = items.length ? userShowsGroupedPerYear(items) : {};

  return (
    <>
      {Object.keys(groupedUserShows).map((year) => {
        const nbShowYear = groupedUserShows[year].length;
        return (
          <GridWrapper key={`table_grid_${year}`}>
            <HeaderYear>
              {year.replace('year-', '')}
              <NumberShowsPerYear>
                {nbShowYear} show{nbShowYear > 1 && 's'}
              </NumberShowsPerYear>
            </HeaderYear>
            <Grid>
              {groupedUserShows[year].map((user_show: IShow, index: number) => (
                <ShowCard
                  key={`userShow_card_${user_show.id}`}
                  userShow={user_show}
                  index={index}
                  setDeleteUserShow={setDeleteUserShow}
                  editUserShow={editUserShow}
                  setDetailsUserShow={setDetailsUserShow}
                />
              ))}
            </Grid>
          </GridWrapper>
        );
      })}
    </>
  );
};

export default ListGrid;
