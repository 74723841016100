import styled from 'styled-components';

export const TextInput = styled.input`
  &[type="text"] {
    border-radius: 0;

    &:first-of-type {
      border-radius: 2.5rem 2.5rem 0 0;
    }
  }
`;

export const EmailInput = styled.input`
  &[type="email"] {
    border-radius: 0;
  }
`;

export const PasswordInput = styled.input`
  &[type="password"] {
      border-radius: 0;

    &:last-of-type {
      border-radius: 0 0 2.5rem 2.5rem;
    }
  }
`;

