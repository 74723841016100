import { useState } from 'react';
import Api from '../../../helpers/Api';
import { useAuth } from '../../../utils/AuthContext';
import useAlert from '../../../hooks/useAlert';

import AppPageLayout from '../../../components/Layout/AppPage';
import Loader from '../../../components/Elements/Loader';

import { ErrorMessage, FormContainer, SubmitButton } from '../../../components/Layout/Auth/styles';
import { TextInput, PasswordInput } from '../ResetPassword/styles';
import { RedButton } from '../../../components/Elements/Buttons/styles';
import { PageContainer, LogoutContainer } from './styles';

const AccountPage = () => {
  const { checkUserStatus, logoutUser, user } = useAuth();
  const { setAlert } = useAlert();
  const [formFields, setFormFields] = useState({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleInputUpdate = (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormFields({
      ...formFields,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const submitInfoForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    const { firstName, lastName } = formFields;
    setLoading(true);

    Api.request(
      `/users/${user.id}`,
      {
        firstName,
        lastName,
      },
      'put',
    )
      .then(async () => {
        await checkUserStatus();
        setLoading(false);
        setAlert('Account info updated!', 'success');
      })
      .catch((response: any) => {
        const errorMessage = response?.response?.data?.error?.message;
        if (errorMessage) {
          setError(errorMessage);
        }
        setLoading(false);
      });
  };

  const submitPasswordForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    const { currentPassword, password, passwordConfirmation } = formFields;
    if (password !== passwordConfirmation) {
      setError("Passwords don't match");
      return;
    }
    setLoading(true);

    Api.request(
      '/auth/change-password',
      {
        currentPassword,
        password,
        passwordConfirmation,
      },
      'post',
    )
      .then(() => {
        setFormFields({
          ...formFields,
          currentPassword: '',
          password: '',
          passwordConfirmation: '',
        });
        setLoading(false);
        setAlert('Password updated!', 'success');
      })
      .catch((response: any) => {
        const errorMessage = response?.response?.data?.error?.message;
        if (errorMessage) {
          setError(errorMessage);
        }
        setLoading(false);
      });
  };

  const { firstName, lastName, currentPassword, password, passwordConfirmation } = formFields;

  return (
    <AppPageLayout centered={true}>
      <PageContainer>
        {loading && <Loader />}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <FormContainer onSubmit={submitInfoForm}>
          <h2>Personal Info</h2>
          <TextInput
            type="text"
            name="firstName"
            placeholder="First name"
            required
            value={firstName}
            onInput={handleInputUpdate}
          />
          <TextInput
            type="text"
            name="lastName"
            placeholder="Last name"
            required
            value={lastName}
            onInput={handleInputUpdate}
          />
          <SubmitButton type="submit">Update</SubmitButton>
        </FormContainer>
        <FormContainer onSubmit={submitPasswordForm}>
          <h2>Security</h2>
          <PasswordInput
            type="password"
            name="currentPassword"
            placeholder="Current password"
            required
            value={currentPassword}
            onInput={handleInputUpdate}
          />
          <PasswordInput
            type="password"
            name="password"
            placeholder="New password"
            required
            value={password}
            onInput={handleInputUpdate}
          />
          <PasswordInput
            type="password"
            name="passwordConfirmation"
            placeholder="Confirm new password"
            required
            value={passwordConfirmation}
            onInput={handleInputUpdate}
          />
          <SubmitButton type="submit">Update</SubmitButton>
        </FormContainer>
        <LogoutContainer>
          <RedButton onClick={logoutUser}>Logout</RedButton>
        </LogoutContainer>
      </PageContainer>
    </AppPageLayout>
  );
};

export default AccountPage;
