import { shallowEqual } from 'react-redux';
import { useTheme } from 'styled-components';
import { useAppSelector } from '../../store';
import { Theme } from '../../types';

import { ShowIcon } from '../../components/Elements/Icons';
import AppPageLayout from '../../components/Layout/AppPage';
import NewArtistsPerYear from '../../components/StatsModules/NewArtistsPerYear';
import ShowsPerYearModule from '../../components/StatsModules/ShowsPerYear';
import TopArtistsModule from '../../components/StatsModules/TopArtists';
import TopCitiesModule from '../../components/StatsModules/TopCities';
import TopCountriesModule from '../../components/StatsModules/TopCountries';
import TopVenuesModule from '../../components/StatsModules/TopVenues';
import TotalCurrencyPrice from '../../components/StatsModules/TotalCurrencyPrice';
import TotalFutureShowsModule from '../../components/StatsModules/TotalFutureShows';
import TotalPastShowsModule from '../../components/StatsModules/TotalPastShows';
import TotalFutureArtistsModule from '../../components/StatsModules/TotalFutureArtists';
import TotalFutureArtistsNotSeenModule from '../../components/StatsModules/TotalFutureArtistsNotSeen';
import TotalPastArtistsModule from '../../components/StatsModules/TotalPastArtists';
import TotalSeatedShows from '../../components/StatsModules/TotalSeatedShows';

import { DashboardContainer, ModulesWrapper, IconContainer, ChartsWrapper } from './styles';
import PricesPerYear from '../../components/StatsModules/PricesPerYear';

const StatsPage = () => {
  const theme = useTheme() as Theme;
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  return (
    <AppPageLayout>
      {userShows?.length ? (
        <DashboardContainer>
          <ModulesWrapper>
            <TotalPastShowsModule />
            <TotalPastArtistsModule />
            <TotalFutureShowsModule />
          </ModulesWrapper>
          <ModulesWrapper>
            <TotalFutureArtistsModule />
            <TotalFutureArtistsNotSeenModule />
          </ModulesWrapper>
          <ModulesWrapper>
            <TotalCurrencyPrice />
          </ModulesWrapper>
          <ModulesWrapper>
            <TotalSeatedShows />
          </ModulesWrapper>
          <ModulesWrapper>
            <TopArtistsModule />
            <TopVenuesModule />
            <ShowsPerYearModule />
            <TopCountriesModule />
            <TopCitiesModule />
          </ModulesWrapper>
          <ChartsWrapper>
            <NewArtistsPerYear />
          </ChartsWrapper>
          <ChartsWrapper>
            <PricesPerYear />
          </ChartsWrapper>
        </DashboardContainer>
      ) : (
        <>
          <IconContainer>
            <ShowIcon color={theme.colors['Casper']} />
          </IconContainer>
          <p>No stats to display for the moment. Add your first show now!</p>
        </>
      )}
    </AppPageLayout>
  );
};

export default StatsPage;
