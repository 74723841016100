import styled, { css, keyframes } from 'styled-components';
import { min, minMax } from '../../../theme/breakpoints';

interface IWithEvenProps {
  even: number;
}

export const ShowCardContainer = styled.div`
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  padding: 1.6rem;

  ${min('tablet')} {
    max-width: 50%;
  }
  ${min('desktop')} {
    max-width: 33.333%;
  }
`;

export const ActionButton = styled.button`
  position: absolute;
  z-index: 3;
  right: 0;
  padding: 0.8rem;
  transform: translate(30%, -30%);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4), inset 0 0 0 1px transparent;
  transition: .3s box-shadow ease-out;

  &:hover {
    ${min('tablet')} {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.8), inset 0 0 0 1px ${({ theme }) => theme.colors['Casper']};
    }
  }
  svg {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const DeleteButton = styled(ActionButton)`
  top: 3.5rem;
`;

export const EditButton = styled(ActionButton)`
  top: 0;
`;

export const CardOuterContent = styled.div<IWithEvenProps>`
  height: 100%;
  flex: 1;
  position: relative;

  ${DeleteButton},
  ${EditButton} {
    border-color: transparent;
    background-color: ${({ theme, even }) => even ? theme.colors['WoodsmokePrimary'] : theme.colors['WoodsmokeSecondary']};

    ${minMax('tablet', 'desktop')} {
      background-color: ${({ theme }) => theme.colors['WoodsmokeSecondary']};
    }
  }
`;

export const ArtistsNames = styled.div`
  color: ${({ theme }) => theme.colors['White']};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.125;
  padding-right: 2.5rem;
  flex: 1;
  margin-left: 1rem;

  ${min('tablet')} {
    font-size: 1.8rem;
  }
  ${min('desktop')} {
    font-size: 2rem;
  }
  ${min('largeDesktop')} {
    font-size: 2.6rem;
  }
`;

export const ShowInfo = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: auto;
  position: relative;
  z-index: 2;
  text-shadow: 0 0 10px ${({ theme }) => theme.colors['Black']};
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10rem;
  position: relative;
  z-index: 2;
  text-shadow: 0 0 10px ${({ theme }) => theme.colors['Black']};
  width: 100%;
`;

export const AdditionalDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: auto;
  padding-left: 1.6rem;

  p {
    color: ${({ theme }) => theme.colors['Casper']};
    text-align: right;
    font-family: ${({ theme }) => theme.fonts.robotoCondensed};
    margin: 0;
  }
`;

export const Price = styled.p`
  font-size: 1.8rem;
`;

export const VenueInfo = styled.div`
  flex: 1;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors['Casper']};
`;

export const VenueName = styled.div`
  font-size: 1.4rem;
  font-weight: 700;

  ${min('desktop')} {
    font-size: 1.6rem;
  }
  ${min('largeDesktop')} {
    font-size: 1.8rem;
  }
`;

export const City = styled.div`
  font-size: 1.2rem;

  ${min('desktop')} {
    font-size: 1.4rem;
  }
  ${min('largeDesktop')} {
    font-size: 1.6rem;
  }
`;

export const DateContainer = styled.span`
  color: ${({ theme }) => theme.colors['White']};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors['Black']};
  border-radius: 1rem;
  padding: 0.8rem;
  text-align: center;
  margin-left: auto;

  ${min('tablet')} {
    padding: 1.4rem 1rem;
  }
`;

export const Year = styled.span`
  font-size: 1.2rem;
  line-height: 1;

  ${min('desktop')} {
    font-size: 1.4rem;
  }
  ${min('largeDesktop')} {
    font-size: 1.4rem;
  }
`;

export const Month = styled.span`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;

  ${min('desktop')} {
    font-size: 1.8rem;
  }
  ${min('largeDesktop')} {
    font-size: 2rem;
  }
`;

export const Day = styled.span`
  font-family: ${({ theme }) => theme.fonts.libreBaskerville};
  font-size: 1.8rem;
  line-height: 1;

  ${min('tablet')} {
    font-size: 2rem;
  }
  ${min('desktop')} {
    font-size: 2.4rem;
  }
  ${min('largeDesktop')} {
    font-size: 2.5rem;
  }
`;

export const CommentSection = styled.div`
  position: relative;
  display: inline-block;
  text-align: left;
`;

export const CommentDetails = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  right: 0;
  min-width: 15rem;
  z-index: 1;
  color: ${({ theme }) => theme.colors['Black']};
  background-color: ${({ theme }) => theme.colors['Casper']};
  padding: 1.6rem;
  border-radius: 1rem;
  box-shadow: 0 0 20px ${({ theme }) => theme.colors['Black']};
  transition: .2s all ease-in-out;
  transform: translateY(-2px);

  ${min('tablet')} {
    min-width: 20rem;
  }
  ${min('largeDesktop')} {
    min-width: 25rem;
  }
`;


export const CommentButton = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 0.8rem;
  width: 4rem;
  height: 4rem;
  border-color: transparent;
  
  svg {
    display: block;
    width: 100%;

    path {
      fill: ${({ theme }) => theme.colors['GoldTips']};
    }
  }
  &:hover + ${CommentDetails},
  &:focus + ${CommentDetails} {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

const pulseAnimation = () => keyframes`
  from {
    box-shadow: 0 0 1.6rem rgb(242, 203, 64, 0.4);
  }
  to {
    box-shadow: 0 0 3.2rem rgb(242, 203, 64, 0.6);
  }
`;

interface IBackgroundImage {
  pending: boolean;
  coverImage: boolean;
}

export const BackgroundImage = styled.div<IBackgroundImage>`
  position: absolute !important;
  left: 1px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 1.6rem;
  z-index: 0;
  background-position: 50% 30%;
  background-size: cover;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 30%;
  }
  &::after {
    content: "";
    display: ${({ coverImage }) => coverImage ? 'block' : 'none'};
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: ${({ pending }) => pending ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.8)'};
    opacity: 1;
    transition: .3s opacity;
    border-radius: 1.6rem;
    backdrop-filter: ${({ pending }) => pending ? 'blur(0.5px)' : 'blur(5px)'};
    z-index: 1;
  }
`;

interface ICardInnerContentProps extends IWithEvenProps {
  readonly: boolean;
  pending: boolean;
  isToday: boolean;
}

export const CardInnerContent = styled.div<ICardInnerContentProps>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.colors['WoodsmokeSecondary']};
  text-align: left;
  border-radius: 1.6rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4), inset 0 0 0 1px ${({ theme }) => theme.colors['WoodsmokeSecondary']};
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    opacity: 1;
    transition: .3s opacity;
    z-index: 2;
    border-radius: 1.6rem;
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.colors['WoodsmokePrimary']};
    transition: .3s opacity ease-out;
  }
  ${min('desktop')} {
    background-color: ${({ theme, even }) => even ? theme.colors['WoodsmokePrimary'] : theme.colors['WoodsmokeSecondary']};
  }
  ${({ readonly }) => !readonly && css`
    transition: .3s box-shadow ease-out;
    cursor: pointer;

    &:hover {
      ${min('tablet')} {
        margin-top: -1px;
        margin-bottom: 1px;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.5), inset 0 0 0 1px ${({ theme }) => theme.colors['Casper']};

        &::after {
          opacity: 0;
        }
        ${BackgroundImage} {
          &::after {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.6;
            backdrop-filter: none;
          }
        }
      }
    }
    &:focus-visible {
      margin-top: -1px;
      margin-bottom: 1px;

      ${BackgroundImage} {
        &::after {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.6;
          backdrop-filter: none;
        }
      }
    }
  `}
  ${({ isToday }) => isToday && css`
    animation: ${pulseAnimation} 0.8s ease-out infinite alternate;

    &:hover {
      ${min('tablet')} {
        box-shadow: 0 0 3.2rem rgb(242, 203, 64, 0.6), inset 0 0 0 1px ${({ theme }) => theme.colors['Casper']} !important;
      }
    }
  `}
`;
