import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';

import { Item, Count, FixedModuleContainer, Results, Info, MainInfo, SecondaryInfo } from '../styles';
import { isPastShow } from '../../../utils/date';

const TopVenuesModule = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  let countVenues: {
    id: number;
    name: string;
    city: string;
    count: number;
  }[] = [];
  userShows?.forEach((userShow) => {
    const { venue } = userShow;
    if (venue && isPastShow(userShow)) {
      const indexFoundInCount = countVenues.findIndex((venueCount) => venueCount.id === venue.id);
      if (indexFoundInCount > -1) {
        countVenues[indexFoundInCount].count += 1;
      } else {
        countVenues.push({
          id: venue.id,
          name: venue.name,
          city: `${venue.cityName} (${venue.countryCode})`,
          count: 1,
        });
      }
    }
  });

  countVenues = countVenues.filter((venueCount) => venueCount.count > 1);
  countVenues.sort((a, b) => b.count - a.count);
  countVenues = countVenues.slice(0, 7);

  return countVenues.length ? (
    <FixedModuleContainer>
      <h3>Top venues</h3>
      <Results>
        {countVenues.map((venueCount) => (
          <Item key={`top_venues_${venueCount.name}`}>
            <Info>
              <MainInfo>{venueCount.name}</MainInfo>
              <SecondaryInfo>{venueCount.city}</SecondaryInfo>
            </Info>
            <Count>{venueCount.count}</Count>
          </Item>
        ))}
      </Results>
    </FixedModuleContainer>
  ) : null;
};

export default TopVenuesModule;
