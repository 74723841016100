import { LoaderContainer, LoaderInner } from './styles';

const Loader = () => (
  <LoaderContainer>
    <LoaderInner>
      <span />
      <span />
    </LoaderInner>
  </LoaderContainer>
);

export default Loader;
