import styled, { css, keyframes } from 'styled-components';
import { min } from '../../../theme/breakpoints';

const pulseAnimation = () => keyframes`
  from {
    box-shadow: 0 0 1.6rem rgb(242, 203, 64, 0.4);
  }
  to {
    box-shadow: 0 0 3.2rem rgb(242, 203, 64, 0.6);
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  padding: 0 0 2rem;

  ${min('tablet')} {
    padding: 0 0 3.2rem;
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
`;

export const HeaderColumn = styled.th`
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0.8rem 1.6rem;
  font-family: ${({ theme }) => theme.fonts.young};
  color: ${({ theme }) => theme.colors['Casper']};
  font-size: 1.4rem;

  ${min('tablet')} {
    padding: 1.6rem;
    font-size: 1.8rem;
  }
  &:first-child {
    border-top-left-radius: 1.6rem;
  }
  &:last-child {
    border-top-right-radius: 1.6rem;
  }
`;

export const Column = styled.td`
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-size: 1.6rem;
  padding: 0.8rem 1.6rem;
  font-size: 1.2rem;
  cursor: pointer;

  ${min('tablet')} {
    padding: 1.6rem 2rem; 
    font-size: 1.6rem;
  }
`;

interface IRowProps {
  even?: number;
  pending: boolean;
  isToday: boolean;
}

export const Row = styled.tr<IRowProps>`
  background: ${({ pending, isToday, theme }) => isToday ? `repeating-linear-gradient(
    -45deg,
    ${theme.colors['GoldTips']},
    ${theme.colors['GoldTips']} 10px,
    #f0c323 10px,
    #f0c323 20px
  )` : pending ? `repeating-linear-gradient(
    -45deg,
    ${theme.colors['WoodsmokeSecondary']},
    ${theme.colors['WoodsmokeSecondary']} 10px,
    ${theme.colors['WoodsmokePrimary']} 10px,
    ${theme.colors['WoodsmokePrimary']} 20px
  )` : 'none'};
  background-color: ${({ theme, even }) => even ? theme.colors['WoodsmokeSecondary'] : theme.colors['WoodsmokePrimary']};
  transition: .2s all ease-out;
  box-shadow: inset 0 0 0 1px transparent;
  position: relative;
  color: ${({ isToday, theme }) => isToday ? theme.colors['Black'] : theme.colors['White']};
  font-weight: ${({ isToday }) => isToday ? 900 : 400};
  ${({ isToday }) => isToday && css`
    animation: ${pulseAnimation()} 0.8s ease-out infinite alternate;
    z-index: 1;
  `}
`;

interface IInvisibleButtonProps {
  withComment: boolean;
}

export const InvisibleButton = styled.button<IInvisibleButtonProps>`
  display: block;
  background-color: transparent;
  position: absolute;
  width: ${({ withComment }) => withComment ? 'calc(100% - 14rem)' : 'calc(100% - 10rem)'};
  left: 0;
  top: 0;
  bottom: 0;
  transition: .2s all ease-out;
  box-shadow: inset 0 0 0 1px transparent;
  border-radius: 0;

  &:hover {
    ${min('tablet')} {
      transform: translateY(-1px);
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors['Casper']};
    }
  }
  &:focus-visible {
    outline: 0;
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors['GoldTips']};
  }
`;

export const ActionsColumn = styled(Column)`
  padding: 0 1.6rem;
  text-align: right;
  cursor: default;
  white-space: nowrap;
`;

export const ActionButton = styled.button`
  display: inline-block;
  padding: 0;
  background: none;
  border: 0;
  width: 3.2rem;
  height: 3.2rem;
  line-height: 3.2rem;

  ${min('tablet')} {
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
  }
  svg {
    display: block;
    height: 1.6rem;
    margin: auto;

    ${min('tablet')} {
      height: 2rem;
    }
  }
`;
