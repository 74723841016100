import { shallowEqual } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { resetAll } from '../../../store/formSlice';

import { AppActions, PlusButton } from './styles';
import { PlusIcon } from '../../Elements/Icons';

interface INavbarDesktopProps {
  setFormVisible?: (value: boolean) => void;
}

const NavbarDesktop = ({ setFormVisible }: INavbarDesktopProps) => {
  const { typeForm } = useAppSelector(
    (state) => ({
      typeForm: state.form.type,
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const location = useLocation();

  const openFormCreateShow = () => {
    if (setFormVisible) {
      if (typeForm === 'edit') {
        dispatch(resetAll());
      }
      setFormVisible(true);
    }
  };

  return (
    <AppActions>
      {location.pathname !== '/account' && (
        <PlusButton onClick={() => openFormCreateShow()} aria-label="Add a show">
          <PlusIcon />
        </PlusButton>
      )}
      <Link to={'/'} className={`button${location.pathname === '/' ? ' selected' : ''}`}>
        List
      </Link>
      <Link to={'/stats'} className={`button${location.pathname === '/stats' ? ' selected' : ''}`}>
        Stats
      </Link>
      <Link to={'/account'} className={`button${location.pathname === '/account' ? ' selected' : ''}`}>
        Account
      </Link>
    </AppActions>
  );
};

export default NavbarDesktop;
