import styled from 'styled-components';
import { min } from '../../theme/breakpoints';

export const ModulesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1.6rem 0;
  gap: 3.2rem;
  margin: 0;

  ${min('tablet')} {
    padding: 3.2rem 0;
  }
`;

export const ChartsWrapper = styled.div`
  padding: 1.6rem 0;

  ${min('tablet')} {
    padding: 3.2rem 0;
  }
  h3 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 1.6rem;
  }
`;

export const DashboardContainer = styled.div`
  flex: 0;

  ${min('tablet')} {
    margin: 3.2rem 0;
  }
`;

export const IconContainer = styled.div`
  width: 20rem;
  margin: 3.2rem auto 0;
`;
