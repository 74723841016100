import styled from 'styled-components';

export const GrayButton = styled.button`
  background-color: ${({ theme }) => theme.colors['MineShaft']};
  color: ${({ theme }) => theme.colors['White']};
`;

export const RedButton = styled.button`
  background-color: ${({ theme }) => theme.colors['LightRed']};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors['LightRed']};
  }
`;

export const GreenButton = styled.button`
  background-color: ${({ theme }) => theme.colors['LightGreen']};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors['LightGreen']};
  }
`;

export const BlueButton = styled.button`
  background-color: ${({ theme }) => theme.colors['CuriousBlue']};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors['CuriousBlue']};
  }
`;

export const GreyButton = styled.button`
  color: ${({ theme }) => theme.colors['White']};
  background-color: ${({ theme }) => theme.colors['MineShaft']};
`;

export const CancelLink = styled.a`
  margin-top: 1.6rem;
  display: inline-block;
  font-size: 1.4rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors['LightRed']};
  text-decoration: underline;
  border-radius: 5rem;
  padding: 0.8rem;

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors['LightRed']};
  }
`;

export const NoStyleButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0;
`;
