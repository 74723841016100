import React, { useRef } from 'react';
import { shallowEqual } from 'react-redux';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { useTheme } from 'styled-components';
import { useAppSelector } from '../../../../store';
import { ICountry, IOption, Theme } from '../../../../types';

import { FiltersContainer, FiltersList, FilterItem, ResetAll } from './styles';
import { RedButton } from '../../../Elements/Buttons/styles';

interface IFilterSelectProps {
  placeholder: string;
  options: IOption[];
  handleChange: (option: MultiValue<IOption>, actionMeta: ActionMeta<IOption>) => void;
}

const FilterSelect = React.forwardRef<any, IFilterSelectProps>((props, ref) => {
  const { placeholder, options, handleChange } = props;
  const theme = useTheme() as Theme;
  return (
    <Select
      ref={ref}
      classNames={{
        container: () => 'custom-select multiple',
        menu: () => 'menu-list',
        control: () => 'control',
        option: (state) => (state.isSelected ? 'selected' : ''),
        placeholder: () => 'placeholder',
        valueContainer: () => 'multi-value-container',
        multiValue: () => 'multi-value',
        multiValueRemove: () => 'multi-value-remove',
        multiValueLabel: () => 'multi-value-label',
      }}
      theme={(selectTheme) => ({
        ...selectTheme,
        colors: {
          ...selectTheme.colors,
          primary: theme.colors['Casper'],
          primary25: theme.colors['WoodsmokeSecondary'],
        },
      })}
      styles={{
        control: (base, state) => ({
          ...base,
          outlineOffset: '2px',
          outline: state.isFocused ? `2px solid ${theme.colors['GoldTips']}` : '0',
          boxShadow: 'none',
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isSelected ? theme.colors['GoldTips'] : 'transparent',
        }),
      }}
      placeholder={placeholder}
      name={placeholder.toLowerCase()}
      options={options}
      onChange={handleChange}
      isClearable
      isSearchable
      isMulti
    />
  );
});

interface IListFiltersProps {
  filterList: (props: { values: MultiValue<IOption>; name: string }) => void;
  nbActiveFilters: number;
  resetFilterList: () => void;
  showFilters: boolean;
}

const ListFilters = ({ filterList, nbActiveFilters, resetFilterList, showFilters }: IListFiltersProps) => {
  const filterArtistsRef = useRef<any>();
  const filterCitiesRef = useRef<any>();
  const filterCountriesRef = useRef<any>();
  const filterVenuesRef = useRef<any>();
  const filterYearsRef = useRef<any>();
  const { countries, userShows } = useAppSelector(
    (state) => ({
      countries: state.api.countries,
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const artists: IOption[] = [];
  const cities: IOption[] = [];
  const venues: IOption[] = [];

  const formattedCountries = countries
    .filter((country) => userShows?.find((userShow) => userShow.venue.countryCode === country.code))
    .map((country: ICountry) => ({
      value: country.code,
      label: country.name,
    }));

  const years: IOption[] = [];
  userShows?.forEach((userShow) => {
    const yearShow = parseInt(userShow.date.split('-')[0]);
    userShow.artists.forEach((artistFromShow) => {
      if (artistFromShow.name && artistFromShow.id && !artists.find((artist) => artist.value === artistFromShow.id)) {
        artists.push({
          label: artistFromShow.name,
          value: artistFromShow.id,
        });
      }
    });
    if (!years.find((year) => year.value === yearShow)) {
      years.push({
        label: yearShow,
        value: yearShow,
      });
    }
    if (!cities.find((city) => city.value === userShow.venue.cityName)) {
      cities.push({
        label: userShow.venue.cityName,
        value: userShow.venue.cityName,
      });
    }
    if (!venues.find((venue) => venue.value === userShow.venue.id)) {
      venues.push({
        label: userShow.venue.name,
        value: userShow.venue.id,
      });
    }
  });

  years.sort((a, b) => (a.value as number) - (b.value as number));
  artists.sort((a, b) => (a.label as string).localeCompare(b.label as string));
  venues.sort((a, b) => (a.label as string).localeCompare(b.label as string));

  const handleSelectUpdate = (option: MultiValue<IOption>, actionMeta: ActionMeta<IOption>) => {
    const filterInfo = {
      values: option,
      name: actionMeta.name as string,
    };
    filterList(filterInfo);
  };

  const resetAllFilters = () => {
    if (filterArtistsRef.current) {
      filterArtistsRef.current.clearValue();
    }
    if (filterVenuesRef.current) {
      filterVenuesRef.current.clearValue();
    }
    if (filterCitiesRef.current) {
      filterCitiesRef.current.clearValue();
    }
    if (filterCountriesRef.current) {
      filterCountriesRef.current.clearValue();
    }
    resetFilterList();
  };

  return (
    <FiltersContainer showFilters={showFilters}>
      <FiltersList>
        {artists.length > 0 && (
          <FilterItem>
            <FilterSelect
              ref={filterArtistsRef}
              placeholder="Artists"
              options={artists}
              handleChange={handleSelectUpdate}
            />
          </FilterItem>
        )}
        {venues.length > 0 && (
          <FilterItem>
            <FilterSelect
              ref={filterVenuesRef}
              placeholder="Venues"
              options={venues}
              handleChange={handleSelectUpdate}
            />
          </FilterItem>
        )}
        {cities.length > 0 && (
          <FilterItem>
            <FilterSelect
              ref={filterCitiesRef}
              placeholder="Cities"
              options={cities}
              handleChange={handleSelectUpdate}
            />
          </FilterItem>
        )}
        {countries.length > 0 && (
          <FilterItem>
            <FilterSelect
              ref={filterCountriesRef}
              placeholder="Countries"
              options={formattedCountries}
              handleChange={handleSelectUpdate}
            />
          </FilterItem>
        )}
        {years.length > 0 && (
          <FilterItem>
            <FilterSelect ref={filterYearsRef} placeholder="Years" options={years} handleChange={handleSelectUpdate} />
          </FilterItem>
        )}
      </FiltersList>
      {nbActiveFilters > 0 && (
        <ResetAll>
          <RedButton onClick={() => resetAllFilters()}>Reset all filters</RedButton>
        </ResetAll>
      )}
    </FiltersContainer>
  );
};

export default ListFilters;
