import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';
import { isPastShow } from '../../../utils/date';

import { SmallModuleContainer, Results, BigNumber } from '../styles';

const TotalPastShowsModule = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const pastShows = userShows?.filter((userShow) => isPastShow(userShow));

  return (
    <SmallModuleContainer>
      <Results center>
        you went to
        <BigNumber>{pastShows?.length || 0}</BigNumber>
        show{pastShows && pastShows.length > 1 && 's'}
      </Results>
    </SmallModuleContainer>
  );
};

export default TotalPastShowsModule;
