import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const TicketPastIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64.9 53">
      <g>
        <path
          d="M3.4,28.5C3.4,28.5,3.4,28.5,3.4,28.5c4.1-1.5,8.7,0.6,10.2,4.8c1.5,4.1-0.6,8.7-4.8,10.2c0,0,0,0,0,0l3.4,9.4L48,40
		l-1.4-3.8c-1,0.4-2.2-0.2-2.6-1.2c-0.4-1,0.2-2.2,1.2-2.6l-1.4-3.8c-1,0.4-2.2-0.2-2.6-1.2c-0.4-1,0.2-2.2,1.2-2.6l-1.4-3.8
		c-1,0.4-2.2-0.2-2.6-1.2s0.2-2.2,1.2-2.6l-1.4-3.8c-1,0.4-2.2-0.2-2.6-1.2c-0.4-1,0.2-2.2,1.2-2.6l-1.4-3.8L0,19.1L3.4,28.5z"
          fill={color || theme.colors['Black']}
        />
      </g>
    </svg>
  );
};

export default TicketPastIcon;
