import styled from 'styled-components';
import { min } from '../../theme/breakpoints';

export const HeaderYear = styled.h2`
  border-radius: 5px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.libreBaskerville};
  font-size: 3.2rem;
  margin: 1.6rem 0;
  color: ${({ theme }) => theme.colors['GoldTips']};

  ${min('tablet')} {
    font-size: 5.6rem;
    margin: 0 0 1.6rem;
  }
`;

export const NumberShowsPerYear = styled.p`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors['White']};
  margin: 0;

  ${min('tablet')} {
    font-size: 1.6rem;
  }
`;
