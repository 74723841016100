import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';
import { isPastShow } from '../../../utils/date';

import { Item, Count, FixedModuleContainer, Results, Info, MainInfo } from '../styles';

const ShowsPerYearModule = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  let countShows: {
    year: string;
    count: number;
  }[] = [];
  userShows?.forEach((userShow) => {
    if (userShow.date && isPastShow(userShow)) {
      const showYear = userShow.date.split('-')[0];
      const indexFoundInCount = countShows.findIndex((showCount) => showCount.year === showYear);
      if (indexFoundInCount > -1) {
        countShows[indexFoundInCount].count += 1;
      } else {
        countShows.push({
          year: showYear,
          count: 1,
        });
      }
    }
  });

  return countShows.length ? (
    <FixedModuleContainer>
      <h3>Shows per year</h3>
      <Results>
        {countShows.map((yearCount) => (
          <Item key={`shows_per_year_${yearCount.year}`}>
            <Info>
              <MainInfo>{yearCount.year}</MainInfo>
            </Info>
            <Count>{yearCount.count}</Count>
          </Item>
        ))}
      </Results>
    </FixedModuleContainer>
  ) : null;
};

export default ShowsPerYearModule;
