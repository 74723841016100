import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ICountry, IShow, IUser } from '../types/model';
import Api from '../helpers/Api';
import { AppDispatch } from '../store';

export interface ApiState {
  countries: ICountry[];
  userShows: IShow[] | null;
}

const initialState: ApiState = {
  countries: [],
  userShows: null,
};

export const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<ICountry[]>) => {
      state.countries = action.payload;
    },
    setUserShows: (state, action: PayloadAction<IShow[]>) => {
      state.userShows = action.payload;
    },
  },
});

export const { setCountries, setUserShows } = apiSlice.actions;

export const fetchCountries = () => async (dispatch: AppDispatch) => {
  return new Promise((resolve, reject) => {
    Api.request<{
      data: ICountry[]
    }>('/countries?pagination[pageSize]=250&sort=name')
      .then((response) => {
        if (response) {
          const formattedCountries = response.data.map((country: any) => ({
            id: country.id,
            ...country.attributes
          }));
          dispatch(setCountries(formattedCountries));
          resolve(formattedCountries);
        } else {
          reject(response);
        }
      })
      .catch((response) => {
        console.error(response);
        reject(response);
      });
  });
};

export const fetchUserShows = () => async (dispatch: AppDispatch) => {
  return new Promise((resolve, reject) => {
    Api.request<IShow[]>('/shows')
      .then((response) => {
        if (response) {
          dispatch(setUserShows(response));
        }
        resolve(response);
      })
      .catch((response) => {
        console.error(response);
        reject(response);
      });
  });
};

export default apiSlice.reducer;
