import { Breakpoints } from '../types/theme';

const breakpoints: Breakpoints = {
  largeDesktop: 1660,
  desktop: 1024,
  tablet: 768,
  mobile: 375
}

export const getMinSizeMedia = (size: keyof Breakpoints) => `${breakpoints[size] + 1}px`;

export const min = (size: keyof Breakpoints) => `@media screen and (min-width: ${getMinSizeMedia(size)})`;

export const max = (size: keyof Breakpoints) => `@media screen and (max-width: ${breakpoints[size]}px)`;

export const minMax = (sizeMin: keyof Breakpoints, sizeMax: keyof Breakpoints) => `@media screen and (min-width: ${getMinSizeMedia(sizeMin)})and (max-width: ${breakpoints[sizeMax]}px)`;

export default breakpoints;
