import axios from 'axios';

class Api {
  static apiURL?: string = `${process.env.REACT_APP_API_URL}/api`;

  static getHeaders() {
    const jwt = localStorage.getItem('jwt')
    return jwt ? {
      'Authorization': `Bearer ${jwt}`
    } : {
    }
  }

  static async request<T>(url: string, params?: Record<string, unknown>, method?: string): Promise<T> {
    return new Promise((resolve, reject) => {
      if ((method && method === 'get') || !method) {
        axios.get<T>(url, {
          baseURL: this.apiURL,
          params: params || {},
          headers: this.getHeaders()
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        axios.request<T>({
          method: method || 'get',
          url,
          baseURL: this.apiURL,
          data: params || {},
          headers: this.getHeaders()
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }
}

export default Api;
