import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const ArtistIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 65.2 69">
      <path
        d="M44.3,30.1c-2.4-0.4-4.1-2.5-4.1-4.9c0-3.6,1.6-6.9,4.1-9.1l-21.5,3.2c-3.9,0.6-6.8,4-6.8,7.9c0,7.9,0,15.9,0,23.8
	c-1.7-1.3-3.7-2-6-2C4.5,49,0,53.5,0,59c0,5.5,4.5,10,10,10s10-4.5,10-10c0-0.2,0-0.4,0-0.7c0-0.1,0-0.2,0-0.3V38.7l36-5.4V44
	c-1.7-1.3-3.7-2-6-2c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10s10-4.5,10-10c0-0.2,0-0.4,0-0.7c0-0.1,0-0.2,0-0.3c0-6.8,0-13.6,0-20.4
	c-1.9,0.2-3.9,0.3-5.8,0.3C50.8,31,47.5,30.7,44.3,30.1z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M54.2,0c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5S58.3,0,54.2,0z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M60,23c0,1.6,0,3.1,0,4.7c1.2-0.1,2.4-0.3,3.5-0.5c0.9-0.2,1.6-1,1.6-2c0-5.1-4.1-9.2-9.2-9.2h-0.1C58.3,17.4,60,20,60,23z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M50.5,16.2c-0.9,0.2-1.7,0.5-2.5,0.9c-0.8,0.4-1.5,1-2.1,1.6c-0.4,0.4-0.8,0.9-1.1,1.4c-1,1.5-1.6,3.2-1.6,5.1
	c0,0.1,0,0.2,0,0.4c0,0.2,0.1,0.4,0.2,0.6s0.3,0.4,0.4,0.5c0.3,0.2,0.6,0.4,0.9,0.4c2.3,0.4,4.7,0.7,7,0.8c0.8,0,1.6,0.1,2.4,0.1
	c2,0,3.9-0.1,5.8-0.3c0-1.6,0-3.1,0-4.7c0-3-1.7-5.6-4.1-7h-3.5C51.7,16,51.1,16.1,50.5,16.2z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default ArtistIcon;
