import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';
import { isPastShow } from '../../../utils/date';

import { Item, Count, FixedModuleContainer, Results, Info, MainInfo, SecondaryInfo } from '../styles';

const TopCitiesModule = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  let countCities: {
    cityName: string;
    countryName: string;
    count: number;
  }[] = [];
  userShows?.forEach((userShow) => {
    if (isPastShow(userShow)) {
      const indexFoundInCount = countCities.findIndex(
        (cityCount) =>
          cityCount.cityName === userShow.venue.cityName && cityCount.countryName === userShow.venue.countryName,
      );
      if (indexFoundInCount > -1) {
        countCities[indexFoundInCount].count += 1;
      } else {
        countCities.push({
          cityName: userShow.venue.cityName,
          countryName: userShow.venue.countryName,
          count: 1,
        });
      }
    }
  });

  countCities.sort((a, b) => b.count - a.count);
  countCities = countCities.slice(0, 7);

  return countCities.length ? (
    <FixedModuleContainer>
      <h3>Top cities</h3>
      <Results>
        {countCities.map((cityCount) => (
          <Item key={`top_cities_${cityCount.cityName}_${cityCount.countryName}`}>
            <Info>
              <MainInfo>{cityCount.cityName}</MainInfo>
              <SecondaryInfo>{cityCount.countryName}</SecondaryInfo>
            </Info>
            <Count>{cityCount.count}</Count>
          </Item>
        ))}
      </Results>
    </FixedModuleContainer>
  ) : null;
};

export default TopCitiesModule;
