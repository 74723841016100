import styled, { css, keyframes } from 'styled-components';
import { min, minMax } from '../../../theme/breakpoints';

const sizeStubMobile = '8.8rem';

export const ShowTicketContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 120rem;
`;

export const ActionButton = styled.button`
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: ${sizeStubMobile};
  padding: 0.8rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4), inset 0 0 0 1px transparent;
  transition: .3s box-shadow ease-out;

  ${min('tablet')} {
    left: inherit;
    bottom: inherit;
    right: 33.333%;
    top: 50%;
  }
  ${min('desktop')} {
    right: 25%;
  }
  &:hover {
    ${min('tablet')} {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.8), inset 0 0 0 1px ${({ theme }) => theme.colors['Casper']};
    }
  }
  svg {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const DeleteButton = styled(ActionButton)`
  transform: translate(-110%, 50%);

  ${min('tablet')} {
    transform: translate(50%, 10%);
  }
`;

export const EditButton = styled(ActionButton)`
  transform: translate(10%, 50%);

  ${min('tablet')} {
    transform: translate(50%, -110%);
  }
`;

export const TicketOuterContent = styled.div`
  height: 100%;
  flex: 1;
  position: relative;

  ${DeleteButton},
  ${EditButton} {
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors['WoodsmokeSecondary']};

    ${minMax('tablet', 'desktop')} {
      background-color: ${({ theme }) => theme.colors['WoodsmokeSecondary']};
    }
  }
`;

export const ArtistsNames = styled.div`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  font-weight: 700;
  font-size: 2em;
  line-height: 1.25;
  flex: 1;

  ${min('mobile')} {
    font-size: 2.4em;
  }
  ${min('desktop')} {
    font-size: 3.2em;
  }
`;

export const ShowInfo = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: auto;
`;

export const Details = styled.div`
  font-size: 1rem;
  flex: 1;
  text-align: center;
  padding: 2.4rem 1.6rem 3.2rem;
  position: relative;
  z-index: 3;
  text-shadow: 0 0 10px ${({ theme }) => theme.colors['Black']};

  ${min('tablet')} {
    text-shadow: none;
    padding: 3.2rem 3.2rem 4rem;
  }
`;

export const VenueInfo = styled.div`
  flex: 1;
  padding: 1.6rem 0.8rem 0;

  ${min('tablet')} {
    padding: 3.2rem 1.6rem 0;
  }
`;

export const VenueName = styled.div`
  font-size: 1.6em;
  font-weight: 700;

  ${min('desktop')} {
    font-size: 1.8em;
  }
  ${min('largeDesktop')} {
    font-size: 2em;
  }
`;

export const City = styled.div`
  font-size: 1.4em;

  ${min('desktop')} {
    font-size: 1.6em;
  }
  ${min('largeDesktop')} {
    font-size: 1.8em;
  }
`;

export const CommentSection = styled.div`
  position: relative;
  display: inline-block;
  text-align: left;
`;

export const CommentDetails = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  right: 0;
  min-width: 15rem;
  z-index: 1;
  color: ${({ theme }) => theme.colors['Black']};
  background-color: ${({ theme }) => theme.colors['Casper']};
  padding: 1.6rem;
  border-radius: 1rem;
  box-shadow: 0 0 20px ${({ theme }) => theme.colors['Black']};
  transition: .2s all ease-in-out;
  transform: translateY(-2px);

  ${min('tablet')} {
    min-width: 20rem;
  }
  ${min('largeDesktop')} {
    min-width: 25rem;
  }
`;


export const CommentButton = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 0.8rem;
  width: 4rem;
  height: 4rem;
  border-color: transparent;
  
  svg {
    display: block;
    width: 100%;

    path {
      fill: ${({ theme }) => theme.colors['GoldTips']};
    }
  }
  &:hover + ${CommentDetails},
  &:focus + ${CommentDetails} {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

const pulseAnimation = () => keyframes`
  from {
    box-shadow: 0 0 1.6rem rgb(242, 203, 64, 0.4);
  }
  to {
    box-shadow: 0 0 3.2rem rgb(242, 203, 64, 0.6);
  }
`;

interface ITicketInnerContentProps {
  readonly: boolean;
  pending: boolean;
  isToday: boolean;
  even: number;
}

export const Price = styled.div`
  font-family: ${({ theme }) => theme.fonts.libreBaskerville};
  margin-bottom: 0.8rem;
`;

export const Seated = styled.div`
`;

export const AdditionalInfo = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.6rem 0.8rem 0;

  ${min('tablet')} {
    padding: 3.2rem 3.2rem 0;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 33.333%;
  padding: 0 0.8rem;

  ${min('tablet')} {
    padding: 0 1.6rem;
  }
`;

export const InfoTitle = styled.div`
  border-bottom: 2px solid;
  font-family: ${({ theme }) => theme.fonts.young};
  padding: 0 0.8rem;
  font-size: 1.2em;

  ${min('tablet')} {
    font-size: 1.4em;
  }
  ${min('desktop')} {
    font-size: 1.6em;
  }
`;

export const InfoDetail = styled.div`
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  text-transform: uppercase;
  margin: auto;
  padding-top: 0.8rem;
  line-height: 1;
  font-size: 1.4em;

  ${min('tablet')} {
    font-size: 1.6em;
  }
  ${min('desktop')} {
    font-size: 1.8em;
  }
`;

interface IBackgroundImage {
  coverImage: boolean;
  desktop: boolean;
  pending: boolean;
}

export const BackgroundImage = styled.div<IBackgroundImage>`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: 50% 30%;
  background-size: cover;
  overflow: hidden;
  border-radius: 1.6rem;
  
  ${min('tablet')} {
    border-radius: 0 1.6rem 1.6rem 0;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 30%;
  }
  &::after {
    content: "";
    display: ${({ coverImage }) => coverImage ? 'block' : 'none'};
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: ${({ pending }) => pending ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.8)'};
    opacity: 1;
    transition: .3s opacity;
    backdrop-filter: ${({ pending }) => pending ? 'blur(0.5px)' : 'blur(5px)'};
    z-index: 1;
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem;
  }
`;

interface IStubProps {
  pending: boolean;
}

export const Stub = styled.div<IStubProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  flex: 0 0 ${sizeStubMobile};
  align-self: stretch;
  padding: 1.6rem 2.4rem;
  border-bottom-left-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem;
  font-size: 0.7rem;
  text-shadow: 0 0 10px ${({ theme }) => theme.colors['Black']};

  ${min('tablet')} {
    flex: 0 0 33.333%;
    border-bottom-left-radius: 0;
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem;
  }
  ${min('desktop')} {
    flex: 0 0 25%;
  }
  > div:not(:first-child) {
    display: none;

    ${min('tablet')} {
      display: block;
    }
  }
  &::before {
    content: "";
    display: ${({ pending }) => !pending ? 'block' : 'none'};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors['WoodsmokePrimary']};
    z-index: 1;
    border-bottom-left-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem;

    ${min('tablet')} {
      display: none;
    }
  }
  ${ArtistsNames}, ${VenueInfo}, ${AdditionalInfo} {
    position: relative;
    z-index: 2;
  }
  ${ArtistsNames}, ${VenueInfo} {
    flex: 0;
  }
  ${VenueInfo} {
    padding: 1.6rem 0;
  }
  ${AdditionalInfo} {
    padding: 0;

    ${InfoContainer} {
      padding: 0 0.8rem;

      &:not(:last-child) {
        border-right: 1px solid;
      }
      ${InfoTitle} {
        border: 0;
        padding: 0;
      }
    }
  }
`;

export const TicketInnerContent = styled.div<ITicketInnerContentProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  background: ${({ pending, isToday, theme }) => isToday ? `repeating-linear-gradient(
    -45deg,
    ${theme.colors['GoldTips']},
    ${theme.colors['GoldTips']} 10px,
    #f0c323 10px,
    #f0c323 20px
  )` : pending ? `repeating-linear-gradient(
    -45deg,
    ${theme.colors['WoodsmokeSecondary']},
    ${theme.colors['WoodsmokeSecondary']} 10px,
    ${theme.colors['WoodsmokePrimary']} 10px,
    ${theme.colors['WoodsmokePrimary']} 20px
  )` : 'none'};
  background-color: ${({ theme }) => theme.colors['WoodsmokeSecondary']};
  text-align: left;
  border-radius: 1.6rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4), inset 0 0 0 1px ${({ theme }) => theme.colors['WoodsmokeSecondary']};
  transition: .3s box-shadow ease-out;
  cursor: pointer;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    opacity: 1;
    transition: .3s opacity;
    z-index: 2;
    border-radius: 1.6rem;
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.colors['WoodsmokePrimary']};
    transition: .3s opacity ease-out;
  }
  > ${BackgroundImage} {
    ${min('tablet')} {
      display: none;
    }
  }
  &:hover {
    ${min('tablet')} {
      margin-top: -1px;
      margin-bottom: 1px;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.5), inset 0 0 0 1px ${({ theme }) => theme.colors['Casper']};

      &::after {
        opacity: 0;
      }
      ${BackgroundImage} {
        top: 1px;
        height: calc(100% - 2px);
        width: calc(100% - 1px);

        &::after {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.6;
          backdrop-filter: none;
        }
      }
    }
  }
  &:focus-visible {
    margin-top: -1px;
    margin-bottom: 1px;

    ${BackgroundImage} {
      &::after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        backdrop-filter: none;
      }
    }
  }
  ${ArtistsNames} {
    color: ${({ theme }) => theme.colors['White']};
  }
  ${InfoTitle}, ${VenueInfo} {
    color: ${({ theme }) => theme.colors['Casper']};
  }
  ${InfoContainer} {
    color: ${({ theme }) => theme.colors['WhiteRock']};
  }
  ${minMax('tablet', 'desktop')} {
    background-color: ${({ theme }) => theme.colors['WoodsmokeSecondary']};
  }
  ${min('tablet')} {
    flex-direction: row;

    ${Details} {
      ${ArtistsNames} {
        color: ${({ theme, isToday }) => isToday ? theme.colors['Black'] : theme.colors['White']};
      }
      ${VenueInfo} {
        color: ${({ theme, isToday }) => isToday ? theme.colors['WoodsmokePrimary'] : theme.colors['Casper']};
      }
      ${AdditionalInfo} {
        color: ${({ theme, isToday }) => isToday ? theme.colors['WoodsmokePrimary'] : theme.colors['WhiteRock']};
      }
      ${InfoTitle}, ${InfoContainer} {
        border-color: ${({ theme, isToday }) => isToday ? theme.colors['WoodsmokePrimary'] : theme.colors['Casper']} !important;
      }
      ${InfoContainer} {
        color: ${({ theme, isToday }) => isToday ? theme.colors['WoodsmokePrimary'] : theme.colors['WhiteRock']};
      }
      ${InfoTitle} {
        color: ${({ theme, isToday }) => isToday ? theme.colors['WoodsmokePrimary'] : theme.colors['Casper']};
      }
    }
  }
  ${({ isToday }) => isToday && css`
    animation: ${pulseAnimation()} 0.8s ease-out infinite alternate;
  `}
`;

export const DotsLine = styled.div`
  width: 100%;
  height: 1.6rem;
  background-image: url("/rounded-dots-horizontal.svg");
  background-repeat: repeat-x;
  background-size: auto 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  transform-origin: 50% 50%;
  z-index: 1;

  ${min('tablet')} {
    width: 1.6rem;
    height: 100%;
    background-image: url("/rounded-dots-vertical.svg");
    background-repeat: repeat-y;
    background-size: 100% auto;
    transform: translateX(-50%);
  }
`;
