import useAlert from '../../../hooks/useAlert';

import { Alert } from './styles';

const AlertPopup = () => {
  const { text, type, visible, disableAnimation } = useAlert();
  return (
    <Alert type={type} visible={visible} disableAnimation={disableAnimation}>
      {text}
    </Alert>
  );
};

export default AlertPopup;
