import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const DragHandleIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 95 95">
      <path
        d="M90,42.5H62.2H45.1h-0.4H33.9H5c-2.8,0-5,2.2-5,5c0,2.8,2.2,5,5,5h28.9h10.8h0.4h17.1H90c2.8,0,5-2.2,5-5
	C95,44.7,92.8,42.5,90,42.5z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M90,12.5H62.2H45.1h-0.4H33.9H5c-2.8,0-5,2.2-5,5c0,2.8,2.2,5,5,5h28.9h10.8h0.4h17.1H90c2.8,0,5-2.2,5-5
	C95,14.7,92.8,12.5,90,12.5z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M90,72.5H62.2H45.1h-0.4H33.9H5c-2.8,0-5,2.2-5,5c0,2.8,2.2,5,5,5h28.9h10.8h0.4h17.1H90c2.8,0,5-2.2,5-5
	C95,74.7,92.8,72.5,90,72.5z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default DragHandleIcon;
