import { ICountry } from '../types';

export const getCountryCurrency = (countries: ICountry[], countryCode: string) => {
  const foundCountries = countries.filter((country) => country.code === countryCode);
  if (foundCountries.length) {
    return foundCountries[0].currency;
  }
  console.error(`Country with code ${countryCode} not found. No currency available.`);
  return null;
};

export const formatPriceWithCurrency = (price: number, currency: string | null) => currency ? new Intl.NumberFormat(Intl.DateTimeFormat().resolvedOptions().locale, { style: 'currency', currency }).format(price) : price;
