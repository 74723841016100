import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const ExternalIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 18 18">
      <path
        d="M11,2c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.3,0,0.5,0.1,0.7,0.3C17.9,0.5,18,0.7,18,1v6c0,0.6-0.4,1-1,1s-1-0.4-1-1V3.4l-9.3,9.3
	c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4L14.6,2H11z M0,4c0-1.1,0.9-2,2-2h5c0.6,0,1,0.4,1,1S7.6,4,7,4H2v12h12v-5
	c0-0.6,0.4-1,1-1s1,0.4,1,1v5c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V4z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default ExternalIcon;
