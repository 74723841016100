import styled from 'styled-components';

export const LayoutContainer = styled.div`
  height: 100vh;
  height: 100dvh;
`;

export const FullContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 0;
`;

export const SuccessMessage = styled.p`
  color: ${({ theme }) => theme.colors['LightGreen']};
  margin-top: 0;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 35rem;
  width: 100%;
  margin: 0 auto;

  &:not(:last-child) {
    margin: 0 auto 3.2rem;
  }
  a {
    margin: 1.6rem auto 0;
    font-size: 1.4rem;
  }
  input {
    &[type="text"], &[type="password"], &[type="email"] {
      width: 100%;
      margin: auto;

      &:focus-visible {
        position: relative;
        z-index: 1;
      }
    }
  }
`;

export const SubmitButton = styled.button`
  width: auto;
  margin: 1rem auto 0;
`;
