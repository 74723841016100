import styled from "styled-components";
import { min } from '../../../theme/breakpoints';

export const Container = styled.div`
  width: 100%;
  max-width: 120rem;
  margin: 1.6rem auto;

  ${min('tablet')} {
    margin: 6.4rem auto 3.2rem;
  }
  h3 {
    text-align: center;
  }
`;

export const Title = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.colors['GoldTips']};
`;
