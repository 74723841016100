import { Dispatch, SetStateAction, useState } from 'react';
import { AppDispatch, useAppDispatch } from '../../../store';
import { fetchUserShows } from '../../../store/apiSlice';
import useAlert from '../../../hooks/useAlert';
import Api from '../../../helpers/Api';
import { IShow } from '../../../types';

import ModalContainer from '../Container';
import ShowCard from '../../ShowItem/Card';

import { GreenButton, RedButton } from '../../Elements/Buttons/styles';
import { ErrorMessage, ShowCardContainer, UserShowActions } from '../styles';

interface IModalDeleteUserShowProps {
  close: () => void;
  setDeleteUserShow: Dispatch<SetStateAction<IShow | null>>;
  setDetailsUserShow: Dispatch<SetStateAction<IShow | null>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  userShow: IShow | null;
}

const ModalDeleteUserShow = ({
  close,
  setDeleteUserShow,
  setDetailsUserShow,
  setLoading,
  userShow,
}: IModalDeleteUserShowProps) => {
  const { setAlert } = useAlert();
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch() as AppDispatch;

  const handleDeleteUserShow = async (userShow: IShow) => {
    setLoading(true);
    Api.request(`/shows/${userShow.id}`, {}, 'delete')
      .then(async () => {
        setAlert('Show removed from the list.', 'success');
        await dispatch(fetchUserShows());
        setDetailsUserShow(null);
        setDeleteUserShow(null);
        setLoading(false);
      })
      .catch((response) => {
        const errorMessage = response?.data?.error?.message;

        if (errorMessage) {
          setError(errorMessage);
        } else {
          setError('An error occured, please try again or contact the administrator.');
        }
        setLoading(false);
      });
  };

  return (
    <ModalContainer visible={!!userShow} withClose handleClose={close}>
      {userShow && (
        <>
          <h3>Delete Show</h3>
          <p>Are you sure you want to remove this show from your list?</p>
          <ShowCardContainer>
            <ShowCard userShow={userShow} readonly />
          </ShowCardContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <UserShowActions>
            <GreenButton onClick={() => handleDeleteUserShow(userShow as IShow)}>Yes</GreenButton>&nbsp;
            <RedButton onClick={() => setDeleteUserShow(null)}>No</RedButton>
          </UserShowActions>
        </>
      )}
    </ModalContainer>
  );
};

export default ModalDeleteUserShow;
