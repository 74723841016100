import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';

import { SmallModuleContainer, Results, BigNumber } from '../styles';
import moment from 'moment';

const TotalFutureArtistsNotSeenModule = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const pastShows = userShows?.filter(
    (userShow) => !userShow.pending && moment(userShow.date).isSameOrBefore(moment(), 'day'),
  );
  const futureShows = userShows?.filter(
    (userShow) => userShow.pending && moment(userShow.date).isAfter(moment(), 'day'),
  );

  const artistsUniquePast: string[] = [];
  const artistsUniqueFuture: string[] = [];

  pastShows?.forEach((userShow) => {
    userShow.artists.forEach((artist) => {
      if (artist.name && !artistsUniquePast.includes(artist.name)) {
        artistsUniquePast.push(artist.name);
      }
    });
  });
  futureShows?.forEach((userShow) => {
    userShow.artists.forEach((artist) => {
      if (artist.name && !artistsUniqueFuture.includes(artist.name)) {
        artistsUniqueFuture.push(artist.name);
      }
    });
  });

  const nbArtists = artistsUniqueFuture.filter((x) => !artistsUniquePast.includes(x)).length;

  return (
    <SmallModuleContainer>
      <Results center>
        including
        <BigNumber>{nbArtists || 0}</BigNumber>
        artist{nbArtists > 1 && 's'} never seen before
      </Results>
    </SmallModuleContainer>
  );
};

export default TotalFutureArtistsNotSeenModule;
