import styled from 'styled-components';
import { min } from '../../../../theme/breakpoints';

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0;

  ${min('tablet')} {
    margin: 1.6rem auto 0;
  }
  > button {
    display: block;
    margin: 1.6rem 1.6rem 0;
  }
`;
interface IListDisplayContainer {
  isMobile: boolean;
}

export const ListDisplayContainer = styled.div<IListDisplayContainer>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -0.8rem;
  padding: 0;
  
  button {
    padding: 0.8rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  button {
    &:first-child {
      display: none;

      ${min('tablet')} {
        display: block;
      }
    }
    svg {
      display: block;
      width: 2.5rem;

      ${min('tablet')} {
        width: 3rem;
      }
    }
  }
`;

export const NumberShows = styled.p`
  margin: auto;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors['White']};

  ${min('tablet')} {
    font-size: 1.6rem;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    width: 100%;
  }
  ${min('tablet')} {
    margin-left: 1.6rem;
  }
  button {
    svg {
      display: block;
      height: 2.5rem;

      ${min('tablet')} {
        height: 3rem;
      }
    }
  }
`;
