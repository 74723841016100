import { createContext, useContext, useEffect, useState } from 'react';
import Api from '../helpers/Api';
import Loader from '../components/Elements/Loader';
import { IUser, IAuthUser } from '../types';

const AuthContext = createContext<any>({});

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    checkUserStatus();
  }, []);

  const loginUser = async (identifier: string, password: string) => {
    return new Promise<IAuthUser>((resolve, reject) => {
      Api.request<IAuthUser>('/auth/local', { identifier, password }, 'post')
        .then((response) => {
          if (response.jwt) {
            localStorage.setItem('jwt', response.jwt);
            setUser(response.user);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  const logoutUser = () => {
    setLoading(true);
    localStorage.removeItem('jwt');
    setUser(null);
    setLoading(false);
  };

  const registerUser = (firstName: string, lastName: string, username: string, email: string, password: string) => {
    return new Promise<IAuthUser>((resolve, reject) => {
      Api.request<IAuthUser>('/auth/local/register', { firstName, lastName, username, email, password }, 'post')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  const checkUserStatus = async () => {
    setLoading(true);
    if (localStorage.getItem('jwt')) {
      try {
        const response = await Api.request<IUser>('/users/me');
        setUser(response);
      } catch (error) {
        localStorage.removeItem('jwt');
      }
    }
    setLoading(false);
  };

  const passwordForgotten = async (email: string) => {
    return new Promise((resolve, reject) => {
      Api.request('/auth/forgot-password', { email }, 'post')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  const resetPassword = async (code: string, password: string, passwordConfirmation: string) => {
    return new Promise((resolve, reject) => {
      Api.request('/auth/reset-password', { code, password, passwordConfirmation }, 'post')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  const contextData = {
    user,
    loginUser,
    logoutUser,
    registerUser,
    checkUserStatus,
    passwordForgotten,
    resetPassword,
  };

  return <AuthContext.Provider value={contextData}>{loading ? <Loader /> : children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
