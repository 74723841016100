import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const CommentIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 63 62.9">
      <rect
        x="8.8"
        y="22.2"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -14.5916 30.8077)"
        width="42.2"
        height="21.6"
        fill={color || theme.colors['Black']}
      />
      <path d="M0.3,57.1c-1.2,4.2,1.2,6.7,5.5,5.5l13.7-3.9L4.2,43.3L0.3,57.1z" fill={color || theme.colors['Black']} />
      <path
        d="M61.2,8.5l-6.8-6.8c-2.3-2.3-6.1-2.3-8.5,0l-5.7,5.7l15.3,15.3l5.7-5.7C63.6,14.6,63.6,10.8,61.2,8.5z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default CommentIcon;
