import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../utils/AuthContext';

import LayoutAuthPage from '../../../components/Layout/Auth';
import Loader from '../../../components/Elements/Loader';

import { ErrorMessage, FormContainer, SubmitButton, SuccessMessage } from '../../../components/Layout/Auth/styles';

const PasswordForgottenPage = () => {
  const { passwordForgotten } = useAuth();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessageDisplayed, setSuccessMessageDisplayed] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    passwordForgotten(email)
      .then(() => {
        setLoading(false);
        setSuccessMessageDisplayed(true);
      })
      .catch((response: any) => {
        const errorMessage = response?.data?.error?.message;
        if (errorMessage) {
          setError(errorMessage);
        }
        setLoading(false);
      });
  };

  return (
    <LayoutAuthPage title="Password Forgotten">
      {loading && <Loader />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <FormContainer onSubmit={submitForm}>
        {successMessageDisplayed ? (
          <SuccessMessage>
            If the e-mail address you entered exists in our database, you'll receive a message shortly.
          </SuccessMessage>
        ) : (
          <>
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              required
              value={email}
              onInput={(e) => setEmail(e.currentTarget.value)}
            />
            <SubmitButton type="submit">Reset</SubmitButton>
          </>
        )}
        <Link to={'/login'}>Back to login</Link>
      </FormContainer>
    </LayoutAuthPage>
  );
};

export default PasswordForgottenPage;
