import { useEffect, useRef, useState, Dispatch, SetStateAction, useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import Select, { ActionMeta } from 'react-select';
import { useTheme } from 'styled-components';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../../store';
import {
  setSelectedVenueResult,
  setVenueRequestPerformed,
  setVenueResults,
  setVenueValues,
} from '../../../../store/formSlice';
import { defaultVenueValues } from '../../../../constants';
import { IOption, IVenue, TVenueFormFields, Theme } from '../../../../types';
import Api from '../../../../helpers/Api';

import { RedButton } from '../../../Elements/Buttons/styles';
import { FormActions } from './styles';

interface IVenueFormProps {
  loading: boolean;
  setError: Dispatch<SetStateAction<string | null>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const VenueForm = ({ loading, setError, setLoading }: IVenueFormProps) => {
  const theme = useTheme() as Theme;
  const selectCountryRef = useRef<any>();
  const [init, setInit] = useState(false);
  const [displayResetForm, setDisplayResetForm] = useState(false);

  const dispatch = useAppDispatch() as AppDispatch;
  const { countries, venueFormValues, venueRequestPerformed, searchFormValues } = useAppSelector(
    (state) => ({
      countries: state.api.countries,
      venueFormValues: state.form.venue.values,
      venueRequestPerformed: state.form.venue.requestPerformed,
      searchFormValues: state.form.search.values,
    }),
    shallowEqual,
  );

  const [venueFormFields, setVenueFormFields] = useState<TVenueFormFields>({
    cityName: venueFormValues.cityName || searchFormValues.cityName,
    country: venueFormValues.country || searchFormValues.countryCode,
    name: venueFormValues.name || searchFormValues.venueName,
  });

  useEffect(() => {
    const nbParamsFilled = Object.keys(venueFormFields).filter((paramKey) => {
      return venueFormFields[paramKey as keyof TVenueFormFields];
    });

    if (nbParamsFilled.length > 0) {
      setDisplayResetForm(true);
    } else {
      setDisplayResetForm(false);
    }
  }, [venueFormFields]);

  const handleVenueFormUpdate = (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    setVenueFormFields({
      ...venueFormFields,
      [event.currentTarget.name as keyof TVenueFormFields]: event.currentTarget.value,
    });
  };

  const handleSelectUpdate = (option: IOption | null, actionMeta: ActionMeta<IOption>) => {
    setVenueFormFields({
      ...venueFormFields,
      [actionMeta.name as keyof TVenueFormFields]: option,
    });
  };

  const resetFormValues = () => {
    selectCountryRef.current.clearValue();
    setVenueFormFields(defaultVenueValues);
  };

  const performRequest = useCallback(async () => {
    setLoading(true);

    // Reset
    setError(null);
    dispatch(setVenueRequestPerformed(false));
    dispatch(setVenueResults([]));
    dispatch(setVenueValues(venueFormFields));
    dispatch(setSelectedVenueResult(-1));

    const countryFilter = venueFormFields.country?.value
      ? {
          country: venueFormFields.country?.value,
        }
      : {};

    // Perform request
    const response = await Api.request<IVenue[]>('venues', {
      ...venueFormFields,
      ...countryFilter,
    });
    if (response) {
      dispatch(setVenueResults(response));
    }
    dispatch(setVenueRequestPerformed(true));
    setLoading(false);
  }, [dispatch, setError, setLoading, venueFormFields]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!venueFormFields.name || !venueFormFields.country || !venueFormFields.cityName) {
      setError('You have to fill the 3 fields above to continue.');
      return;
    }

    performRequest();
  };

  useEffect(() => {
    if (!init) {
      setInit(true);
    }
  }, [init]);

  useEffect(() => {
    if (
      venueFormFields.cityName &&
      venueFormFields.country &&
      venueFormFields.name &&
      !venueRequestPerformed &&
      !loading &&
      !init
    ) {
      performRequest();
    }
  }, [venueFormFields, venueRequestPerformed, loading, init, performRequest]);

  const formattedCountries = countries.map((country) => ({
    value: country.code,
    label: country.name,
  }));

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Venue name"
        name="name"
        value={venueFormFields.name}
        onInput={handleVenueFormUpdate}
        disabled={loading}
      />
      <input
        type="text"
        placeholder="City name"
        name="cityName"
        value={venueFormFields.cityName}
        onInput={handleVenueFormUpdate}
        disabled={loading}
      />
      {countries.length > 0 && (
        <Select
          ref={selectCountryRef}
          classNames={{
            container: () => 'custom-select',
            control: () => 'control',
            option: (state) => (state.isSelected ? 'selected' : ''),
            placeholder: () => 'placeholder',
          }}
          theme={(selectTheme) => ({
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: theme.colors['Casper'],
              primary25: theme.colors['WoodsmokeSecondary'],
            },
          })}
          styles={{
            control: (base, state) => ({
              ...base,
              outlineOffset: '2px',
              outline: state.isFocused ? `2px solid ${theme.colors['GoldTips']}` : '0',
              boxShadow: 'none'
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected ? theme.colors['GoldTips'] : 'transparent'
            }),
          }}
          placeholder="Country"
          name="country"
          isDisabled={loading}
          options={formattedCountries}
          defaultValue={venueFormFields.country}
          onChange={handleSelectUpdate}
          isClearable
          isSearchable
        />
      )}
      <FormActions>
        <button disabled={loading} type="submit">
          Search
        </button>
        &nbsp;
        <RedButton type="button" disabled={!displayResetForm} onClick={resetFormValues}>
          Reset
        </RedButton>
      </FormActions>
    </form>
  );
};

export default VenueForm;
