import { Fragment, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import moment from 'moment';
import { useAppSelector } from '../../../store';
// @ts-ignore
import CanvasJSReact from '@canvasjs/react-charts';

import { ModuleContainer, ChartContainer, CurrencyChart } from './styles';
import { useTheme } from 'styled-components';
import { Theme } from '../../../types';
import { formatPriceWithCurrency } from '../../../utils/price';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const PricesPerYear = () => {
  const theme = useTheme() as Theme;
  const { countries, userShows } = useAppSelector(
    (state) => ({
      countries: state.api.countries,
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  if (!userShows?.length) {
    return null;
  }

  let currenciesWithYears: {
    currency: string;
    years: {
      [key: string]: number[];
    };
  }[] = [];

  userShows?.forEach((userShow) => {
    const { date, price, venue } = userShow;
    const currentShowCountries = countries.filter((country) => country.code === venue.countryCode);
    if (price && currentShowCountries.length === 1) {
      const showDate = moment(date);
      const year = `${showDate.year()}`;
      const currentShowCurrency = currentShowCountries[0].currency;
      if (
        currenciesWithYears.filter((currencyWithYear) => currencyWithYear.currency === currentShowCurrency).length === 0
      ) {
        currenciesWithYears.push({
          currency: currentShowCurrency,
          years: {
            [year]: [price],
          },
        });
      } else {
        // If currency already exist
        currenciesWithYears = currenciesWithYears.map((currencyWithYear) => {
          if (currencyWithYear.currency === currentShowCurrency) {
            if (!currencyWithYear.years[year]) {
              currencyWithYear.years[year] = [price];
            } else {
              currencyWithYear.years[year].push(price);
            }
          }
          return currencyWithYear;
        });
      }
    }
  });

  const chartsData = currenciesWithYears
    .filter((currencyWithYear) => Object.keys(currencyWithYear.years).length > 1)
    .map((currencyWithYear) => {
      const dataPointsTotal = Object.keys(currencyWithYear.years).map((year) => {
        const totalPrice = currencyWithYear.years[year].reduce((a, b) => a + b);
        const formattedPrice = formatPriceWithCurrency(totalPrice, currencyWithYear.currency);
        return {
          x: new Date(parseInt(year), 0, 1),
          y: totalPrice,
          indexLabel: formattedPrice,
          toolTipContent: `Total spent in {x}: ${formattedPrice}`,
        };
      });
      const dataPointsAverage = Object.keys(currencyWithYear.years).map((year) => {
        const totalPrice = currencyWithYear.years[year].reduce((a, b) => a + b);
        const averagePrice = totalPrice / currencyWithYear.years[year].length;
        const formattedPrice = formatPriceWithCurrency(averagePrice, currencyWithYear.currency);
        return {
          x: new Date(parseInt(year), 0, 1),
          y: averagePrice,
          indexLabel: formattedPrice,
          toolTipContent: `Average price of a ticket in {x}: ${formattedPrice}`,
        };
      });
      return {
        dataPointsTotal,
        dataPointsAverage,
        currency: currencyWithYear.currency,
      };
    });

  CanvasJS.addColorSet('custom', [theme.colors['GoldTips'], theme.colors['CuriousBlue']]);

  const graphs = chartsData.map((chartData) => {
    let chart = useRef<any>(null);

    const toggleDataSeries = (e: any) => {
      if (typeof e.dataSeries.visible === 'undefined' || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      // @ts-ignore
      chart.render();
    };

    const options = {
      theme: 'dark2',
      backgroundColor: 'transparent',
      colorSet: 'custom',
      axisX: {
        intervalType: 'year',
        interval: 1,
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
        },
      },
      axisY: {
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
        },
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: 'pointer',
        fontSize: 16,
        itemclick: toggleDataSeries,
      },
      data: [
        {
          name: 'Total amount spent',
          xValueFormatString: 'YYYY',
          type: 'spline',
          dataPoints: chartData.dataPointsTotal,
          showInLegend: true,
        },
        {
          name: 'Average price of a ticket',
          xValueFormatString: 'YYYY',
          type: 'spline',
          dataPoints: chartData.dataPointsAverage,
          showInLegend: true,
        },
      ],
    };
    return (
      <CurrencyChart key={`chart_pricePerYear_${chartData.currency}`}>
        <h4>Currency: {chartData.currency}</h4>
        <CanvasJSChart onRef={(ref: any) => (chart = ref)} options={options} />
      </CurrencyChart>
    );
  });

  return (
    <ModuleContainer>
      <h3>Amount spent per year</h3>
      <p>How much you spent on shows and the average price of a ticket per year (by currency)</p>
      <ChartContainer>{graphs}</ChartContainer>
    </ModuleContainer>
  );
};

export default PricesPerYear;
