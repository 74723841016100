import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';
import { isPastShow } from '../../../utils/date';

import { Item, Count, FixedModuleContainer, Results, Info, MainInfo } from '../styles';

const TopArtistsModule = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  let countArtists: {
    name: string;
    count: number;
  }[] = [];
  userShows?.forEach((userShow) => {
    if (isPastShow(userShow)) {
      userShow.artists.forEach((artist) => {
        if (artist.name) {
          const indexFoundInCount = countArtists.findIndex((artistCount) => artistCount.name === artist.name);
          if (indexFoundInCount > -1) {
            countArtists[indexFoundInCount].count += 1;
          } else {
            countArtists.push({
              name: artist.name,
              count: 1,
            });
          }
        }
      });
    }
  });

  countArtists = countArtists.filter((artistCount) => artistCount.count > 1);
  countArtists.sort((a, b) => b.count - a.count);
  countArtists = countArtists.slice(0, 10);

  return countArtists.length ? (
    <FixedModuleContainer>
      <h3>Top artists</h3>
      <Results>
        {countArtists.map((artistCount) => (
          <Item key={`top_artists_${artistCount.name}`}>
            <Info>
              <MainInfo>{artistCount.name}</MainInfo>
            </Info>
            <Count>{artistCount.count}</Count>
          </Item>
        ))}
      </Results>
    </FixedModuleContainer>
  ) : null;
};

export default TopArtistsModule;
