import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../utils/AuthContext';

import LayoutAuthPage from '../../../components/Layout/Auth';
import Loader from '../../../components/Elements/Loader';

import { ErrorMessage, FormContainer, SubmitButton } from '../../../components/Layout/Auth/styles';
import { PasswordInput } from './styles';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    if (password !== passwordConfirmation) {
      setError("Passwords don't match");
      return;
    }
    setLoading(true);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');

    resetPassword(code, password, passwordConfirmation)
      .then(() => {
        setLoading(false);
        navigate('/login?password-reset=success');
      })
      .catch((response: any) => {
        const errorMessage = response?.data?.error?.message;
        if (errorMessage) {
          setError(errorMessage);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeParam = urlParams.get('code');

    if (!codeParam) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <LayoutAuthPage title="Reset Password">
      {loading && <Loader />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <FormContainer onSubmit={submitForm}>
        <PasswordInput
          type="password"
          name="password"
          placeholder="New password"
          required
          value={password}
          onInput={(e) => setPassword(e.currentTarget.value)}
        />
        <PasswordInput
          type="password"
          name="passwordConfirmation"
          placeholder="Confirm new password"
          required
          value={passwordConfirmation}
          onInput={(e) => setPasswordConfirmation(e.currentTarget.value)}
        />
        <SubmitButton type="submit">Reset</SubmitButton>
      </FormContainer>
    </LayoutAuthPage>
  );
};

export default ResetPasswordPage;
