import styled from 'styled-components';

export const VenueFormContainer = styled.div`
  form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    margin-top: 3.2rem;
  }
`;

export const Actions = styled.div`
  margin-top: 1.6rem;
`;
