import { useTheme } from 'styled-components';
import { TicketFutureIcon, TicketPastIcon } from '../../Elements/Icons';
import { ButtonTile } from './styles';
import { Theme } from '../../../types';
import { AppDispatch, useAppDispatch } from '../../../store';
import { setFormFuture } from '../../../store/formSlice';

interface IFuturePastFormProps {
  goToPastEvent: () => void;
  goToFutureEvent: () => void;
}

const FuturePastForm = ({ goToPastEvent, goToFutureEvent }: IFuturePastFormProps) => {
  const theme = useTheme() as Theme;
  const dispatch = useAppDispatch() as AppDispatch;

  const handleGoToPastEvent = () => {
    dispatch(setFormFuture(false));
    goToPastEvent();
  };

  const handleGoToFuturEvent = () => {
    dispatch(setFormFuture(true));
    goToFutureEvent();
  };

  return (
    <div>
      <ButtonTile onClick={() => handleGoToPastEvent()}>
        <TicketPastIcon color={theme.colors['LightGreen']} />
        Past show
      </ButtonTile>
      <ButtonTile onClick={() => handleGoToFuturEvent()}>
        <TicketFutureIcon color={theme.colors['GoldTips']} />
        Future show
      </ButtonTile>
    </div>
  );
};

export default FuturePastForm;
