import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import moment from 'moment';
import Select, { ActionMeta } from 'react-select';
import DatePicker from 'react-date-picker';
import { useTheme } from 'styled-components';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../../store';
import { fetchUserShows } from '../../../../store/apiSlice';
import {
  setArtistsRequestPerformed,
  setArtistsResults,
  setSearchRequestPerformed,
  setSearchResults,
  setSearchValues,
  setSelectedSearchResult,
  setVenueRequestPerformed,
  setVenueResults,
  setVenueValues,
} from '../../../../store/formSlice';
import Api from '../../../../helpers/Api';
import { defaultSearchValues, defaultVenueValues } from '../../../../constants';
import { DateValue, ICountry, IOption, IShow, TSearchFormFields, Theme } from '../../../../types';

import { GrayButton, RedButton } from '../../../Elements/Buttons/styles';
import { FormActions, FormContainer } from './styles';

interface ISearchFormProps {
  goToPreviousStep: () => void;
  loading: boolean;
  setError: Dispatch<SetStateAction<string | null>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const SearchForm = ({ goToPreviousStep, loading, setError, setLoading }: ISearchFormProps) => {
  const theme = useTheme() as Theme;
  const dispatch = useAppDispatch() as AppDispatch;
  const selectDateOrYearRef = useRef<any>();
  const selectCountryRef = useRef<any>();
  const selectYearRef = useRef<any>();
  const [displayResetForm, setDisplayResetForm] = useState(false);
  const { countries, searchFormValues, userShows } = useAppSelector(
    (state) => ({
      countries: state.api.countries,
      searchFormValues: state.form.search.values,
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const [searchFormFields, setSearchFormFields] = useState<TSearchFormFields>({
    artistName: '',
    cityName: '',
    countryCode: null,
    date: null,
    dateOrYear: null,
    venueName: '',
    year: null,
  });

  useEffect(() => {
    if (!searchFormValues.countryCode && selectCountryRef.current) {
      selectCountryRef.current.clearValue();
    }
    if (!searchFormValues.year && selectYearRef.current) {
      selectYearRef.current.clearValue();
    }
    if (!searchFormValues.dateOrYear && selectDateOrYearRef.current) {
      selectDateOrYearRef.current.clearValue();
    }
    setSearchFormFields(searchFormValues);
  }, [searchFormValues]);

  useEffect(() => {
    const nbParamsFilled = Object.keys(searchFormFields).filter((paramKey) => {
      return searchFormFields[paramKey as keyof TSearchFormFields];
    });

    if (nbParamsFilled.length > 0) {
      setDisplayResetForm(true);
    } else {
      setDisplayResetForm(false);
    }
  }, [searchFormFields]);

  const handleSearchFormUpdate = (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    setSearchFormFields({
      ...searchFormFields,
      [event.currentTarget.name as keyof TSearchFormFields]: event.currentTarget.value,
    });
  };

  const handleSelectUpdate = (option: IOption | null, actionMeta: ActionMeta<IOption>) => {
    setSearchFormFields({
      ...searchFormFields,
      [actionMeta.name as keyof TSearchFormFields]: option,
    });
  };

  const handleDateUpdate = (value: DateValue) => {
    setSearchFormFields({
      ...searchFormFields,
      date: value,
    });
  };

  const resetFormValues = () => {
    if (selectDateOrYearRef.current) {
      selectDateOrYearRef.current.clearValue();
    }
    if (selectCountryRef.current) {
      selectCountryRef.current.clearValue();
    }
    if (selectYearRef.current) {
      selectYearRef.current.clearValue();
    }
    setSearchFormFields(defaultSearchValues);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    const nbParamsFilled = Object.keys(searchFormFields).filter((paramKey) => {
      return searchFormFields[paramKey as keyof TSearchFormFields];
    });

    if (nbParamsFilled.length < 2) {
      setError("You have to give at least two information about the show you're looking for.");
    } else {
      setLoading(true);
      // Reset artists
      dispatch(setArtistsRequestPerformed(false));
      dispatch(setArtistsResults([]));
      // Reset venue
      dispatch(setVenueRequestPerformed(false));
      dispatch(setVenueResults([]));
      dispatch(setVenueValues(defaultVenueValues));

      // Format the date
      let dateFilter = {};
      if (searchFormFields.date) {
        const dateFormatted = new Date(searchFormFields.date as Date);
        const day = dateFormatted.getDate() < 10 ? `0${dateFormatted.getDate()}` : dateFormatted.getDate();
        const month =
          dateFormatted.getMonth() + 1 < 10 ? `0${dateFormatted.getMonth() + 1}` : dateFormatted.getMonth() + 1;
        const year = dateFormatted.getFullYear();
        dateFilter = {
          date: `${year}-${month}-${day}`,
        };
      }

      dispatch(
        setSearchValues({
          ...searchFormFields,
          ...dateFilter,
        }),
      );
      dispatch(setSearchRequestPerformed(false));
      dispatch(setSelectedSearchResult(-1));
      dispatch(setSearchResults([]));

      if (!userShows) {
        await dispatch(fetchUserShows());
      }

      const yearFilter = searchFormFields.year?.value
        ? {
            year: searchFormFields.year?.value,
          }
        : {};

      const countryFilter = searchFormFields.countryCode?.value
        ? {
            countryCode: searchFormFields.countryCode?.value,
          }
        : {};

      // Perform request
      const response = await Api.request<IShow[]>('search/shows', {
        ...searchFormFields,
        ...dateFilter,
        ...countryFilter,
        ...yearFilter,
      });
      if (response) {
        dispatch(setSearchResults(response));
      }
      dispatch(setSearchRequestPerformed(true));
      setLoading(false);
    }
  };

  const years = [];
  for (let i = new Date().getFullYear(); i >= 1950; i--) {
    years.push({
      label: i,
      value: i,
    });
  }

  const formattedCountries = countries.map((country: ICountry) => ({
    value: country.code,
    label: country.name,
  }));

  return (
    <FormContainer onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Artist name"
        name="artistName"
        value={searchFormFields.artistName}
        onInput={handleSearchFormUpdate}
        disabled={loading}
      />
      <input
        type="text"
        placeholder="Venue name"
        name="venueName"
        value={searchFormFields.venueName}
        onInput={handleSearchFormUpdate}
        disabled={loading}
      />
      <input
        type="text"
        placeholder="City name"
        name="cityName"
        value={searchFormFields.cityName}
        onInput={handleSearchFormUpdate}
        disabled={loading}
      />
      {countries.length > 0 && (
        <Select
          ref={selectCountryRef}
          classNames={{
            container: () => 'custom-select',
            control: () => 'control',
            option: (state) => (state.isSelected ? 'selected' : ''),
            placeholder: () => 'placeholder',
          }}
          theme={(selectTheme) => ({
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: theme.colors['Casper'],
              primary25: theme.colors['WoodsmokeSecondary'],
            },
          })}
          styles={{
            control: (base, state) => ({
              ...base,
              outlineOffset: '2px',
              outline: state.isFocused ? `2px solid ${theme.colors['GoldTips']}` : '0',
              boxShadow: 'none',
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? theme.colors['WoodsmokePrimary'] : 'transparent',
            }),
          }}
          placeholder="Country"
          name="countryCode"
          isDisabled={loading}
          options={formattedCountries}
          defaultValue={searchFormValues.countryCode}
          onChange={handleSelectUpdate}
          isClearable
          isSearchable
        />
      )}
      <Select
        ref={selectDateOrYearRef}
        classNames={{
          container: () => 'custom-select',
          control: () => 'control',
          option: (state) => (state.isSelected ? 'selected' : ''),
          placeholder: () => 'placeholder',
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            outlineOffset: '2px',
            outline: state.isFocused ? `2px solid ${theme.colors['GoldTips']}` : '0',
            boxShadow: 'none',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? theme.colors['WoodsmokePrimary'] : 'transparent',
          }),
        }}
        placeholder="Date / Year"
        name="dateOrYear"
        isDisabled={loading}
        options={[
          {
            label: 'Date',
            value: 'date',
          },
          {
            label: 'Year',
            value: 'year',
          },
        ]}
        onChange={handleSelectUpdate}
        defaultValue={searchFormValues.dateOrYear}
        theme={(selectTheme) => ({
          ...selectTheme,
          colors: {
            ...selectTheme.colors,
            primary: theme.colors['Casper'],
            primary25: theme.colors['WoodsmokeSecondary'],
          },
        })}
      />
      {searchFormFields.dateOrYear?.value === 'date' && (
        <DatePicker
          onChange={handleDateUpdate}
          value={searchFormFields.date ? moment(searchFormFields.date as unknown as string).toDate() : null}
          disabled={loading}
          dayPlaceholder={'DD'}
          monthPlaceholder={'MM'}
          yearPlaceholder={'YYYY'}
          maxDate={new Date()}
          format={'y-MM-dd'}
          openCalendarOnFocus={false}
          showLeadingZeros
        />
      )}
      {searchFormFields.dateOrYear?.value === 'year' && (
        <Select
          ref={selectYearRef}
          classNames={{
            container: () => 'custom-select',
            option: (state) => (state.isSelected ? 'selected' : ''),
            placeholder: () => 'placeholder',
          }}
          styles={{
            control: (base, state) => ({
              ...base,
              outlineOffset: '2px',
              outline: state.isFocused ? `2px solid ${theme.colors['GoldTips']}` : '0',
              boxShadow: 'none',
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected ? theme.colors['GoldTips'] : 'transparent',
            }),
          }}
          placeholder="Year"
          name="year"
          isDisabled={loading}
          options={years}
          onChange={handleSelectUpdate}
          defaultValue={searchFormValues.year}
          isClearable
          isSearchable
          theme={(selectTheme) => ({
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: theme.colors['Casper'],
              primary25: theme.colors['WoodsmokeSecondary'],
            },
          })}
        />
      )}
      <FormActions>
        <GrayButton onClick={goToPreviousStep}>Back</GrayButton>
        &nbsp;
        <button disabled={loading} type="submit">
          Search
        </button>
        &nbsp;
        <RedButton type="button" disabled={!displayResetForm} onClick={resetFormValues}>
          Reset
        </RedButton>
      </FormActions>
    </FormContainer>
  );
};

export default SearchForm;
