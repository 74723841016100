import moment from 'moment';
import { IShow } from '../../../types/model';

import ShowTicket from '../../ShowItem/Ticket';

import { Container, Title } from './styles';

interface INextShowProps {
  items: IShow[];
  setDeleteUserShow: React.Dispatch<React.SetStateAction<IShow | null>>;
  setDetailsUserShow: React.Dispatch<React.SetStateAction<IShow | null>>;
  editUserShow: (userShow: IShow) => void;
}

const NextShow = ({ items, setDeleteUserShow, setDetailsUserShow, editUserShow }: INextShowProps) => {
  const nextUpcomingShows = items
    .filter((show) => show.pending && moment(show.date).isSameOrAfter(moment(), 'day'))
    .sort((a, b) => (moment(a.date).isAfter(b.date) ? 1 : -1));

  return nextUpcomingShows.length > 0 ? (
    <Container>
      <Title>Next show</Title>
      <ShowTicket
        userShow={nextUpcomingShows[0]}
        setDeleteUserShow={setDeleteUserShow}
        editUserShow={editUserShow}
        setDetailsUserShow={setDetailsUserShow}
      />
    </Container>
  ) : null;
};

export default NextShow;
