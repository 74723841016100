import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../utils/AuthContext';

import LayoutAuthPage from '../../../components/Layout/Auth';
import Loader from '../../../components/Elements/Loader';

import { ErrorMessage, FormContainer, SubmitButton, SuccessMessage } from '../../../components/Layout/Auth/styles';
import { PasswordInput, UsernameInput } from './styles';

const LoginPage = () => {
  const { loginUser } = useAuth();
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    loginUser(identifier, password)
      .then(() => {
        setLoading(false);
      })
      .catch((response: any) => {
        const errorMessage = response?.data?.error?.message;
        if (errorMessage) {
          setError(errorMessage);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const validation = urlParams.get('validation');
    const passwordReset = urlParams.get('password-reset');

    if (validation === 'success') {
      setSuccessMessage('E-mail validated! 🎉 You can now login.');
    }
    if (passwordReset === 'success') {
      setSuccessMessage('Password reset! You can now login with your new password.');
    }
  }, []);

  return (
    <LayoutAuthPage title="Sign in">
      {loading && <Loader />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      <FormContainer onSubmit={submitForm}>
        <UsernameInput
          type="text"
          name="identifier"
          placeholder="Username"
          value={identifier}
          onInput={(e) => setIdentifier(e.currentTarget.value)}
        />
        <PasswordInput
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onInput={(e) => setPassword(e.currentTarget.value)}
        />
        <SubmitButton type="submit">Login</SubmitButton>
        <Link to={'/password-forgotten'}>Password forgotten</Link>
        <p>
          You don't have an account? <Link to={'/register'}>Create one now</Link>
        </p>
      </FormContainer>
    </LayoutAuthPage>
  );
};

export default LoginPage;
