import styled from 'styled-components';
import { min } from '../../../../theme/breakpoints';

interface IFiltersContainerProps {
  showFilters: boolean;
}

export const FiltersContainer = styled.div<IFiltersContainerProps>`
  width: 100%;
  max-width: 120rem;
  display: ${({ showFilters }) => showFilters ? 'block' : 'none'};
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 1.6rem auto;

  ${min('tablet')} {
    margin: 1.6rem auto 3.2rem;
    flex-direction: row;
  }
  .custom-select {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const FiltersList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0;
  gap: 1.6rem;

  ${min('tablet')} {
    flex-direction: row;
    gap: 3.2rem;
  }
  .custom-select {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  ${min('tablet')} {
    flex: 0 0 calc(33.333% - 2.4rem);
  }
  ${min('desktop')} {
    flex: 0 0 calc(25% - 2.4rem);
  }
  ${min('largeDesktop')} {
    flex: 1;
  }
`;

export const ResetAll = styled.div`
  margin: 1.6rem auto 0;
`;
