import styled from 'styled-components';
import { min } from '../../../theme/breakpoints';

export const PageContainer = styled.div`
  margin: 1.6rem 0 0;

  ${min('tablet')} {
    margin: 3.2rem;
  }
`;

export const FormSection = styled.div`
  margin: 1.6rem 0;
`;

export const LogoutContainer = styled.div`
  margin-top: 3.2rem;
`;
