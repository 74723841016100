import styled from 'styled-components';

export const UserShowActions = styled.div`
  margin-top: 1rem;
`;

export const ShowCardContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1.6rem;

  > div {
    flex: 1;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors['LightRed']}
`;
