import React from 'react';
import { shallowEqual } from 'react-redux';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { Theme, IShow } from '../../../types';
import { useAppSelector } from '../../../store';
import { formatPriceWithCurrency, getCountryCurrency, userShowsGroupedPerYear } from '../../../utils';

import { CommentIcon, DeleteIcon, EditIcon } from '../../Elements/Icons';

import { HeaderYear, NumberShowsPerYear } from '../styles';
import { CommentButton, CommentDetails, CommentSection } from '../../ShowItem/Card/styles';
import {
  TableContainer,
  Table,
  HeaderColumn,
  Row,
  Column,
  InvisibleButton,
  ActionsColumn,
  ActionButton,
} from './styles';

interface IListTableProps {
  items: IShow[];
  setDeleteUserShow: React.Dispatch<React.SetStateAction<IShow | null>>;
  setDetailsUserShow: React.Dispatch<React.SetStateAction<IShow | null>>;
  editUserShow: (userShow: IShow) => void;
}

const ListTable = ({ items, setDeleteUserShow, setDetailsUserShow, editUserShow }: IListTableProps) => {
  const theme = useTheme() as Theme;
  const { countries } = useAppSelector(
    (state) => ({
      countries: state.api.countries,
    }),
    shallowEqual,
  );

  const groupedUserShows = items.length ? userShowsGroupedPerYear(items) : {};

  const generateRows = (shows: IShow[]) =>
    shows.map((userShow: IShow, index: number) => {
      const { artists, comment, date, id, venue, pending, price, seated } = userShow;
      const isToday = moment(date).isSame(moment(), 'day');
      return (
        <Row even={index % 2} key={`user_show_${id}`} pending={pending} isToday={isToday}>
          <Column style={{ minWidth: '20rem' }}>
            <InvisibleButton onClick={() => setDetailsUserShow(userShow)} withComment={!!comment} />
            {artists.map((artist, index: number) => `${artist.name}${index < artists.length - 1 ? ' + ' : ''}`)}
          </Column>
          <Column width="25%" style={{ minWidth: '15rem' }}>
            {venue.name}
          </Column>
          <Column width="150rem" style={{ whiteSpace: 'nowrap' }}>
            {date}
          </Column>
          <Column width="15%">
            {venue.cityName} ({venue.countryCode})
          </Column>
          <Column width="100rem">{seated ? 'Yes' : 'No'}</Column>
          <Column width="10%">
            {price && formatPriceWithCurrency(price, getCountryCurrency(countries, venue.countryCode))}
          </Column>
          <ActionsColumn width="140rem">
            {comment && (
              <CommentSection>
                <CommentButton>
                  <CommentIcon color={isToday ? theme.colors['Black'] : theme.colors['GoldTips']} />
                </CommentButton>
                <CommentDetails>{comment}</CommentDetails>
              </CommentSection>
            )}
            <ActionButton onClick={() => editUserShow(userShow)} aria-label="Edit the show">
              <EditIcon color={isToday ? theme.colors['Black'] : theme.colors['GoldTips']} />
            </ActionButton>
            <ActionButton onClick={() => setDeleteUserShow(userShow)} aria-label="Delete the show">
              <DeleteIcon color={isToday ? theme.colors['Black'] : theme.colors['GoldTips']} />
            </ActionButton>
          </ActionsColumn>
        </Row>
      );
    });

  return (
    <>
      {Object.keys(groupedUserShows).map((year) => {
        const nbShowYear = groupedUserShows[year].length;
        return (
          <React.Fragment key={`table_list_${year}`}>
            <HeaderYear>
              {year.replace('year-', '')}
              <NumberShowsPerYear>
                {nbShowYear} show{nbShowYear > 1 && 's'}
              </NumberShowsPerYear>
            </HeaderYear>
            <TableContainer>
              <Table cellSpacing={0}>
                <thead>
                  <tr>
                    <HeaderColumn>Artist(s)</HeaderColumn>
                    <HeaderColumn>Venue</HeaderColumn>
                    <HeaderColumn>Date</HeaderColumn>
                    <HeaderColumn>City</HeaderColumn>
                    <HeaderColumn>Seated</HeaderColumn>
                    <HeaderColumn>Price</HeaderColumn>
                    <HeaderColumn></HeaderColumn>
                  </tr>
                </thead>
                <tbody>{generateRows(groupedUserShows[year])}</tbody>
              </Table>
            </TableContainer>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ListTable;
