import { IShow } from "../types";

export const userShowsGroupedPerYear = (items: IShow[]) => items.reduce<Record<string, IShow[]>>((returnObject, current) => {
  const year = current.date.split('-')[0];
  if (!returnObject[`year-${year}`]) {
    returnObject[`year-${year}`] = [];
  }
  returnObject[`year-${year}`].push(current);
  return returnObject;
}, {})
