import styled from 'styled-components';
import { min } from '../../../theme/breakpoints';

export const ArtistsContainer = styled.div`
  max-width: 60rem;
  width: 100%;
  margin: auto;
`;

export const AddButton = styled.button`
  margin: 0 auto 1.6rem;
  padding: 1rem;

  svg {
    display: block;
    width: 2.6rem;
    height: 2.6rem;
  }
`;

export const NewArtistForm = styled.form`
  position: relative;
  display: block;
  width: 100%;
  max-width: 30rem;
  margin: 0 auto 1.6rem;
`;

export const NewArtistInput = styled.input`
  &[type="text"] {
    display: block;
    width: 100%;
    padding-right: 5rem;
  }
`;

export const NewArtistSubmit = styled.button`
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  padding: 0;
  width: 3.4rem;
  height: 3.4rem;

  ${min('mobile')} {
    width: 4.8rem;
    height: 4.8rem;
  }
  svg {
    display: block;
    margin: auto;
    width: 2rem;
    height: 2rem;

    ${min('mobile')} {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

export const NewArtistsCards = styled.div`
  max-width: 50rem;
  margin: auto;
`;

export const NewArtistCard = styled.button`
  display: block;
  text-align: left;
  color: ${({ theme }) => theme.colors['Black']};
  background-color: ${({ theme }) => theme.colors['Casper']};
  padding: 1.5rem 2rem 1.5rem 2rem;
  border-radius: 5px;
  margin-bottom: 1.6rem;
  width: 100%;
  position: relative;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.colors['Casper']};

  ${min('tablet')} {
    padding-right: 5.6rem;
  }
  svg,
  &::after {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    transform: translateY(-50%);

    ${min('tablet')} {
      width: 3rem;
      height: 3rem;
    }
  }
  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
  &::after {
    content: '';
    border-radius: 3rem;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors['Black']} inset;
  }
`;

export const NumberResults = styled.div`
  color: ${({ theme }) => `${theme.colors['Casper']} !important;`};
  font-size: 1.4rem;
  margin-bottom: 1.6rem;

  ${min('tablet')} {
    font-size: 1.6rem;
  }
`;

export const NewArtistName = styled.div`
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  font-weight: 700;
  text-transform: uppercase;
`;


export const NewArtistDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.montserrat};
`;

export const AddManuallyContainer = styled.div`
  margin-bottom: 1.6rem;
`;

export const SelectOneArtist = styled.p`
  margin-top: 0;
`;
