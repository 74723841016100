import styled from 'styled-components';
import { min } from '../../../theme/breakpoints';

export const GridWrapper = styled.div`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0;

  ${min('tablet')} {
    margin: 0 auto 3.2rem;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -1.6rem;
  padding: 0;
  
  ${min('desktop')} {
    max-width: 120rem;
  }
  ${min('largeDesktop')} {
    max-width: 150rem;
  }
`;

