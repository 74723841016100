import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const ListTableIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 75 75">
      <g>
        <path
          d="M70.3,4C70.7,4,71,4.3,71,4.7V7v1.2v3.5v1.1v2.3c0,0.4-0.3,0.7-0.7,0.7H4.7c-0.4,0-0.7-0.3-0.7-0.7v-2.3v-1.1V8.2V7V4.7
        C4,4.3,4.3,4,4.7,4H70.3 M70.3,0H4.7C2.1,0,0,2.1,0,4.7V7v1.1v3.6v1.1v2.3c0,2.6,2.1,4.7,4.7,4.7h65.6c2.6,0,4.7-2.1,4.7-4.7v-2.3
        v-1.1V8.2V7V4.7C75,2.1,72.9,0,70.3,0L70.3,0z"
          fill={color || theme.colors['Black']}
        />
        <path
          d="M70.3,59.1c0.4,0,0.7,0.3,0.7,0.7v2.3v1.1v3.6V68v2.3c0,0.4-0.3,0.7-0.7,0.7H4.7C4.3,71,4,70.7,4,70.3V68v-1.1v-3.6v-1.1
        v-2.3c0-0.4,0.3-0.7,0.7-0.7H70.3 M70.3,55.1H4.7c-2.6,0-4.7,2.1-4.7,4.7v2.3v1.1v3.6V68v2.3C0,72.9,2.1,75,4.7,75h65.6
        c2.6,0,4.7-2.1,4.7-4.7V68v-1.1v-3.6v-1.1v-2.3C75,57.2,72.9,55.1,70.3,55.1L70.3,55.1z"
          fill={color || theme.colors['Black']}
        />
        <path
          d="M70.3,31.6c0.4,0,0.7,0.3,0.7,0.7v2.3v1.1v3.6v1.1v2.3c0,0.4-0.3,0.7-0.7,0.7H4.7c-0.4,0-0.7-0.3-0.7-0.7v-2.3v-1.1v-3.6
        v-1.1v-2.3c0-0.4,0.3-0.7,0.7-0.7H70.3 M70.3,27.6H4.7c-2.6,0-4.7,2.1-4.7,4.7v2.3v1.1v3.6v1.1v2.3c0,2.6,2.1,4.7,4.7,4.7h65.6
        c2.6,0,4.7-2.1,4.7-4.7v-2.3v-1.1v-3.6v-1.1v-2.3C75,29.6,72.9,27.6,70.3,27.6L70.3,27.6z"
          fill={color || theme.colors['Black']}
        />
      </g>
    </svg>
  );
};

export default ListTableIcon;
