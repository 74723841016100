export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const defaultSearchValues = {
  artistName: '',
  cityName: '',
  countryCode: null,
  date: null,
  dateOrYear: null,
  venueName: '',
  year: null,
};

export const defaultVenueValues = {
  cityName: '',
  country: null,
  name: '',
};
