import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';

import { SmallModuleContainer, Results, BigNumber } from '../styles';
import moment from 'moment';

const TotalFutureShowsModule = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const futureShows = userShows?.filter(
    (userShow) => userShow.pending && moment(userShow.date).isAfter(moment(), 'day'),
  );

  return (
    <SmallModuleContainer>
      <Results center>
        you have
        <BigNumber>{futureShows?.length || 0}</BigNumber>
        show{futureShows && futureShows.length > 1 && 's'} to come
      </Results>
    </SmallModuleContainer>
  );
};

export default TotalFutureShowsModule;
