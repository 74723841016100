import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const MenuMobileAccount = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 75 75">
      <g>
        <path
          d="M37.5,38.1C46,38.1,53,31.2,53,22.7C53,14.2,46,7.2,37.5,7.2C29,7.2,22,14.2,22,22.7C22,31.2,29,38.1,37.5,38.1z
		 M37.5,11.2c6.3,0,11.5,5.1,11.5,11.5c0,6.3-5.1,11.5-11.5,11.5S26,29,26,22.7C26,16.4,31.2,11.2,37.5,11.2z"
          fill={color || theme.colors['Black']}
        />
        <path
          d="M57.7,44.9C57.7,44.9,57.7,44.9,57.7,44.9l-0.1-0.1c-2.6-2.6-5.9-4-9.6-4H27.2c-3.6,0-6.9,1.4-9.5,4c-2.6,2.6-4,6-4,9.5
		v10.5c0,1.7,1.3,3,3,3h41.7c1.7,0,3-1.3,3-3V54.4C61.3,51,60.1,47.6,57.7,44.9z M27,44.9L48,44.8c2.5,0,4.9,1,6.7,2.8
		c1.7,1.9,2.6,4.2,2.6,6.7v9.5H17.5v-9.4c0-2.5,1-4.9,2.8-6.7C22.2,45.9,24.5,44.9,27,44.9z"
          fill={color || theme.colors['Black']}
        />
      </g>
    </svg>
  );
};

export default MenuMobileAccount;
