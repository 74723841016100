import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IArtist, IShow, IVenue } from '../types/model';
import { TSearchFormFields, TVenueFormFields } from '../types/form';
import { defaultSearchValues, defaultVenueValues } from '../constants';

export type TypeForm = 'create' | 'edit';

export interface FormState {
  type: TypeForm,
  future: boolean;
  step: number;
  artists: {
    requestPerformed: boolean;
    results: IArtist[],
    new: {
      value: string;
      requestPerformed: boolean;
      results: IArtist[];
    }
  };
  search: {
    values: TSearchFormFields;
    requestPerformed: boolean;
    results: IShow[];
    selectedResult: number;
  };
  venue: {
    values: TVenueFormFields;
    requestPerformed: boolean;
    results: IVenue[];
    selectedResult: number;
  };
}

const initialState: FormState = {
  type: 'create',
  future: false,
  step: 0,
  artists: {
    requestPerformed: false,
    results: [],
    new: {
      value: '',
      requestPerformed: false,
      results: [],
    }
  },
  search: {
    values: defaultSearchValues,
    requestPerformed: false,
    results: [],
    selectedResult: -1,
  },
  venue: {
    values: defaultVenueValues,
    requestPerformed: false,
    results: [],
    selectedResult: -1,
  },
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormType: (state, action: PayloadAction<'create' | 'edit'>) => {
      state.type = action.payload;
    },
    setFormFuture: (state, action: PayloadAction<boolean>) => {
      state.future = action.payload;
    },
    setFormStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setArtistsRequestPerformed: (state, action: PayloadAction<boolean>) => {
      state.artists.requestPerformed = action.payload;
    },
    setArtistsResults: (state, action: PayloadAction<IArtist[]>) => {
      state.artists.results = action.payload;
    },
    setNewArtistsResults: (state, action: PayloadAction<IArtist[]>) => {
      state.artists.new.results = action.payload;
    },
    setNewArtistsValue: (state, action: PayloadAction<string>) => {
      state.artists.new.value = action.payload;
    },
    setNewArtistRequestPerformed: (state, action: PayloadAction<boolean>) => {
      state.artists.new.requestPerformed = action.payload;
    },
    setSearchValues: (state, action: PayloadAction<TSearchFormFields>) => {
      state.search.values = action.payload;
    },
    setSearchRequestPerformed: (state, action: PayloadAction<boolean>) => {
      state.search.requestPerformed = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<IShow[]>) => {
      state.search.results = action.payload;
    },
    setSelectedSearchResult: (state, action: PayloadAction<number>) => {
      state.search.selectedResult = action.payload;
    },
    setVenueValues: (state, action: PayloadAction<TVenueFormFields>) => {
      state.venue.values = action.payload;
    },
    setVenueRequestPerformed: (state, action: PayloadAction<boolean>) => {
      state.venue.requestPerformed = action.payload;
    },
    setVenueResults: (state, action: PayloadAction<IVenue[]>) => {
      state.venue.results = action.payload;
    },
    setSelectedVenueResult: (state, action: PayloadAction<number>) => {
      state.venue.selectedResult = action.payload;
    },
    resetAll: (state) => {
      state.type = 'create';
      state.step = 0;
      state.search.requestPerformed = false;
      state.search.results = [];
      state.search.selectedResult = -1;
      state.search.values = defaultSearchValues;
      state.artists.requestPerformed = false;
      state.artists.results = [];
      state.artists.new.requestPerformed = false;
      state.artists.new.results = [];
      state.artists.new.value = '';
      state.venue.requestPerformed = false;
      state.venue.results = [];
      state.venue.values = defaultVenueValues;
      state.venue.selectedResult = -1;
    },
  },
});

export const {
  setFormType,
  setFormFuture,
  setFormStep,
  setArtistsRequestPerformed,
  setArtistsResults,
  setNewArtistsResults,
  setNewArtistsValue,
  setNewArtistRequestPerformed,
  setSearchValues,
  setSearchRequestPerformed,
  setSearchResults,
  setSelectedSearchResult,
  setVenueValues,
  setVenueRequestPerformed,
  setVenueResults,
  setSelectedVenueResult,
  resetAll,
} = formSlice.actions;

export default formSlice.reducer;
