const colors = {
  Black: '#000000',
  Casper: '#BDD0DB',
  GoldTips: '#F2CB40',
  CuriousBlue: '#25CCF7',
  LightGreen: '#84EB89',
  LightRed: '#ff7370',
  MineShaft: '#2f2f2f',
  SilverChalice: '#a3a3a3',
  White: '#ffffff',
  WhiteRock: '#EDEBD7',
  WoodsmokePrimary: '#111112',
  WoodsmokeSecondary: '#18181c',
};

const colorsTmp = ['#89FC00', '#92B4A7'];

export default colors;
