import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';

import { SmallModuleContainer, Results, BigNumber } from '../styles';
import moment from 'moment';

const TotalFutureArtistsModule = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const futureShows = userShows?.filter(
    (userShow) => userShow.pending && moment(userShow.date).isAfter(moment(), 'day'),
  );
  const artistsUnique: string[] = [];
  futureShows?.forEach((userShow) => {
    userShow.artists.forEach((artist) => {
      if (artist.name && !artistsUnique.includes(artist.name)) {
        artistsUnique.push(artist.name);
      }
    });
  });
  const nbArtists = artistsUnique.length;

  return (
    <SmallModuleContainer>
      <Results center>
        you'll see
        <BigNumber>{nbArtists || 0}</BigNumber>
        artist{nbArtists > 1 && 's'} in upcoming shows
      </Results>
    </SmallModuleContainer>
  );
};

export default TotalFutureArtistsModule;
