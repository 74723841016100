import { useTheme } from 'styled-components';
import { IIcon, SortOptions, Theme } from '../../../types';

interface ISortIcon extends IIcon {
  sort: SortOptions;
}

const SortDateOutlineIcon = ({ sort, color }: ISortIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 131 75">
      <g>
        <path
          d="M61,4c0.7,0,1.4,0.7,1.4,1.4v8.8c0,0.7-0.7,1.4-1.4,1.4c-0.7,0-1.4-0.7-1.4-1.4V5.4C59.7,4.7,60.3,4,61,4 M17.1,4
		c0.7,0,1.4,0.7,1.4,1.4v8.8c0,0.7-0.7,1.4-1.4,1.4c-0.7,0-1.4-0.7-1.4-1.4V5.4C15.7,4.7,16.4,4,17.1,4 M48.7,12.8v2.3
		c0,6.8,5.5,12.3,12.3,12.3c6.8,0,12.3-5.5,12.3-12.3v-2.3c0.5,0,0.8,0.4,0.8,0.9v48.1l-9.4,9.4H4.9c-0.5,0-0.9-0.4-0.9-0.9V13.7
		c0-0.5,0.3-0.8,0.8-0.9v2.3c0,6.8,5.5,12.3,12.3,12.3c6.8,0,12.3-5.5,12.3-12.3v-2.3H48.7 M61,0c-2.9,0-5.4,2.4-5.4,5.4v8.8
		c0,2.9,2.4,5.4,5.4,5.4s5.4-2.4,5.4-5.4V5.4C66.4,2.4,64,0,61,0L61,0z M17.1,0c-2.9,0-5.4,2.4-5.4,5.4v8.8c0,2.9,2.4,5.4,5.4,5.4
		c2.9,0,5.4-2.4,5.4-5.4V5.4C22.5,2.4,20,0,17.1,0L17.1,0z M73.2,8.8h-3.9v6.3c0,4.6-3.7,8.3-8.3,8.3c-4.6,0-8.3-3.7-8.3-8.3V8.8
		H25.4v6.3c0,4.6-3.7,8.3-8.3,8.3c-4.6,0-8.3-3.7-8.3-8.3V8.8H4.9C2.1,8.8,0,10.9,0,13.7v56.6c0,2.7,2.1,4.9,4.9,4.9h61.5l11.7-11.7
		V13.7C78.1,10.9,76,8.8,73.2,8.8L73.2,8.8z"
          fill={color || theme.colors['Black']}
        />
        {sort === 'asc' ? (
          <path
            d="M107.1,9.2c0.9,0,1.8,0.3,2.3,1l16.8,20c1.9,2.2-0.2,5.1-2.5,5.1c-0.8,0-1.6-0.3-2.3-1.2L110,20.7V61c0,2.1-1.5,3.1-3,3.1
    s-3-1-3-3.1V20.7L92.6,34.1c-0.7,0.8-1.5,1.2-2.2,1.2c-2.3,0-4.3-2.8-2.2-5.1l16.6-20C105.3,9.5,106.2,9.2,107.1,9.2 M107.1,5.2
    c-2,0-3.9,0.8-5.3,2.1l-0.1,0.1l-0.1,0.1L85.1,27.5c-1.9,2.1-2.4,4.9-1.3,7.4c1.1,2.6,3.8,4.4,6.5,4.4c2.1,0,4-0.9,5.3-2.6l4.3-5.1
    V61c0,4,3,7.1,7,7.1c4,0,7-3,7-7.1V31.5l4.4,5.1c1.4,1.7,3.3,2.6,5.4,2.6c2.7,0,5.4-1.7,6.6-4.3c1.2-2.5,0.8-5.3-1-7.4l-16.8-20
    C111.2,6.1,109.3,5.2,107.1,5.2L107.1,5.2z"
            fill={theme.colors['GoldTips']}
          />
        ) : (
          <path
            d="M107.1,64.1c-0.9,0-1.8-0.3-2.3-1L88,43.1c-1.9-2.2,0.2-5.1,2.5-5.1c0.8,0,1.6,0.3,2.3,1.2l11.4,13.4V12.3
          c0-2.1,1.5-3.1,3-3.1s3,1,3,3.1v40.3l11.4-13.4c0.7-0.8,1.5-1.2,2.2-1.2c2.3,0,4.3,2.8,2.2,5.1l-16.6,20
          C108.9,63.8,108,64.1,107.1,64.1 M107.1,68.1c2,0,3.9-0.8,5.3-2.1l0.1-0.1l0.1-0.1l16.5-19.9c1.9-2.1,2.4-4.9,1.3-7.4
          c-1.1-2.6-3.8-4.4-6.5-4.4c-2.1,0-4,0.9-5.3,2.6l-4.3,5.1V12.3c0-4-3-7.1-7-7.1c-4,0-7,3-7,7.1v29.5l-4.4-5.1
          c-1.4-1.7-3.3-2.6-5.4-2.6c-2.7,0-5.4,1.7-6.6,4.3c-1.2,2.5-0.8,5.3,1,7.4l16.8,20C103,67.2,104.9,68.1,107.1,68.1L107.1,68.1z"
            fill={theme.colors['GoldTips']}
          />
        )}
      </g>
    </svg>
  );
};

export default SortDateOutlineIcon;
