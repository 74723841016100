import { Dispatch, SetStateAction } from 'react';
import { shallowEqual } from 'react-redux';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../../store';
import { setSelectedVenueResult, setVenueResults } from '../../../../store/formSlice';
import { IVenue } from '../../../../types';

import { CheckIcon } from '../../../Elements/Icons';

import { GreenButton } from '../../../Elements/Buttons/styles';
import { VenueCards, NumberResults, VenueCardContainer, VenueCard, VenueName } from './styles';

interface IVenueResultsProps {
  goToNextStep: () => void;
  setError: Dispatch<SetStateAction<string | null>>;
}

const VenueResults = ({ goToNextStep, setError }: IVenueResultsProps) => {
  const dispatch = useAppDispatch() as AppDispatch;
  const { venueRequestPerformed, venueResults, venueSelectedResult, venueValues } = useAppSelector(
    (state) => ({
      venueRequestPerformed: state.form.venue.requestPerformed,
      venueResults: state.form.venue.results,
      venueSelectedResult: state.form.venue.selectedResult,
      venueValues: state.form.venue.values,
    }),
    shallowEqual,
  );

  const selectVenue = (event: React.FormEvent<HTMLInputElement>) => {
    dispatch(setSelectedVenueResult(parseInt(event.currentTarget.value)));
  };

  const addManually = () => {
    if (!venueValues.country || !venueValues.name || !venueValues.cityName) {
      setError('You have to provide all 3 fields to add this venue.');
      return;
    }
    const newVenue = {
      name: venueValues.name,
      cityName: venueValues.cityName,
      countryCode: venueValues.country.value as string,
      countryName: venueValues.country.label as string,
      source: 'setlist.fm',
      id: 0,
      setlistFmId: '',
    };
    dispatch(setVenueResults([newVenue]));
    dispatch(setSelectedVenueResult(0));
    goToNextStep();
  };

  return (
    <VenueCards>
      <NumberResults>
        {venueResults.length || 'No'} result
        {venueResults.length > 1 && 's'}
      </NumberResults>
      {venueResults.length > 0 && <p>Pick one or try another request if you don't find what you're looking for.</p>}
      {venueResults.map((venueResult: IVenue, index: number) => (
        <VenueCardContainer key={`venue_result_${index}`}>
          <input
            type="radio"
            name="venueResult"
            value={index}
            checked={index === venueSelectedResult}
            onChange={selectVenue}
            id={`venue_result_${index}`}
          />
          <VenueCard htmlFor={`venue_result_${index}`}>
            <VenueName>{venueResult.name}</VenueName>
            <div>
              {venueResult.cityName} ({venueResult.state}, {venueResult.countryCode})
            </div>
            {index === venueSelectedResult && <CheckIcon />}
          </VenueCard>
        </VenueCardContainer>
      ))}
      {venueResults.length === 0 && venueRequestPerformed ? (
        <p>If you're absolutely certain about the venue you filled, you can add it manually.</p>
      ) : (
        <p>
          If you don't find what you're looking for but are certain about the information you gave, you can add it
          manually.
        </p>
      )}
      <GreenButton onClick={addManually}>Add manually</GreenButton>
    </VenueCards>
  );
};

export default VenueResults;
