import styled, { css } from 'styled-components';
import { min } from '../../theme/breakpoints';

interface IResultsProps {
  center?: boolean;
}

export const Results = styled.ul<IResultsProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => center ? 'center' : 'space-between'};
  justify-content: ${({ center }) => center ? 'center' : 'flex-start'};
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  flex: 1;

  ${min('tablet')} {
    &:hover {
      background-color: ${({ theme }) => theme.colors['WoodsmokeSecondary']};
    }
  }
`;

export const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1.6rem;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
  padding: 1.6rem 1.6rem 2rem;
  width: 100%;
  max-width: calc(60rem + 3.2rem);

  ${min('desktop')} {
    max-width: calc(90rem + 6.4rem);
  }
  ${min('tablet')} {
    min-width: 30rem;
  }
`;

export const FixedModuleContainer = styled(ModuleContainer)`
  width: 30rem;
`;

export const SmallModuleContainer = styled(ModuleContainer)`
  padding: 1.6rem;
  width: calc(50% - 1.6rem);
  font-size: 1.2rem;
  max-width: 30rem;

  ${min('mobile')} {
    font-size: 1.6rem;
  }
  @media screen and (min-width: 1360px) {
    width: calc(20% - 2.6rem);
  }
  ${Results} {
    text-align: center;
  }
`;

export const Info = styled.div`
  margin-right: auto;
  padding-right: 1rem;
`;

export const MainInfo = styled.div`
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors['Casper']};
`;

export const SecondaryInfo = styled.div`
  color: ${({ theme }) => theme.colors['White']};
  font-size: 1.rem;

  ${min('mobile')} {
    font-size: 1.4rem;
  }
`;

export const Count = styled.div`
  color: ${({ theme }) => theme.colors['GoldTips']};
`;

export const BigNumber = styled(Count)`
  font-family: ${({ theme }) => theme.fonts.libreBaskerville};
  font-size: 3rem;

  ${min('tablet')} {
    font-size: 4rem;
  }
  ${min('desktop')} {
    font-size: 5rem;
  }
`;

export const MediumNumber = styled(Count)`
  font-family: ${({ theme }) => theme.fonts.libreBaskerville};
  font-size: 2rem;

  ${min('tablet')} {
    font-size: 2.4rem;
  }
  ${min('desktop')} {
    font-size: 3.2rem;
  }
`;
