import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store';
import {
  setArtistsResults,
  setArtistsRequestPerformed,
  setNewArtistsResults,
  setNewArtistsValue,
  setNewArtistRequestPerformed,
} from '../../../store/formSlice';
import Api from '../../../helpers/Api';
import { IArtist } from '../../../types';

import FormArtistList from './List';
import Loader from '../../Elements/Loader';
import { PlusIcon, SearchIcon } from '../../Elements/Icons';

import { GreenButton, GreyButton } from '../../Elements/Buttons/styles';
import {
  ArtistsContainer,
  AddButton,
  NewArtistForm,
  NewArtistInput,
  NewArtistSubmit,
  NewArtistsCards,
  NewArtistCard,
  NumberResults,
  NewArtistName,
  NewArtistDescription,
  AddManuallyContainer,
  SelectOneArtist,
} from './styles';

interface IArtistsFormProps {
  goToPreviousStep: () => void;
  goToNextStep: () => void;
}

const ArtistsForm = ({ goToPreviousStep, goToNextStep }: IArtistsFormProps) => {
  const dispatch = useAppDispatch() as AppDispatch;
  const {
    artists,
    artistsRequestPerformed,
    futureShow,
    newArtistResults,
    newArtistValue,
    newArtistRequestPerformed,
    searchResults,
    searchValues,
    selectedSearchResult,
    typeForm,
  } = useAppSelector(
    (state) => ({
      artists: state.form.artists.results,
      artistsRequestPerformed: state.form.artists.requestPerformed,
      futureShow: state.form.future,
      newArtistResults: state.form.artists.new.results,
      newArtistValue: state.form.artists.new.value,
      newArtistRequestPerformed: state.form.artists.new.requestPerformed,
      searchResults: state.form.search.results,
      searchValues: state.form.search.values,
      selectedSearchResult: state.form.search.selectedResult,
      typeForm: state.form.type,
    }),
    shallowEqual,
  );

  const [loading, setLoading] = useState(false);
  const [addNewArtistForm, setAddNewArtistForm] = useState(futureShow ? true : false);

  useEffect(() => {
    if (searchValues.artistName && selectedSearchResult < 0 && !artists.length) {
      setAddNewArtistForm(true);
      dispatch(setNewArtistsValue(searchValues.artistName));
    }
  }, [searchValues, searchResults, selectedSearchResult, dispatch, artists]);

  useEffect(() => {
    if (artistsRequestPerformed && !artists.length && typeForm === 'create') {
      goToPreviousStep();
    }

    const fetchOtherArtists = async () => {
      setLoading(true);

      const { date, venue } = searchResults[selectedSearchResult];
      const { setlistFmId } = venue;

      Api.request<IArtist[]>('/artists', {
        venueId: setlistFmId,
        date,
      }).then((response) => {
        dispatch(setArtistsRequestPerformed(true));
        if (response?.length) {
          let artistsToStore = [];
          if (typeForm === 'create') {
            artistsToStore = response.map((artist) => ({
              ...artist,
              seen: true,
            }));
          } else {
            const filteredArtistsFromRequest = response
              .filter((artistFromRequest) => !artists.find((artist) => artist.id === artistFromRequest.id))
              .map((artist) => ({
                ...artist,
                seen: false,
              }));
            artistsToStore = [...artists, ...filteredArtistsFromRequest];
          }

          dispatch(setArtistsResults(artistsToStore));
        }
        setLoading(false);
      });
    };

    if (searchResults.length && selectedSearchResult > -1 && !artistsRequestPerformed) {
      if (typeForm === 'create') {
        const { source } = searchResults[selectedSearchResult];

        if (source === 'setlist.fm') {
          fetchOtherArtists();
        }
        if (source === 'api' && !artistsRequestPerformed) {
          dispatch(setArtistsRequestPerformed(true));
          dispatch(setArtistsResults(searchResults[selectedSearchResult].artists));
        }
      } else if (typeForm === 'edit' && artists.length) {
        fetchOtherArtists();
      }
    }
  }, [searchResults, selectedSearchResult, artistsRequestPerformed, goToPreviousStep, artists, dispatch, typeForm]);

  const searchForNewArtist = async (e: React.FormEvent) => {
    e.preventDefault();
    const artistName = newArtistValue;
    if (newArtistValue) {
      setLoading(true);
      dispatch(setNewArtistRequestPerformed(false));
      dispatch(setNewArtistsResults([]));
      Api.request<IArtist[]>('/artists', {
        artistName,
      }).then((response) => {
        dispatch(setNewArtistRequestPerformed(true));
        if (response?.length) {
          dispatch(setNewArtistsResults(response));
        }
        setLoading(false);
      });
    }
  };

  const addArtistToList = (artist: IArtist) => {
    const newArtist = { ...artist };
    newArtist.seen = true;
    const listResultsUpdated = [...artists, newArtist];
    dispatch(setArtistsResults(listResultsUpdated));
    dispatch(setNewArtistRequestPerformed(false));
    dispatch(setNewArtistsResults([]));
    dispatch(setNewArtistsValue(''));
    setAddNewArtistForm(false);
  };

  const addNewArtistManually = () => {
    const newArtist: IArtist = {
      mbid: (artists.length + 1).toString(),
      name: newArtistValue,
      seen: true,
    };
    const newArtistsResults = [...artists, newArtist];
    dispatch(setArtistsResults(newArtistsResults));
    dispatch(setNewArtistRequestPerformed(false));
    dispatch(setNewArtistsResults([]));
    dispatch(setNewArtistsValue(''));
    setAddNewArtistForm(false);
  };

  const nbSeenArtists = artists.filter((artist) => artist.seen).length;

  return (
    <div>
      {loading && <Loader />}
      <h3>{(searchResults.length && selectedSearchResult > -1) || typeForm === 'edit' ? 'Select' : 'Add'} artists</h3>
      {searchResults.length && selectedSearchResult > -1 ? (
        <p>You can now select the artists you saw for this event. If some are missing, add them to the list.</p>
      ) : selectedSearchResult < 0 ? (
        <p>First, let's add the artists you saw at this show.</p>
      ) : null}
      <p>You can also reorder them (descending: from the headliner to the first band to perform).</p>
      <ArtistsContainer>
        <FormArtistList />
        {!addNewArtistForm && !newArtistResults.length && (
          <AddButton onClick={() => setAddNewArtistForm(true)}>{<PlusIcon />}</AddButton>
        )}
        {(addNewArtistForm || newArtistResults.length > 0) && (
          <NewArtistForm onSubmit={searchForNewArtist}>
            <NewArtistInput
              type="text"
              name="newArtist"
              placeholder="Artist name"
              value={newArtistValue}
              onInput={(e) => dispatch(setNewArtistsValue(e.currentTarget.value))}
            />
            <NewArtistSubmit type="submit">
              <SearchIcon />
            </NewArtistSubmit>
          </NewArtistForm>
        )}
      </ArtistsContainer>
      {newArtistRequestPerformed && (
        <>
          <NumberResults>
            {newArtistResults.length || 'No'} result
            {newArtistResults.length > 1 && 's'}
          </NumberResults>
          {newArtistResults.length === 0 && (
            <AddManuallyContainer>
              <p>Adapt your request or if you're certain of the name of the artist, you can add it manually.</p>
              <GreenButton onClick={addNewArtistManually}>Add manually</GreenButton>
            </AddManuallyContainer>
          )}
          <NewArtistsCards>
            {newArtistResults.map((artist) => (
              <NewArtistCard
                key={`newArtist_${artist.setlistFmId || artist.mbid || artist.id}`}
                onClick={() => addArtistToList(artist)}
              >
                <NewArtistName>{artist.name}</NewArtistName>
                <NewArtistDescription>{artist.description}</NewArtistDescription>
              </NewArtistCard>
            ))}
          </NewArtistsCards>
          {newArtistResults.length > 0 && (
            <AddManuallyContainer>
              <p>Or if you're certain of the name of the artist, you can add it manually.</p>
              <GreenButton onClick={addNewArtistManually}>Add manually</GreenButton>
            </AddManuallyContainer>
          )}
        </>
      )}
      {nbSeenArtists <= 0 && artists.length > 0 && (
        <SelectOneArtist>You have to select at least one artist to continue.</SelectOneArtist>
      )}
      {typeForm === 'create' && (
        <>
          <GreyButton onClick={goToPreviousStep}>Back</GreyButton>&nbsp;
        </>
      )}
      {nbSeenArtists > 0 && <GreenButton onClick={goToNextStep}>Next</GreenButton>}
    </div>
  );
};

export default ArtistsForm;
