import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const SeenIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 17 17">
      <path
        d="M16.7,0.3c-0.4-0.4-1-0.4-1.4-0.1L6,8.6L1.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l5,5C5.5,10.9,5.7,11,6,11
	c0.2,0,0.5-0.1,0.7-0.3l10-9C17.1,1.4,17.1,0.7,16.7,0.3z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M15.3,6.3L6,14.6l-4.3-4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l5,5C5.5,16.9,5.7,17,6,17c0.2,0,0.5-0.1,0.7-0.3l10-9
	c0.4-0.4,0.4-1,0.1-1.4C16.4,5.9,15.7,5.9,15.3,6.3z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default SeenIcon;
