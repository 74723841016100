import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const MenuMobileList = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 75 75">
      <g>
        <path
          d="M62.5,11.5h-50c-1.7,0-3,1.3-3,3v6c0,1.7,1.3,3,3,3h50c1.7,0,3-1.3,3-3v-6C65.5,12.8,64.2,11.5,62.5,11.5z M61.5,15.5v4
		h-48v-4H61.5z"
          fill={color || theme.colors['Black']}
        />
        <path
          d="M62.5,31.3h-50c-1.7,0-3,1.3-3,3v6c0,1.7,1.3,3,3,3h50c1.7,0,3-1.3,3-3v-6C65.5,32.6,64.2,31.3,62.5,31.3z M61.5,35.3v4
		h-48v-4H61.5z"
          fill={color || theme.colors['Black']}
        />
        <path
          d="M62.5,51.5h-50c-1.7,0-3,1.3-3,3v6c0,1.7,1.3,3,3,3h50c1.7,0,3-1.3,3-3v-6C65.5,52.8,64.2,51.5,62.5,51.5z M61.5,55.5v4
		h-48v-4H61.5z"
          fill={color || theme.colors['Black']}
        />
      </g>
    </svg>
  );
};

export default MenuMobileList;
