import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const CheckIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 57.4 57.4">
      <path
        d="M28.7,0C12.8,0,0,12.8,0,28.7s12.8,28.7,28.7,28.7s28.7-12.8,28.7-28.7l0,0C57.4,12.9,44.5,0,28.7,0z M43.3,19.2L27.9,39.8
    c-0.3,0.4-0.7,0.6-1.1,0.6h-0.1c-0.4,0-0.8-0.2-1.1-0.4l-9.9-10.2c-0.6-0.6-0.5-1.6,0.1-2.1c0.6-0.5,1.5-0.5,2.1,0l8.7,9l14.3-19.2
    c0.5-0.6,1.5-0.7,2.1-0.2C43.6,17.7,43.7,18.5,43.3,19.2z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default CheckIcon;
