import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store';

import Loader from '../../Elements/Loader';
import VenueForm from './Form';
import VenueResults from './Results';

import { ErrorMessage } from '../Search/styles';
import { GreenButton, GreyButton } from '../../Elements/Buttons/styles';
import { VenueFormContainer, Actions } from './styles';

interface IVenueFormProps {
  goToPreviousStep: () => void;
  goToNextStep: () => void;
}

const CreateVenue = ({ goToPreviousStep, goToNextStep }: IVenueFormProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { typeForm, venueRequestPerformed, venueSelectedResult, searchFormResults, searchFormSelectedResult } =
    useAppSelector(
      (state) => ({
        typeForm: state.form.type,
        venueRequestPerformed: state.form.venue.requestPerformed,
        venueSelectedResult: state.form.venue.selectedResult,
        searchFormResults: state.form.search.results,
        searchFormSelectedResult: state.form.search.selectedResult,
      }),
      shallowEqual,
    );

  useEffect(() => {
    if (searchFormSelectedResult > -1 && typeForm !== 'edit') {
      goToNextStep();
    }
  }, [searchFormResults, searchFormSelectedResult, goToNextStep, typeForm]);

  return (
    <VenueFormContainer>
      {loading && <Loader />}
      <h3>{typeForm === 'edit' ? 'Edit' : 'Register'} venue</h3>
      <p>All fields are required</p>
      <VenueForm loading={loading} setLoading={setLoading} setError={setError} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {venueRequestPerformed && <VenueResults goToNextStep={goToNextStep} setError={setError} />}
      <Actions>
        <GreyButton onClick={goToPreviousStep}>Back</GreyButton>
        {venueSelectedResult >= 0 && (
          <>
            &nbsp;<GreenButton onClick={goToNextStep}>Next</GreenButton>
          </>
        )}
      </Actions>
    </VenueFormContainer>
  );
};

export default CreateVenue;
