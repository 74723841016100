import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';

import { SmallModuleContainer, Results, BigNumber } from '../styles';

const TotalSeatedShows = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const seatedShows: {
    seated: number;
    not_seated: number;
    unknown: number;
  } = {
    seated: 0,
    not_seated: 0,
    unknown: 0,
  };
  userShows?.forEach((userShow) => {
    if (!userShow.pending) {
      if (userShow.seated) {
        seatedShows.seated++;
      } else {
        seatedShows.not_seated++;
      }
    }
  });

  return (
    <>
      <SmallModuleContainer>
        <Results center>
          you saw
          <BigNumber>{seatedShows.seated}</BigNumber>
          shows seated
        </Results>
      </SmallModuleContainer>
      <SmallModuleContainer>
        <Results center>
          and
          <BigNumber>{seatedShows.not_seated}</BigNumber>
          shows standing
        </Results>
      </SmallModuleContainer>
    </>
  );
};

export default TotalSeatedShows;
