import styled from 'styled-components';
import { min } from '../../../theme/breakpoints';

export const CommentTextarea = styled.textarea``;

export const DatePickerContainer = styled.div`
  margin-bottom: 1.6rem;
`;

export const Actions = styled.div`
  margin-top: 1.6rem;
`;

export const CheckboxContainer = styled.div`
  display: inline-flex;
  position: relative;
  margin-top: 1.6rem;
  border-radius: 5rem;
`;

interface ICheckboxChoice {
  selected: boolean;
}

export const CheckboxChoice = styled.span<ICheckboxChoice>`
  background-color: ${({ selected, theme }) => selected ? theme.colors['GoldTips'] : 'transparent'};
  color: ${({ selected, theme }) => selected ? theme.colors['Black'] : theme.colors['White']};
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.8rem;
  appearance: none;
  border: 0;
  padding: 1rem 1.6rem;
  border-radius: 5rem;

  &:disabled {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.3);
    background-color: ${({ theme }) => theme.colors['MineShaft']};
  }
  ${min('tablet')} {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export const SeatedContainer = styled.label`
  display: block;
  margin: 1.6rem 0;

  input {
    position: absolute;
    opacity: 0;

    &:focus-visible {
      + ${CheckboxContainer} {
        outline: 2px solid ${({ theme }) => theme.colors['GoldTips']};
        outline-offset: 2px;

        ${CheckboxChoice} {
          color: ${({ theme }) => theme.colors['White']};
        }
      }
    }
  }
`;

export const SeatedCheckbox = styled.input``;

export const PriceInput = styled.input``;
