import { shallowEqual } from 'react-redux';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../../store';
import {
  setArtistsRequestPerformed,
  setArtistsResults,
  setSelectedSearchResult,
  setVenueRequestPerformed,
  setVenueResults,
} from '../../../../store/formSlice';
import { months } from '../../../../constants';
import { IShow } from '../../../../types';

import { CheckIcon, ConfirmedIcon } from '../../../Elements/Icons';
import { GreenButton } from '../../../Elements/Buttons/styles';
import {
  ArtistName,
  DateContainer,
  Day,
  Month,
  NumberResults,
  ShowCard,
  ShowCardContainer,
  ShowCards,
  ShowInfo,
  VenueInfo,
  Year,
} from './styles';

interface ISearchResultsProps {
  goToNextStep: () => void;
}

const SearchResults = ({ goToNextStep }: ISearchResultsProps) => {
  const dispatch = useAppDispatch() as AppDispatch;
  const { searchRequestPerformed, searchSelectedResult, searchResults, userShows } = useAppSelector(
    (state) => ({
      searchRequestPerformed: state.form.search.requestPerformed,
      searchResults: state.form.search.results,
      searchSelectedResult: state.form.search.selectedResult,
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const handleSelectResult = (event: React.FormEvent<HTMLInputElement>) => {
    // Reset artists
    dispatch(setArtistsRequestPerformed(false));
    dispatch(setArtistsResults([]));
    dispatch(setSelectedSearchResult(parseInt(event.currentTarget.value)));
    // Reset venue
    dispatch(setVenueRequestPerformed(false));
    dispatch(setVenueResults([]));
  };

  const addManually = () => {
    if (searchSelectedResult > -1) {
      dispatch(setSelectedSearchResult(-1));
      dispatch(setArtistsRequestPerformed(false));
      dispatch(setArtistsResults([]));
    }
    goToNextStep();
  };

  return searchRequestPerformed ? (
    <ShowCards>
      <NumberResults>
        {searchResults.length || 'No'} result
        {searchResults.length > 1 && 's'}
      </NumberResults>
      {searchResults.length === 0 && (
        <>
          <p>
            Adapt your request and try again.
            <br />
            If you are absolutely certain about your search, you can add it manually.
          </p>
          <GreenButton onClick={addManually}>Add manually</GreenButton>
        </>
      )}
      {searchResults.length > 0 && (
        <>
          <p>Pick one or try another request if you don't find what you're looking for.</p>
          {searchResults.map((searchResult: IShow, index: number) => {
            const { artists, date, venue } = searchResult;
            const [year, month, day] = date.split('-');
            const showFoundInUserList = userShows?.length
              ? !!userShows.find(
                  (userShow) =>
                    userShow.date === searchResult.date &&
                    userShow.artists.find((artist) =>
                      searchResult.artists.find(
                        (artistFromSearch) => artistFromSearch.setlistFmId === artist.setlistFmId,
                      ),
                    ),
                )
              : false;
            return (
              <ShowCardContainer key={`search_result_${index}`}>
                {!showFoundInUserList && (
                  <input
                    type="radio"
                    name="searchResult"
                    value={index}
                    checked={index === searchSelectedResult}
                    onChange={handleSelectResult}
                    id={`search_result_${index}`}
                  />
                )}
                <ShowCard inList={showFoundInUserList} htmlFor={`search_result_${index}`}>
                  <DateContainer>
                    <Day>{day}</Day>
                    <Month>{months[parseInt(month) - 1].substring(0, 3)}</Month>
                    <Year>{year}</Year>
                  </DateContainer>
                  <ShowInfo>
                    <ArtistName>
                      {artists.map(
                        (artist, index: number) => `${artist.name}${index < artists.length - 1 ? ' + ' : ''}`,
                      )}
                    </ArtistName>
                    <VenueInfo>
                      {venue.name} ({venue.cityName}, {venue.countryCode})
                    </VenueInfo>
                  </ShowInfo>
                  {showFoundInUserList ? (
                    <ConfirmedIcon />
                  ) : !showFoundInUserList && index === searchSelectedResult ? (
                    <CheckIcon />
                  ) : null}
                </ShowCard>
                {showFoundInUserList && <p>The show above is already in your list.</p>}
              </ShowCardContainer>
            );
          })}
          {searchSelectedResult < 0 && (
            <>
              <p>If you don't find what you're looking for in the results, you can add it manually.</p>
              <GreenButton onClick={addManually}>Add manually</GreenButton>
            </>
          )}
        </>
      )}
    </ShowCards>
  ) : null;
};

export default SearchResults;
