import styled from 'styled-components';
import { min } from '../../../theme/breakpoints';

interface ISearchFormFullscreenProps {
  visible: boolean;
  withMenu: boolean;
  zIndex: number;
}

export const Wrapper = styled.div<ISearchFormFullscreenProps>`
  padding: 0;
  height: ${({ withMenu }) => withMenu ? 'calc(100% - 7.4rem + env(safe-area-inset-bottom))' : '100%'};
  overflow-y: auto;
  opacity: ${({ visible }) => visible ? '1' : '0'};
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};

  ${min('tablet')} {
    height: 100%;
  }
  * {
    transition: .2s opacity ease-in-out;
    opacity: ${({ visible }) => visible ? '1' : '0'};
    visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  padding: 1.5rem 1.5rem;
  z-index: 10;

  ${min('tablet')} {
    top: 3.2rem;
    right: 3.2rem;
  }
  svg {
    display: block;
    width: 1.6rem;
    height: 1.6rem;

    ${min('tablet')} {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors['WoodsmokePrimary']};
  overflow: auto;

  h3 {
    margin-top: 0;
  }
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.colors['WoodsmokePrimary']};
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.4rem;
  position: relative;

  ${min('mobile')} {
    padding: 3.2rem;
  }
`;
