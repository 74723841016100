import { shallowEqual } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { resetAll } from '../../../store/formSlice';

import { NavbarMobileContainer } from './styles';
import {
  MenuMobileAccountIcon,
  MenuMobileAddIcon,
  MenuMobileStatsIcon,
  MenuMobileListIcon,
} from '../../Elements/Icons';
import { useTheme } from 'styled-components';
import { Theme } from '../../../types';

interface INavbarMobileProps {
  addFormVisible: boolean;
  setFormVisible?: (value: boolean) => void;
}

const NavbarMobile = ({ addFormVisible, setFormVisible }: INavbarMobileProps) => {
  const { typeForm } = useAppSelector(
    (state) => ({
      typeForm: state.form.type,
    }),
    shallowEqual,
  );
  const theme = useTheme() as Theme;
  const dispatch = useAppDispatch();
  const location = useLocation();

  const openFormCreateShow = () => {
    if (setFormVisible) {
      if (typeForm === 'edit') {
        dispatch(resetAll());
      }
      setFormVisible(true);
    }
  };

  return (
    <NavbarMobileContainer>
      <Link to={'/'} className={location.pathname === '/' && !addFormVisible ? ' selected' : ''}>
        <MenuMobileListIcon
          color={location.pathname === '/' && !addFormVisible ? theme.colors['GoldTips'] : theme.colors['White']}
        />
        List
      </Link>
      <Link to={'/stats'} className={location.pathname === '/stats' && !addFormVisible ? ' selected' : ''}>
        <MenuMobileStatsIcon
          color={location.pathname === '/stats' && !addFormVisible ? theme.colors['GoldTips'] : theme.colors['White']}
        />
        Stats
      </Link>
      <Link to={'/account'} className={location.pathname === '/account' && !addFormVisible ? ' selected' : ''}>
        <MenuMobileAccountIcon
          color={location.pathname === '/account' && !addFormVisible ? theme.colors['GoldTips'] : theme.colors['White']}
        />
        Account
      </Link>
      <Link to="" className={addFormVisible ? ' selected' : ''} onClick={() => openFormCreateShow()}>
        <MenuMobileAddIcon color={addFormVisible ? theme.colors['GoldTips'] : theme.colors['White']} />
        Add show
      </Link>
    </NavbarMobileContainer>
  );
};

export default NavbarMobile;
