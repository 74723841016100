import styled from 'styled-components';

export const IconContainer = styled.div`
  width: 20rem;
  margin: 3.2rem auto 0;
`;

export const NumberResult = styled.p`
  font-size: 2rem;
`;

export const Number = styled.span`
  font-family: ${({ theme }) => theme.fonts.libreBaskerville};
  color: ${({ theme }) => theme.colors['GoldTips']};
`;

export const NoResult = styled.div`
  margin: 3.2rem;

  svg {
    max-width: 10rem;

    path:not(:last-child) {
      fill: ${({ theme }) => theme.colors['Casper']};
    }
    circle,
    path:last-child {
      fill: ${({ theme }) => theme.colors['GoldTips']};
    }
  }
`;
