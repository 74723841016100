import styled from 'styled-components';
import { min } from '../../../../theme/breakpoints';

export const ShowCards = styled.div`
  margin: 3.2rem auto;
  width: 100%;
  max-width: 75rem;
`;

export const NumberResults = styled.div`
  color: ${({ theme }) => `${theme.colors['Casper']} !important;`};
  font-size: 1.4rem;

  ${min('tablet')} {
    font-size: 1.6rem;
  }
`;

export const ShowCardContainer = styled.div`
  width: 100%;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
  }
`;

interface ICardProps {
  inList?: boolean;
}

export const ShowCard = styled.label<ICardProps>`
  display: flex;
  align-items: center;
  text-align: left;
  background-color: ${({ theme }) => theme.colors['Casper']};
  padding: 5px 5rem 5px 5px;
  border-radius: 5px;
  margin-top: 1.6rem;
  width: 100%;
  position: relative;
  cursor: ${({ inList }) => inList ? 'initial' : 'pointer'};
  border: 2px solid ${({ theme }) => theme.colors['Casper']};

  ${min('tablet')} {
    padding-right: 5.6rem;
  }
  svg,
  &::after {
    position: absolute;
    top: 50%;
    right: ${({ inList }) => inList ? '1.5rem' : '1rem'};
    width: ${({ inList }) => inList ? '2rem' : '3rem'};
    height: ${({ inList }) => inList ? '2rem' : '3rem'};
    transform: translateY(-50%);

    ${min('tablet')} {
      width: ${({ inList }) => inList ? '3rem' : '4rem'};
      height: ${({ inList }) => inList ? '3rem' : '4rem'};
    }
  }
  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
  &::after {
    display: ${({ inList }) => inList ? 'none' : 'block'};
    content: '';
    border-radius: 4rem;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors['Black']} inset;
  }
`;

export const DateContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors['Black']};
  color: #EDEBD7;
  border-radius: 3px;
  padding: 0.5rem;
  margin-right: 1rem;
  text-align: center;
  min-width: 6.5rem;

  ${min('tablet')} {
    padding: 1rem;
  }
`;

export const Year = styled.span`
  font-size: 1.2rem;
  line-height: 1;

  ${min('tablet')} {
    font-size: 1.5rem;
  }
`;

export const Month = styled.span`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;

  ${min('tablet')} {
    font-size: 2.2rem;
  }
`;

export const Day = styled.span`
  font-family: ${({ theme }) => theme.fonts.libreBaskerville};
  font-size: 2.4rem;
  line-height: 1;

  ${min('tablet')} {
    font-size: 2.8rem;
  }
`;

export const ShowInfo = styled.div`

`;

export const ArtistName = styled.div`
  color: ${({ theme }) => theme.colors['Black']};
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;

  ${min('tablet')} {
    font-size: 2rem;
  }
`;

export const VenueInfo = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors['Black']};

  ${min('tablet')} {
    font-size: 1.4rem;
  }
`;
