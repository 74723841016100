import { shallowEqual } from 'react-redux';
import moment from 'moment';
import { useAppSelector } from '../../../store';
// @ts-ignore
import CanvasJSReact from '@canvasjs/react-charts';

import { ModuleContainer, ChartContainer } from './styles';
import { useTheme } from 'styled-components';
import { Theme } from '../../../types';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const NewArtistsPerYear = () => {
  const theme = useTheme() as Theme;
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  let countArtistsPerYear: {
    [key: string]: number;
  } = {};
  let artistsAlreadySeen: string[] = [];

  if (!userShows?.length) {
    return null;
  }

  const sortedShows = [...userShows].sort((a, b) => {
    const aFormatted = a.date.split('/-').join();
    const bFormatted = b.date.split('/').join();
    return aFormatted < bFormatted ? -1 : aFormatted > bFormatted ? 1 : 0;
  });
  sortedShows.forEach((userShow) => {
    if (userShow.pending) {
      return;
    }
    const { artists, date } = userShow;
    artists.forEach((artist) => {
      if (artist.name && !artistsAlreadySeen.includes(artist.name)) {
        const showDate = moment(date);
        const year = `${showDate.year()}`;
        artistsAlreadySeen.push(artist.name);
        if (!countArtistsPerYear[year]) {
          countArtistsPerYear[year] = 0;
        }
        countArtistsPerYear[year]++;
      }
    });
  });

  const dataPoints = Object.keys(countArtistsPerYear).map((year) => ({
    x: new Date(parseInt(year), 0, 1),
    y: countArtistsPerYear[year],
  }));

  CanvasJS.addColorSet('goldTips', [theme.colors['GoldTips']]);

  const options = {
    theme: 'dark2',
    backgroundColor: 'transparent',
    colorSet: 'goldTips',
    axisX: {
      intervalType: 'year',
      interval: 1,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    axisY: {
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    data: [
      {
        xValueFormatString: 'YYYY',
        type: 'spline',
        dataPoints,
      },
    ],
  };

  return (
    <ModuleContainer>
      <h3>New artists per year</h3>
      <p>The number of new artists you discovered in shows each year</p>
      <ChartContainer>
        <CanvasJSChart options={options} />
      </ChartContainer>
    </ModuleContainer>
  );
};

export default NewArtistsPerYear;
