import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const SearchIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 73 72.9">
      <path
        d="M71.8,66L53.9,48.1c9-11.7,8.1-28.6-2.7-39.3C45.3,2.9,37.7,0,30,0S14.7,2.9,8.8,8.8c-11.7,11.7-11.7,30.7,0,42.4
	C14.7,57.1,22.3,60,30,60c6.4,0,12.8-2,18.1-6.1l18,17.8c0.8,0.8,1.8,1.2,2.9,1.2c1,0,2.1-0.4,2.9-1.2C73.4,70.2,73.4,67.6,71.8,66z
	 M30.1,51.9c-5.9,0-11.3-2.3-15.5-6.4c-8.5-8.5-8.5-22.4,0-31c4.1-4.1,9.6-6.4,15.5-6.4s11.3,2.3,15.5,6.4S52,24.1,52,30
	s-2.3,11.3-6.4,15.5S35.9,51.9,30.1,51.9z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default SearchIcon;
