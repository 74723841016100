import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const DateIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 80 77">
      <path
        d="M17.5,20c3,0,5.5-2.5,5.5-5.5v-9c0-3-2.5-5.5-5.5-5.5S12,2.5,12,5.5v9C12,17.5,14.5,20,17.5,20z M62.5,20
   c3,0,5.5-2.5,5.5-5.5v-9c0-3-2.5-5.5-5.5-5.5S57,2.5,57,5.5v9C57,17.5,59.5,20,62.5,20z M75,9h-4v6.5c0,4.7-3.8,8.5-8.5,8.5
   S54,20.2,54,15.5V9H26v6.5c0,4.7-3.8,8.5-8.5,8.5S9,20.2,9,15.5V9H5c-2.8,0-5,2.2-5,5v58c0,2.8,2.2,5,5,5h63l12-12V14
   C80,11.2,77.8,9,75,9z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default DateIcon;
