import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const CloseIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 19.2 19.2">
      <g>
        <path
          d="M18.8,0.3L18.8,0.3c0.4,0.4,0.4,1.1,0,1.4L11,9.6l0,0l7.8,7.8c0.4,0.4,0.4,1,0,1.4l0,0c-0.4,0.4-1,0.4-1.4,0L9.6,11l0,0
		l-7.8,7.8c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4l7.8-7.8l0,0L0.3,1.7c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0
		l7.8,7.8l0,0l7.8-7.8C17.8-0.1,18.5-0.1,18.8,0.3z"
          fill={color || theme.colors['Black']}
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
