import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const ShowIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 60">
      <rect x="5" y="14" width="2" height="7" fill={theme.colors['GoldTips']} />
      <rect x="14" y="5" width="8" height="2" fill={theme.colors['GoldTips']} />
      <path
        d="M1,5c0,1.1,0.4,2.1,1.2,2.8l0,0L1.5,8.5l1.4,1.4l0.7-0.7l2.1,2.1c0.4,0.4,1,0.4,1.4,0l4.2-4.2c0.4-0.4,0.4-1,0-1.4L9.3,3.6
	L10,2.9L8.6,1.5L7.8,2.2C6.6,0.9,4.6,0.6,3,1.6L1.7,0.3L0.3,1.7L1.6,3C1.2,3.6,1,4.3,1,5z M7.8,5l1.4,1.4L6.4,9.2L5,7.8L7.8,5z
	 M3.6,3.6c0.8-0.8,2-0.8,2.8,0L3.6,6.4l0,0C2.8,5.6,2.8,4.4,3.6,3.6z"
        fill={theme.colors['GoldTips']}
      />
      <rect
        x="12.5"
        y="10"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.5872 13.5106)"
        width="2"
        height="7.1"
        fill={theme.colors['GoldTips']}
      />
      <rect x="53" y="14" width="2" height="7" fill={theme.colors['GoldTips']} />
      <rect x="38" y="5" width="8" height="2" fill={theme.colors['GoldTips']} />
      <path
        d="M52.9,11.4c0.4,0.4,1,0.4,1.4,0l2.1-2.1l0.7,0.7l1.4-1.4l-0.7-0.7l0,0c1.3-1.3,1.5-3.3,0.6-4.8l1.3-1.3l-1.4-1.4L57,1.6
	c-1.6-0.9-3.6-0.7-4.8,0.6l-0.7-0.7l-1.4,1.4l0.7,0.7l-2.1,2.1c-0.4,0.4-0.4,1,0,1.4L52.9,11.4z M56.4,3.6c0.8,0.8,0.8,2,0,2.8l0,0
	l-2.8-2.8C54.4,2.8,55.6,2.8,56.4,3.6z M52.2,5L55,7.8l-1.4,1.4l-2.8-2.8L52.2,5z"
        fill={theme.colors['GoldTips']}
      />
      <rect
        x="43"
        y="12.5"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 4.0773 36.8452)"
        width="7.1"
        height="2"
        fill={theme.colors['GoldTips']}
      />
      <path
        d="M55.9,50.1c-0.5-2.7-3.1-4.5-5.8-4c-1.3,0.2-2.4,0.9-3.1,2c-1.6-2.2-4.7-2.7-6.9-1.1c-0.4,0.3-0.8,0.6-1.1,1.1c0,0,0,0,0,0
	h-1v-2.8l1.9-4.8c0-0.1,0.1-0.2,0.1-0.4V23c0-1.7-1.3-3-3-3c-1.7,0-3,1.3-3,3v6.2c-1-0.4-2.2-0.2-3,0.6c-0.8-0.8-2-1-3-0.6V23
	c0-1.7-1.3-3-3-3c-1.7,0-3,1.3-3,3v12.2l-2.6-1.6c-1.6-1.1-3.8-0.6-4.9,1c-1.1,1.6-0.6,3.8,1,4.9c0.1,0,0.1,0.1,0.2,0.1l6.6,4.1
	l0.8,1.5V48h-1c-0.3,0-0.6,0.1-0.8,0.4c-1.4-2.3-4.5-3.1-6.8-1.7c-0.6,0.3-1,0.8-1.4,1.3c-1.6-2.2-4.7-2.7-7-1.1
	c-1,0.8-1.7,1.9-2,3.1c-2.7,0.5-4.5,3.1-4,5.8s3.1,4.5,5.8,4C7.1,59.7,8.3,59,9,58c1.6,2.2,4.7,2.7,6.9,1.1c0.4-0.3,0.8-0.7,1.1-1.1
	c1.4,1.9,4,2.6,6.2,1.5c0.2,0.3,0.5,0.5,0.8,0.5h13c0.2,0,0.4-0.1,0.6-0.2c2,0.6,4.2-0.1,5.4-1.8c1.6,2.2,4.7,2.7,6.9,1.1
	c0.4-0.3,0.8-0.7,1.1-1.1c1.6,2.2,4.8,2.7,7,1c2.2-1.6,2.7-4.8,1-7C58.3,51,57.2,50.3,55.9,50.1z M32,32c0-0.6,0.4-1,1-1s1,0.4,1,1
	v2c0,0.6-0.4,1-1,1s-1-0.4-1-1V32z M28,33v-1c0-0.6,0.4-1,1-1s1,0.4,1,1v2c0,0.6-0.4,1-1,1s-1-0.4-1-1V33z M16.7,37.9
	c-0.7-0.5-0.9-1.4-0.5-2.1c0.3-0.5,0.8-0.7,1.3-0.7c0.3,0,0.6,0.1,0.8,0.2l4.1,2.6c0.5,0.3,1.1,0.2,1.4-0.3C24,37.4,24,37.2,24,37
	V23c0-0.6,0.4-1,1-1c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v11c0,1.6,1.3,3,3,3c0.7,0,1.5-0.3,2-0.8c1.2,1.1,3.1,1,4.2-0.2
	c0.5-0.6,0.8-1.3,0.8-2V23c0-0.6,0.4-1,1-1c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v16.8l-1.9,4.8c0,0.1-0.1,0.2-0.1,0.4v3
	h-5v-4.7c0-1.8-1-3.4-2.5-4.3l-1.2-0.7l-1,1.7l1.2,0.7c0.9,0.5,1.5,1.5,1.5,2.6V48h-4v-3c0-0.2,0-0.3-0.1-0.4l-1-2
	c-0.1-0.2-0.2-0.3-0.4-0.4L16.7,37.9z M5,58c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S6.7,58,5,58z M9,52c-0.7-0.9-1.7-1.6-2.9-1.9
	c0.5-1.6,2.1-2.5,3.7-2c1,0.3,1.7,1,2,2C10.7,50.4,9.7,51.1,9,52z M13,58c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S14.7,58,13,58z
	 M17,52c-0.7-0.9-1.7-1.6-2.9-1.9c0.5-1.6,2.1-2.5,3.7-2c1,0.3,1.7,1,2,2C18.7,50.4,17.7,51.1,17,52z M23,57.2
	c-1.2,1.1-3.1,1-4.2-0.2c-1.1-1.2-1-3.1,0.2-4.2c0.6-0.5,1.3-0.8,2-0.8v1c0,0.6,0.4,1,1,1h1V57.2z M36,58H25v-4h11V58z M37,52H23v-2
	h15v2H37z M39,58c-0.3,0-0.7-0.1-1-0.2V54h1c0.6,0,1-0.4,1-1v-0.8c1.6,0.5,2.4,2.3,1.8,3.8C41.4,57.2,40.3,58,39,58z M43,52
	c-0.7-0.9-1.7-1.6-2.9-1.9c0.5-1.6,2.1-2.5,3.7-2c1,0.3,1.7,1,2,2C44.7,50.4,43.7,51.1,43,52z M47,58c-1.7,0-3-1.3-3-3s1.3-3,3-3
	s3,1.3,3,3S48.7,58,47,58z M51,52c-0.7-0.9-1.7-1.6-2.9-1.9c0.5-1.6,2.1-2.5,3.7-2c1,0.3,1.7,1,2,2C52.7,50.4,51.7,51.1,51,52z
	 M55,58c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S56.7,58,55,58z"
        fill={color || theme.colors['Black']}
      />
      <rect x="47" y="26" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="44" y="29" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="50" y="29" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="47" y="32" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="5" y="35" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="2" y="38" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="8" y="38" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="5" y="41" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="12" y="20" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="10" y="22" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="14" y="22" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="12" y="24" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="54" y="38" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="52" y="40" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="56" y="40" width="2" height="2" fill={theme.colors['GoldTips']} />
      <rect x="54" y="42" width="2" height="2" fill={theme.colors['GoldTips']} />
      <path
        d="M22.2,16.4l1.7,1.1l3.8-5.6h4.8l3.8,5.6l1.7-1.1L34,10.8V8h1V6h-1c0-1.8-1.2-3.4-3-3.9V0h-2v2.1c-1.8,0.5-3,2-3,3.9h-1v2h1
	v2.8L22.2,16.4z M30,4c1.1,0,2,0.9,2,2h-4C28,4.9,28.9,4,30,4z M28,8h4v2h-4V8z"
        fill={theme.colors['GoldTips']}
      />
    </svg>
  );
};

export default ShowIcon;
