import { createContext, useState } from 'react';

const ALERT_TIME = 6000;
const initialState = {
  text: '',
  type: '',
  visible: false,
  disableAnimation: false,
};

const AlertContext = createContext({
  ...initialState,
  setAlert: (text: string, type: string, disableAnimation?: boolean) => {},
});

interface IAlertProvider {
  children: React.ReactNode;
}

export const AlertProvider = ({ children }: IAlertProvider) => {
  const [text, setText] = useState('');
  const [type, setType] = useState('');
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [visible, setVisible] = useState(false);

  const setAlert = (text: string, type: string, disableAnimation?: boolean) => {
    setText(text);
    setType(type);
    setVisible(true);
    setDisableAnimation(!!disableAnimation);

    setTimeout(() => {
      setVisible(false);
    }, ALERT_TIME);
    setTimeout(() => {
      setText('');
      setType('');
    }, ALERT_TIME + 200);
  };

  return (
    <AlertContext.Provider
      value={{
        text,
        type,
        visible,
        disableAnimation,
        setAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
