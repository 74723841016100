import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const UnseenIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 95 87.2">
      <path
        d="M91.1,3.7c0-1-0.4-1.9-1.1-2.6C89.3,0.4,88.4,0,87.4,0c-1,0-1.9,0.4-2.6,1.1L70,16C63.3,10.6,55,7.8,46.5,8.1
	c-8.5,0.2-16.7,3.5-23,9.2L3.8,35C1.4,37.2,0,40.3,0,43.6c0,3.3,1.4,6.4,3.8,8.6l15.7,14.2L5,81c-0.7,0.7-1.1,1.6-1.1,2.6
	c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.6,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1L90,6.2C90.7,5.6,91.1,4.6,91.1,3.7z M36.6,49.3
	c-1-1.8-1.5-3.7-1.5-5.7v0c0-4.3,2.2-8.4,5.9-10.6c3.7-2.2,8.3-2.3,12.1-0.3L36.6,49.3z M58.5,27.4L58.5,27.4
	C53.5,23.9,47.1,23,41.2,25c-5.8,1.9-10.4,6.5-12.4,12.4c-1.9,5.8-1,12.2,2.5,17.3l-6.6,6.6l-16-14.4c-0.9-0.8-1.4-2-1.4-3.2
	c0-1.2,0.5-2.4,1.4-3.2l0,0l19.7-17.7v0c5-4.5,11.3-7,18-7.3c6.7-0.3,13.2,1.8,18.5,5.8L58.5,27.4z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M95,43.6c0-3.3-1.4-6.4-3.8-8.6l-10.9-9.9c-1-0.9-2.3-1.2-3.5-0.8c-1.2,0.4-2.2,1.4-2.4,2.7c-0.3,1.3,0.2,2.6,1.1,3.5
	l10.9,9.9c0.9,0.8,1.4,2,1.4,3.2c0,1.2-0.5,2.4-1.4,3.2L66.6,64.6h0c-4.2,3.8-9.5,6.3-15.2,7.1c-5.7,0.8-11.4-0.1-16.5-2.6
	c-1.2-0.6-2.5-0.5-3.6,0.3c-1.1,0.7-1.7,2-1.6,3.3c0.1,1.3,0.9,2.4,2,3c6.4,3.1,13.6,4.3,20.7,3.3c7.1-1,13.7-4.1,19-8.9l19.7-17.7
	h0C93.6,50,95,46.9,95,43.6z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M61.7,40.5c-1.1,0.6-1.8,1.8-1.8,3.1c0,3.3-1.3,6.4-3.6,8.7c-2.3,2.3-5.5,3.6-8.7,3.6c-1.3,0-2.5,0.7-3.1,1.8
	c-0.6,1.1-0.6,2.5,0,3.6c0.6,1.1,1.8,1.8,3.1,1.8c5.2,0,10.2-2.1,13.9-5.7c3.7-3.7,5.7-8.7,5.7-13.9c0-1.3-0.7-2.5-1.8-3.1
	C64.2,39.8,62.8,39.8,61.7,40.5z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default UnseenIcon;
