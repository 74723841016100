import { useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';

import Loader from '../../Elements/Loader';
import SearchForm from './Form';
import SearchResults from './Results';

import { GreenButton } from '../../Elements/Buttons/styles';
import { SearchFormWrapper, ErrorMessage } from './styles';

interface ISearchFormProps {
  goToPreviousStep: () => void;
  goToNextStep: () => void;
}

const SearchShow = ({ goToPreviousStep, goToNextStep }: ISearchFormProps) => {
  const { searchSelectedResult } = useAppSelector(
    (state) => ({
      searchSelectedResult: state.form.search.selectedResult,
    }),
    shallowEqual,
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <SearchFormWrapper>
      {loading && <Loader />}
      <h3>Search for a show</h3>
      <p>All fields are optional. The more info you give, the most accurate the search will be.</p>
      <SearchForm loading={loading} setError={setError} setLoading={setLoading} goToPreviousStep={goToPreviousStep} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <SearchResults goToNextStep={goToNextStep} />
      {searchSelectedResult >= 0 && (
        <>
          <GreenButton onClick={goToNextStep}>Next</GreenButton>&nbsp;
        </>
      )}
    </SearchFormWrapper>
  );
};

export default SearchShow;
