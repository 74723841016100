import styled, { css, keyframes } from 'styled-components';
import { min } from '../../../theme/breakpoints';

interface IAlertProps {
  type: string;
  visible: boolean;
  disableAnimation: boolean;
}

const progressBarAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const Alert = styled.div<IAlertProps>`
  color: ${({ theme }) => theme.colors['Black']};
  position: fixed;
  right: 1.6rem;
  bottom: calc(8.4rem + env(safe-area-inset-bottom));
  z-index: 100;
  font-size: 1.4rem;
  background-color: ${({ theme, type }) => type === 'success' ? theme.colors['LightGreen'] : type === 'error' ? theme.colors['LightRed'] : theme.colors['WhiteRock']};
  padding: 1.2rem 1.6rem;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
  transition: .2s all ease-in-out;
  transform: ${({ visible }) => visible ? 'translateX(0)' : 'translateX(1rem)'};
  opacity: ${({ visible }) => visible ? 1 : 0};
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  overflow: hidden;

  ${min('tablet')} {
    font-size: 1.6rem;
    right: auto;
    left: 3.2rem;
    bottom: 3.2rem;
    padding: 1.6rem 2rem;
  }
  ${({ visible }) => visible && css<IAlertProps>`
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: ${({ theme }) => theme.colors['Black']};
      transform: translateX(-100%);
      ${({ disableAnimation }) => !disableAnimation && css`animation: ${progressBarAnimation} 6s linear`};
    }
  `}
`;
