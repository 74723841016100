import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store';
import { fetchCountries, fetchUserShows, setUserShows } from '../../../store/apiSlice';
import { TypeForm, resetAll, setFormStep } from '../../../store/formSlice';
import { IShow } from '../../../types';

import AdditionalInfoForm from '../../Forms/AdditionalInfo';
import ArtistsForm from '../../Forms/Artists';
import CreateVenue from '../../Forms/Venue';
import FullscreenCentered from '../FullscreenCentered';
import FuturePastForm from '../../Forms/FuturePast';
import Loader from '../../Elements/Loader';
import ModalContainer from '../../Modals/Container';
import NavbarDesktop from '../../Navbar/Desktop';
import NavbarMobile from '../../Navbar/Mobile';
import SearchShow from '../../Forms/Search';

import { AppContainer, PageContainer } from './styles';

interface IAppPageLayoutProps {
  centered?: boolean;
  children: React.ReactNode;
}

const AppPageLayout = ({ centered = true, children }: IAppPageLayoutProps) => {
  const dispatch = useAppDispatch() as AppDispatch;
  const { searchFormSelectedResult, stepForm, typeForm, userShows, futureEventForm } = useAppSelector(
    (state) => ({
      searchFormRequestPerformed: state.form.search.requestPerformed,
      searchFormSelectedResult: state.form.search.selectedResult,
      stepForm: state.form.step,
      typeForm: state.form.type,
      userShows: state.api.userShows,
      futureEventForm: state.form.future,
    }),
    shallowEqual,
  );

  const [loading, setLoading] = useState(true);
  const [formVisible, setFormVisible] = useState(false);

  useEffect(() => {
    if (formVisible) {
      document.getElementsByTagName('body')[0].setAttribute('style', 'overflow: hidden;');
    } else {
      document.getElementsByTagName('body')[0].removeAttribute('style');
    }
  }, [formVisible]);

  useEffect(() => {
    const fetchDefaultData = async () => {
      await dispatch(fetchCountries());
    };

    const fetchData = async () => {
      await fetchDefaultData();
      await dispatch(fetchUserShows());
      setLoading(false);
    };

    fetchData();

    document.addEventListener('openSearchForm', (() => {
      setFormVisible(true);
    }) as EventListener);

    return () => {
      document.removeEventListener('openSearchForm', (() => {
        setFormVisible(true);
      }) as EventListener);
    };
  }, [dispatch]);

  const handleShowSaved = async (show: IShow, typeForm: TypeForm) => {
    setLoading(true);
    if (typeForm === 'edit' && userShows) {
      const userShowsUpdated = userShows.map((userShow) => {
        if (userShow.id === show.id) {
          return show;
        }
        return userShow;
      });
      dispatch(setUserShows(userShowsUpdated));
    } else {
      await dispatch(fetchUserShows());
    }
    setFormVisible(false);
    setFormStep(0);
    setLoading(false);
  };

  const resetForm = () => {
    setLoading(true);
    dispatch(resetAll());
    dispatch(setFormStep(-1));
    setFormVisible(false);
    setLoading(false);
  };

  const renderFormStep = () => {
    switch (stepForm) {
      case -1:
        return null;
      case 0:
        return (
          <FuturePastForm
            goToPastEvent={() => dispatch(setFormStep(1))}
            goToFutureEvent={() => dispatch(setFormStep(2))}
          />
        );
      case 1:
        return (
          <SearchShow goToPreviousStep={() => dispatch(setFormStep(0))} goToNextStep={() => dispatch(setFormStep(2))} />
        );
      case 2:
        return (
          <ArtistsForm
            goToPreviousStep={() => dispatch(setFormStep(futureEventForm ? 0 : 1))}
            goToNextStep={() => {
              if (searchFormSelectedResult > -1 && typeForm !== 'edit') {
                dispatch(setFormStep(3));
              } else {
                dispatch(setFormStep(4));
              }
            }}
          />
        );
      case 3:
        return (
          <AdditionalInfoForm
            goToPreviousStep={() => {
              if (searchFormSelectedResult > -1 && typeForm !== 'edit') {
                dispatch(setFormStep(2));
              } else {
                dispatch(setFormStep(4));
              }
            }}
            showSaved={handleShowSaved}
          />
        );
      case 4:
        return (
          <CreateVenue
            goToPreviousStep={() => dispatch(setFormStep(2))}
            goToNextStep={() => dispatch(setFormStep(3))}
          />
        );
    }
  };

  return (
    <AppContainer>
      <NavbarMobile
        addFormVisible={formVisible}
        setFormVisible={() => {
          if (stepForm === -1) {
            dispatch(setFormStep(0));
          }
          setFormVisible(true);
        }}
      />
      <FullscreenCentered visible>
        <h1>{process.env.REACT_APP_NAME}</h1>
        <NavbarDesktop
          setFormVisible={() => {
            if (stepForm === -1) {
              dispatch(setFormStep(0));
            }
            setFormVisible(true);
          }}
        />
        <PageContainer centered={centered}>
          <ModalContainer visible={formVisible} withClose handleClose={() => resetForm()}>
            {renderFormStep()}
          </ModalContainer>
          {loading ? <Loader /> : children}
        </PageContainer>
      </FullscreenCentered>
    </AppContainer>
  );
};

export default AppPageLayout;
