import styled from 'styled-components';

export const ShowInfo = styled.div`
  width: 100%;
  margin-top: 3.2rem;

  h3 {
    margin-bottom: 0;
  }
`;
