import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const VenueIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 95 83.2">
      <path
        d="M94.8,33.1c-1.7-4.2-11.7-7.7-25.9-9.6c-0.7,5.5-5.7,9.7-11.9,9.7c-3.3,0-6.3-1.2-8.5-3.2c-2,3.4-5.9,5.6-10.5,5.6
	c-6.7,0-12-4.9-12-11.1c0-0.4,0-0.7,0.1-1.1C10.6,25.5,0,29.6,0,34.4c0,0.8,0.4,1.6,1,2.4c4.4,5.5,23.6,9.7,46.5,9.7
	c26.2,0,47.5-5.4,47.5-12.1C95,33.9,94.9,33.5,94.8,33.1z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M1.1,43.7l2.3,20.5l0.8,7.4c0.2,1.4,1.1,2.6,2.3,3.2c6.1,2.7,16.1,4.6,28,5.5c0.2,1.6,1.5,2.9,3.2,2.9h19.7
	c1.6,0,3-1.2,3.2-2.9c11.9-0.9,21.9-2.9,28-5.5c1.3-0.6,2.2-1.8,2.3-3.2l0.8-7.4L94,43.7c-13.2,7.9-42.3,8.2-46.5,8.2
	S14.2,51.6,1.1,43.7z M15.9,66c0,0.4-0.3,0.6-0.7,0.6c-1.6-0.2-3.2-0.5-4.7-0.8c-0.3-0.1-0.5-0.3-0.5-0.6v-5.4
	c0-0.4,0.3-0.7,0.7-0.6c1.5,0.3,3,0.6,4.7,0.9c0.3,0.1,0.5,0.3,0.5,0.6C15.9,60.7,15.9,66,15.9,66z M29.1,67.4
	c0,0.3-0.3,0.6-0.6,0.6c-1.6-0.1-3.2-0.2-4.7-0.4c-0.3,0-0.5-0.3-0.5-0.6v-5.3c0-0.4,0.3-0.6,0.6-0.6c1.5,0.1,3.1,0.3,4.7,0.4
	c0.3,0,0.6,0.3,0.6,0.6L29.1,67.4L29.1,67.4z M55.3,77.8H39.7l-1.3-13.2c5.7-2.9,12.5-2.9,18.2,0L55.3,77.8z M79.1,60.7
	c0-0.3,0.2-0.5,0.5-0.6c1.6-0.3,3.2-0.6,4.7-0.9c0.4-0.1,0.7,0.2,0.7,0.6v5.4c0,0.3-0.2,0.5-0.5,0.6c-1.5,0.3-3.1,0.6-4.7,0.8
	c-0.4,0.1-0.7-0.2-0.7-0.6V60.7z M65.9,62.1c0-0.3,0.2-0.6,0.6-0.6c1.6-0.1,3.2-0.2,4.7-0.4c0.4,0,0.6,0.2,0.6,0.6V67
	c0,0.3-0.2,0.6-0.5,0.6c-1.5,0.1-3.1,0.3-4.7,0.4c-0.4,0-0.6-0.2-0.6-0.6L65.9,62.1L65.9,62.1z"
        fill={color || theme.colors['Black']}
      />
      <path
        d="M38,30.3c3.7,0,6.6-2.5,6.6-5.7V11.3l13.3-1.7v7c-0.3,0-0.6,0-0.9,0c-3.7,0-6.6,2.5-6.6,5.7S53.3,28,57,28s6.6-2.5,6.6-5.7
	V4.8c0-1.4-0.6-2.7-1.6-3.6S59.6-0.1,58.2,0L43.1,2c-2.4,0.3-4.2,2.4-4.2,4.8v12.3c-4-0.4-7.5,2.2-7.5,5.6
	C31.4,27.8,34.3,30.3,38,30.3z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default VenueIcon;
