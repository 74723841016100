import { shallowEqual } from 'react-redux';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../../store';
import { setArtistsResults } from '../../../../store/formSlice';

import { DragHandleIcon, SeenIcon, UnseenIcon } from '../../../Elements/Icons';
import { ArtistsList, ArtistLabel, DragIconContainer, ArtistName, CheckIconContainer } from './styles';

const FormArtistList = () => {
  const dispatch = useAppDispatch() as AppDispatch;
  const { artists } = useAppSelector(
    (state) => ({
      artists: state.form.artists.results,
    }),
    shallowEqual,
  );

  const handleSeenArtistsChange = (position: number) => {
    const selectedArtist = { ...artists[position] };
    selectedArtist.seen = !selectedArtist.seen;
    const newArtists = [...artists];
    newArtists.splice(position, 1);
    newArtists.splice(position, 0, selectedArtist);
    dispatch(setArtistsResults(newArtists));
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    const extractedArtist = artists[source.index];
    const newArtists = [...artists];

    newArtists.splice(source.index, 1);
    newArtists.splice(destination.index, 0, extractedArtist);

    dispatch(setArtistsResults(newArtists));
  };

  return artists.length ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="artistList">
        {(droppableProvided) => (
          <ArtistsList ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
            {artists.map((artist, index) => {
              const id = artist.setlistFmId || artist.mbid || artist.id;
              return (
                id && (
                  <Draggable draggableId={id.toString()} index={index} key={`artist_${id}`}>
                    {(draggableProvided) => (
                      <ArtistLabel
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                        selected={artist.seen}
                        tabIndex={0}
                      >
                        <DragIconContainer {...draggableProvided.dragHandleProps}>
                          <DragHandleIcon />
                        </DragIconContainer>
                        <ArtistName>{artist.name}</ArtistName>
                        <label>
                          <input
                            type="checkbox"
                            checked={artist.seen}
                            onChange={() => handleSeenArtistsChange(index)}
                          />
                          <CheckIconContainer selected={artist.seen}>
                            {artist.seen ? <SeenIcon /> : <UnseenIcon />}
                          </CheckIconContainer>
                        </label>
                      </ArtistLabel>
                    )}
                  </Draggable>
                )
              );
            })}
            {droppableProvided.placeholder}
          </ArtistsList>
        )}
      </Droppable>
    </DragDropContext>
  ) : null;
};

export default FormArtistList;
