import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';
import { isPastShow } from '../../../utils/date';

import { SmallModuleContainer, Results, BigNumber } from '../styles';

const TotalPastArtistsModule = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const pastShows = userShows?.filter((userShow) => isPastShow(userShow));
  const artistsUnique: string[] = [];
  pastShows?.forEach((userShow) => {
    userShow.artists.forEach((artist) => {
      if (artist.name && !artistsUnique.includes(artist.name)) {
        artistsUnique.push(artist.name);
      }
    });
  });
  const nbArtists = artistsUnique.length;

  return (
    <SmallModuleContainer>
      <Results center>
        you saw
        <BigNumber>{nbArtists || 0}</BigNumber>
        artist{nbArtists > 1 && 's'}
      </Results>
    </SmallModuleContainer>
  );
};

export default TotalPastArtistsModule;
