import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const DeleteIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 75.9 95">
      <g>
        <path
          d="M10.4,90.1c0.3,2.8,2.6,4.9,5.4,4.9h44.4c2.8,0,5.1-2.1,5.4-4.9l5-65.7H5.4L10.4,90.1z M48.9,40.7c0-1.5,1.2-2.7,2.7-2.7
			c1.5,0,2.7,1.2,2.7,2.7v38c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7V40.7z M35.3,40.7c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7
			v38c0,1.5-1.2,2.7-2.7,2.7s-2.7-1.2-2.7-2.7V40.7z M21.7,40.7c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7v38c0,1.5-1.2,2.7-2.7,2.7
			s-2.7-1.2-2.7-2.7V40.7z"
          fill={color || theme.colors['Black']}
        />
        <path
          d="M70.6,8.1H48.9V5.4c0-3-2.4-5.4-5.4-5.4H32.6c-3,0-5.4,2.4-5.4,5.4v2.7H5.4c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4h65.1
			c3,0,5.4-2.4,5.4-5.4S73.6,8.1,70.6,8.1z"
          fill={color || theme.colors['Black']}
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
