import { useState } from 'react';
import Api from '../../../helpers/Api';
import { fetchUserShows } from '../../../store/apiSlice';
import { AppDispatch, useAppDispatch } from '../../../store';
import { IShow } from '../../../types';
import useAlert from '../../../hooks/useAlert';

import Loader from '../../Elements/Loader';
import ModalContainer from '../Container';
import ShowCard from '../../ShowItem/Card';

import { GreenButton, GreyButton, RedButton } from '../../Elements/Buttons/styles';
import { ErrorMessage, ShowCardContainer, UserShowActions } from '../styles';
import { ShowInfo } from './styles';

interface IModalReviewShows {
  editUserShow: (userShow: IShow) => void;
  listShows: IShow[];
  onClose: () => void;
}

const ModalReviewShows = ({ editUserShow, listShows, onClose }: IModalReviewShows) => {
  const { setAlert } = useAlert();
  const dispatch = useAppDispatch() as AppDispatch;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleDeleteUserShow = async (userShow: IShow) => {
    setLoading(true);
    Api.request(`/shows/${userShow.id}`, {}, 'delete')
      .then(async () => {
        setAlert('Show removed.', 'success');
        await dispatch(fetchUserShows());
        setLoading(false);
      })
      .catch((response) => {
        const errorMessage = response?.data?.error?.message;

        if (errorMessage) {
          setError(errorMessage);
        } else {
          setError('An error occured, please try again or contact the administrator.');
        }
        setLoading(false);
      });
  };

  return (
    <ModalContainer withClose handleClose={() => onClose()} visible zIndex={9}>
      {loading && <Loader />}
      <>
        <h2>Validate shows</h2>
        <p>
          We see that you have registered shows that happened since your last time on the app.
          <br />
          Take some time to confirm / review them.
        </p>
        <p>
          <GreyButton onClick={() => onClose()}>Not now</GreyButton>
        </p>
        {listShows.map((userShow, index) => (
          <ShowInfo key={`confirm_pending_show_${userShow.id}`}>
            <h3>
              Show {index + 1}/{listShows.length}
            </h3>
            <ShowCardContainer>
              <ShowCard key={`confirm_pending_show_${userShow.id}`} userShow={userShow} />
            </ShowCardContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <UserShowActions>
              <GreenButton onClick={() => editUserShow(userShow)}>Review</GreenButton>&nbsp;
              <RedButton onClick={() => handleDeleteUserShow(userShow)}>Delete</RedButton>
            </UserShowActions>
          </ShowInfo>
        ))}
      </>
    </ModalContainer>
  );
};

export default ModalReviewShows;
