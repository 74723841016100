import styled from 'styled-components';
import { min } from '../../../theme/breakpoints';

export const GridWrapper = styled.div`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto 3.2rem;
  padding: 0;
`;

export const Grid = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  ${min('tablet')} {
    gap: 3.2rem;
  }
`;

