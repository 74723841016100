import colors from './colors';
import breakpoints from './breakpoints';
import { Theme } from '../types/theme';

const theme: Theme = {
  colors,
  breakpoints,
  fonts: {
    montserrat: "'Montserrat', sans-serif",
    young: "'Young Serif', serif",
    robotoCondensed: "'Roboto Condensed', sans-serif",
    libreBaskerville: "'Libre Baskerville', serif",
  }
};

export default theme;
