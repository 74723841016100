import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';
import { isPastShow } from '../../../utils/date';

import { Item, Count, FixedModuleContainer, Results, Info, MainInfo } from '../styles';

const TopCountriesModule = () => {
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  let countCountries: {
    name: string;
    count: number;
  }[] = [];
  userShows?.forEach((userShow) => {
    if (isPastShow(userShow)) {
      const indexFoundInCount = countCountries.findIndex(
        (countryCount) => countryCount.name === userShow.venue.countryName,
      );
      if (indexFoundInCount > -1) {
        countCountries[indexFoundInCount].count += 1;
      } else {
        countCountries.push({
          name: userShow.venue.countryName,
          count: 1,
        });
      }
    }
  });

  countCountries.sort((a, b) => b.count - a.count);

  return countCountries.length ? (
    <FixedModuleContainer>
      <h3>Top countries</h3>
      <Results>
        {countCountries.map((countryCount) => (
          <Item key={`top_countries_${countryCount.name}`}>
            <Info>
              <MainInfo>{countryCount.name}</MainInfo>
            </Info>
            <Count>{countryCount.count}</Count>
          </Item>
        ))}
      </Results>
    </FixedModuleContainer>
  ) : null;
};

export default TopCountriesModule;
