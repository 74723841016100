import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const LocationIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 39.4 60.1">
      <path
        d="M20.9,59.3c2.9-2.9,17.3-18.5,18.4-37.4c0-0.7,0.1-1.4,0.1-2.2C39.3,8.8,30.5,0,19.7,0S0,8.8,0,19.7c0,0.8,0,1.5,0.1,2.2
	c1.1,18.9,15.5,34.4,18.4,37.4c0,0,0.1,0.1,0.2,0.2l0,0c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.1,0.1c0.2,0.2,0.5,0.3,0.7,0.3
	s0.5-0.1,0.7-0.3c0,0,0,0,0.1-0.1c0.1,0,0.1-0.1,0.2-0.2C20.7,59.4,20.8,59.3,20.9,59.3z M19.7,30.4C13.8,30.4,9,25.6,9,19.7
	S13.7,8.9,19.7,8.9s10.7,4.8,10.7,10.7S25.6,30.4,19.7,30.4z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default LocationIcon;
