import styled from 'styled-components';
import { min } from '../../../theme/breakpoints';


export const NavbarMobileContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  width: 100%;
  background-color: ${({ theme }) => theme.colors['WoodsmokePrimary']};

  ${min('tablet')} {
    display: none;
  }
  a {
    width: 25%;
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.fonts.montserrat};
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 1rem 1rem calc(1.6rem + env(safe-area-inset-bottom));
    text-align: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors['White']};

    ${min('mobile')} {
      font-size: 1.2rem;
    }
    &.selected {
      color: ${({ theme }) => theme.colors['GoldTips']};

      svg {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
    svg {
      display: block;
      width: 2.6rem;
      height: 2.6rem;
      margin: auto;
    }
  }
`;
