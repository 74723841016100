import styled from 'styled-components';
import { min } from '../../../theme/breakpoints';

export const ButtonTile = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.colors['White']};
  border: 2px solid ${({ theme }) => theme.colors['MineShaft']};
  margin: 1.6rem;

  svg {
    display: block;
    width: 16rem;
    margin-bottom: 1.6rem;

    ${min('tablet')} {
      width: 20rem;
    }
  }
`;
