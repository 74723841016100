import React from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store';
import { formatPriceWithCurrency } from '../../../utils/price';

import { ModuleContainer, Results, MediumNumber } from '../styles';

const TotalCurrencyPrice = () => {
  const { countries, userShows } = useAppSelector(
    (state) => ({
      countries: state.api.countries,
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  let currenciesWithTotalArray: {
    currency: string;
    total: number;
  }[] = [];
  userShows?.forEach((userShow) => {
    const currentShowCountries = countries.filter((country) => country.code === userShow.venue.countryCode);
    if (userShow.price && currentShowCountries.length === 1) {
      const currentShowCurrency = currentShowCountries[0].currency;
      if (
        currenciesWithTotalArray.filter((currencyWithTotal) => currencyWithTotal.currency === currentShowCurrency)
          .length === 0
      ) {
        currenciesWithTotalArray.push({
          currency: currentShowCurrency,
          total: userShow.price,
        });
      } else {
        currenciesWithTotalArray = currenciesWithTotalArray.map((currencyWithTotal) => {
          if (currencyWithTotal.currency === currentShowCurrency) {
            currencyWithTotal.total += userShow.price as number;
          }
          return currencyWithTotal;
        });
      }
    }
  });

  return (
    <ModuleContainer>
      <Results center>
        you spent
        {currenciesWithTotalArray.map((currencyWithTotal, index) => (
          <React.Fragment key={`currencyWithTotal_${currencyWithTotal.currency}`}>
            <MediumNumber>
              {formatPriceWithCurrency(currencyWithTotal.total, currencyWithTotal.currency)}
            </MediumNumber>
            {index < currenciesWithTotalArray.length - 1 && '+'}
          </React.Fragment>
        ))}
        in total
      </Results>
    </ModuleContainer>
  );
};

export default TotalCurrencyPrice;
