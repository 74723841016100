import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { MultiValue } from 'react-select';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../store';
import { isMobile } from '../../utils';
import {
  setArtistsRequestPerformed,
  setArtistsResults,
  setFormStep,
  setFormType,
  setSearchResults,
  setSelectedSearchResult,
  setSelectedVenueResult,
  setVenueRequestPerformed,
  setVenueResults,
  setVenueValues,
} from '../../store/formSlice';
import { DisplayType, SortOptions, IShow, Theme, IOption } from '../../types';

import AppPageLayout from '../../components/Layout/AppPage';
import ListActions from '../../components/List/Toolbar/Actions';
import ListFilters from '../../components/List/Toolbar/Filters';
import ListGrid from '../../components/List/Grid';
import ListTable from '../../components/List/Table';
import ListTickets from '../../components/List/Tickets';
import Loader from '../../components/Elements/Loader';
import ModalDeleteUserShow from '../../components/Modals/DeleteUserShow';
import ModalDetailsUserShow from '../../components/Modals/DetailsUserShow';
import ModalReviewShows from '../../components/Modals/ReviewShows';
import { SadCactusIcon, ShowIcon } from '../../components/Elements/Icons';

import { IconContainer, NumberResult, Number, NoResult } from './styles';
import NextShow from '../../components/List/NextShow';

interface IFilters {
  artists: number[];
  cities: string[];
  countries: string[];
  venues: number[];
  years: number[];
}

const initialStateFilters = {
  artists: [],
  cities: [],
  countries: [],
  venues: [],
  years: [],
};

const HomePage = () => {
  const dispatch = useAppDispatch() as AppDispatch;
  const theme = useTheme() as Theme;
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const nbElementsPerPage = 20;
  const [page, setPage] = useState(1);
  const [display, setDisplay] = useState<DisplayType>(
    (localStorage.getItem('listDisplay') as DisplayType) || 'tickets',
  );
  const [sort, setSort] = useState<SortOptions>((localStorage.getItem('listSort') as SortOptions) || 'desc');
  const [displayReviewShows, setDisplayReviewShows] = useState<boolean>(true);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [listFilters, setListFilters] = useState<IFilters>(initialStateFilters);
  const [loading, setLoading] = useState(false);
  const [deleteUserShow, setDeleteUserShow] = useState<IShow | null>(null);
  const [detailsUserShow, setDetailsUserShow] = useState<IShow | null>(null);

  const handleChangeDisplay = (display: DisplayType) => {
    if (localStorage.getItem('listDisplay') !== display) {
      localStorage.setItem('listDisplay', display);
      setDisplay(display);
    }
  };

  // If we detect that we are on a mobile, we force the display to tickets view
  useEffect(() => {
    if (isMobile) {
      handleChangeDisplay('tickets');
    }
  }, [isMobile]);

  // Everytime the filters are updated, we go back to page 1
  useEffect(() => {
    setPage(1);
  }, [listFilters]);

  const handleSort = () => {
    const newSortValue = sort === 'asc' ? 'desc' : 'asc';
    localStorage.setItem('listSort', newSortValue);
    setSort(newSortValue);
  };

  useEffect(() => {
    if (detailsUserShow && userShows?.length) {
      const showsFromList = userShows.filter((show) => show.id === detailsUserShow.id);
      if (showsFromList.length === 1) {
        setDetailsUserShow(showsFromList[0]);
      }
    }
  }, [userShows]);

  const sortedUserShows = userShows?.length
    ? [...userShows].sort((a, b) => {
        const aFormatted = a.date.split('/-').join();
        const bFormatted = b.date.split('/').join();
        if (sort === 'asc') {
          return aFormatted < bFormatted ? -1 : aFormatted > bFormatted ? 1 : 0;
        }
        return aFormatted < bFormatted ? 1 : aFormatted > bFormatted ? -1 : 0;
      })
    : [];

  const setUserShowForEdition = (show: IShow) => {
    setLoading(true);
    const { artists, venue } = show;
    const clonedArtists = [...artists].map((artist) => ({
      ...artist,
      seen: true,
    }));
    const formattedUserShow: IShow = {
      ...show,
      source: 'setlist.fm',
    };
    dispatch(setFormType('edit'));
    dispatch(setArtistsRequestPerformed(false));
    dispatch(setSearchResults([]));
    dispatch(setArtistsResults([]));
    dispatch(setVenueResults([]));
    dispatch(setVenueRequestPerformed(true));
    dispatch(setSearchResults([formattedUserShow]));
    dispatch(setSelectedSearchResult(0));
    dispatch(setArtistsResults(clonedArtists));
    dispatch(setVenueResults([venue]));
    dispatch(
      setVenueValues({
        cityName: venue.cityName,
        country: {
          label: venue.countryName,
          value: venue.countryCode,
        },
        name: venue.name,
      }),
    );
    dispatch(setSelectedVenueResult(0));
    dispatch(setFormStep(2));
    setLoading(false);
    const openSearchFormEvent = new CustomEvent('openSearchForm');
    document.dispatchEvent(openSearchFormEvent);
  };

  const resetFilterList = () => {
    setPage(1);
    setListFilters(initialStateFilters);
  };

  const filterList = (filter: { values: MultiValue<IOption>; name: string }) => {
    const { name, values } = filter;
    setListFilters({
      ...listFilters,
      [name]: values.map((value) => value.value),
    });
  };

  let filteredUserShows = [...sortedUserShows];
  let filtersActive = false;

  if (listFilters.artists.length && filteredUserShows.length) {
    filtersActive = true;
    filteredUserShows = filteredUserShows.filter((userShow) => {
      return listFilters.artists.find((artist) =>
        userShow.artists.find((artistFromShow) => artistFromShow.id === artist),
      );
    });
  }

  if (listFilters.venues.length && filteredUserShows.length) {
    filtersActive = true;
    filteredUserShows = filteredUserShows.filter((userShow) => {
      return listFilters.venues.find((venue) => venue === userShow.venue.id);
    });
  }

  if (listFilters.cities.length && filteredUserShows.length) {
    filtersActive = true;
    filteredUserShows = filteredUserShows.filter((userShow) => {
      return listFilters.cities.find((city) => city === userShow.venue.cityName);
    });
  }

  if (listFilters.countries.length && filteredUserShows.length) {
    filtersActive = true;
    filteredUserShows = filteredUserShows.filter((userShow) => {
      return listFilters.countries.find((country) => country === userShow.venue.countryCode);
    });
  }

  if (listFilters.years.length && filteredUserShows.length) {
    filtersActive = true;
    filteredUserShows = filteredUserShows.filter((userShow) => {
      return listFilters.years.find((year) => {
        const yearFromShow = parseInt(userShow.date.split('-')[0]);
        return yearFromShow === year;
      });
    });
  }

  const nbActiveFilters =
    listFilters.artists.length +
    listFilters.venues.length +
    listFilters.cities.length +
    listFilters.countries.length +
    listFilters.years.length;

  const pastPendingShowsInUserShows =
    userShows?.filter((userShow: IShow) => userShow.pending && moment(userShow.date).isBefore(moment(), 'day')) ?? [];

  return (
    <AppPageLayout centered={false}>
      {loading ? (
        <Loader />
      ) : filteredUserShows?.length || (!filteredUserShows?.length && filtersActive) ? (
        <>
          {pastPendingShowsInUserShows.length > 0 && displayReviewShows && (
            <ModalReviewShows
              editUserShow={setUserShowForEdition}
              listShows={pastPendingShowsInUserShows}
              onClose={() => setDisplayReviewShows(false)}
            />
          )}
          <ModalDeleteUserShow
            close={() => setDeleteUserShow(null)}
            userShow={deleteUserShow}
            setLoading={setLoading}
            setDeleteUserShow={setDeleteUserShow}
            setDetailsUserShow={setDetailsUserShow}
          />
          <ModalDetailsUserShow
            close={() => setDetailsUserShow(null)}
            details={detailsUserShow}
            editUserShow={setUserShowForEdition}
            setDeleteUserShow={setDeleteUserShow}
          />
          {userShows && (
            <NextShow
              items={userShows}
              editUserShow={setUserShowForEdition}
              setDeleteUserShow={setDeleteUserShow}
              setDetailsUserShow={setDetailsUserShow}
            />
          )}
          <ListActions
            display={display}
            sort={sort}
            handleChangeDisplay={handleChangeDisplay}
            handleSort={handleSort}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
          />
          <ListFilters
            filterList={filterList}
            nbActiveFilters={nbActiveFilters}
            resetFilterList={resetFilterList}
            showFilters={showFilters}
          />
          {filteredUserShows?.length && nbActiveFilters > 0 ? (
            <NumberResult>
              <Number>{filteredUserShows.length}</Number> result{filteredUserShows.length > 1 && 's'}
            </NumberResult>
          ) : nbActiveFilters > 0 ? (
            <NoResult>
              <SadCactusIcon />
              <p>There's no result with those filters</p>
            </NoResult>
          ) : null}
          {display === 'list' ? (
            <ListTable
              items={filteredUserShows.slice(0, page * nbElementsPerPage)}
              editUserShow={setUserShowForEdition}
              setDeleteUserShow={setDeleteUserShow}
              setDetailsUserShow={setDetailsUserShow}
            />
          ) : display === 'tickets' ? (
            <ListTickets
              items={filteredUserShows.slice(0, page * nbElementsPerPage)}
              editUserShow={setUserShowForEdition}
              setDeleteUserShow={setDeleteUserShow}
              setDetailsUserShow={setDetailsUserShow}
            />
          ) : (
            <ListGrid
              items={filteredUserShows.slice(0, page * nbElementsPerPage)}
              editUserShow={setUserShowForEdition}
              setDeleteUserShow={setDeleteUserShow}
              setDetailsUserShow={setDetailsUserShow}
            />
          )}
          {page < Math.ceil(filteredUserShows.length / nbElementsPerPage) && (
            <div>
              <button onClick={() => setPage(page + 1)}>Load more</button>
            </div>
          )}
        </>
      ) : (
        <>
          <IconContainer>
            <ShowIcon color={theme.colors['Casper']} />
          </IconContainer>
          <p>No show to display for the moment. Add your first now!</p>
        </>
      )}
    </AppPageLayout>
  );
};

export default HomePage;
