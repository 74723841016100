import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const SearchOutlineIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 75 75">
      <path
        d="M31.5,4c7.1,0,14,2.7,19.5,8.1c9.9,9.8,10.7,25.4,2.5,36.1l16.4,16.5c1.5,1.5,1.5,3.9,0.1,5.2c-0.7,0.7-1.7,1.1-2.7,1.1
	c-1,0-1.9-0.4-2.7-1.1L48.1,53.5c-4.9,3.8-10.7,5.6-16.6,5.6c-7.1,0-14-2.7-19.5-8.1c-10.7-10.8-10.7-28.2,0-39
	C17.5,6.7,24.4,4,31.5,4 M31.6,51.7c5.3,0,10.5-2,14.2-5.9c3.8-3.9,5.9-8.8,5.9-14.3c0-5.4-2-10.5-5.9-14.3
	c-3.9-3.8-8.8-5.9-14.2-5.9c-5.4,0-10.5,2.1-14.2,5.9c-7.8,7.9-7.8,20.7,0,28.5C21.2,49.6,26.2,51.7,31.6,51.7 M31.5,0
	C23.1,0,15.2,3.3,9.2,9.3C3.3,15.2,0,23.1,0,31.6s3.3,16.4,9.2,22.3c6,6,13.9,9.3,22.3,9.3c5.7,0,11.3-1.6,16.1-4.5l14.2,14.1
	c1.4,1.4,3.4,2.3,5.5,2.3c2,0,4-0.8,5.5-2.3c3-3,2.9-7.9-0.1-10.9L58.6,47.7c7.4-12.2,5.5-28.2-4.8-38.5C47.8,3.3,39.9,0,31.5,0
	L31.5,0z M31.6,47.7c-4.3,0-8.3-1.6-11.4-4.7c-6.2-6.2-6.2-16.5,0.1-22.8c3-3,7.1-4.7,11.4-4.7c4.3,0,8.3,1.6,11.4,4.7
	c3,2.9,4.7,7,4.7,11.4c0,4.3-1.6,8.3-4.7,11.5C40,46.1,36,47.7,31.6,47.7L31.6,47.7z"
        fill={color || theme.colors['Black']}
      />
    </svg>
  );
};

export default SearchOutlineIcon;
