import { useTheme } from 'styled-components';
import { IIcon, Theme } from '../../../types';

const TicketFutureIcon = ({ color }: IIcon) => {
  const theme = useTheme() as Theme;
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64.9 53">
      <g>
        <path
          d="M61.5,24.4C61.5,24.4,61.5,24.4,61.5,24.4c-4.1,1.5-8.7-0.6-10.2-4.8c-1.5-4.1,0.6-8.7,4.8-10.2c0,0,0,0,0,0L52.6,0
	L35.7,6.2l0,0L0,19.1l3.4,9.4c0,0,0,0,0,0c4.1-1.5,8.7,0.7,10.2,4.8c1.5,4.1-0.6,8.7-4.8,10.2c0,0,0,0,0,0l3.4,9.4L48,40l16.9-6.1
	L61.5,24.4z M35.9,12.5c-0.4-1,0.2-2.2,1.2-2.6l0,0c0,0,0,0,0,0c1-0.4,2.2,0.2,2.6,1.2c0.4,1-0.2,2.2-1.2,2.6c0,0,0,0,0,0l0,0
	C37.4,14.1,36.3,13.5,35.9,12.5z M38.6,20c-0.4-1,0.2-2.2,1.2-2.6l0,0c0,0,0,0,0,0c1-0.4,2.2,0.2,2.6,1.2c0.4,1-0.2,2.2-1.2,2.6
	c0,0,0,0,0,0l0,0C40.1,21.6,39,21,38.6,20z M41.4,27.5c-0.4-1,0.2-2.2,1.2-2.6l0,0c0,0,0,0,0,0c1-0.4,2.2,0.2,2.6,1.2
	c0.4,1-0.2,2.2-1.2,2.6c0,0,0,0,0,0l0,0C42.9,29.1,41.7,28.6,41.4,27.5z M46.6,36.2c-1,0.4-2.2-0.2-2.6-1.2c-0.4-1,0.2-2.2,1.2-2.6
	l0,0c0,0,0,0,0,0c1-0.4,2.2,0.2,2.6,1.2C48.2,34.7,47.7,35.8,46.6,36.2C46.7,36.2,46.7,36.2,46.6,36.2L46.6,36.2z"
          fill={color || theme.colors['Black']}
        />
      </g>
    </svg>
  );
};

export default TicketFutureIcon;
