import { Dispatch, SetStateAction } from 'react';
import { useTheme } from 'styled-components';

import { DisplayType, SortOptions, Theme } from '../../../../types';
import { isMobile } from '../../../../utils';

import {
  ListGridIcon,
  ListTableIcon,
  ListTicketsIcon,
  SearchOutlineIcon,
  SortDateOutlineIcon,
} from '../../../Elements/Icons';

import { NoStyleButton } from '../../../Elements/Buttons/styles';
import { ActionsContainer, ListDisplayContainer, Actions, SortContainer } from './styles';

interface IListActionsProps {
  display: DisplayType;
  sort: SortOptions;
  handleChangeDisplay: (display: DisplayType) => void;
  handleSort: () => void;
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
}

const ListActions = ({
  display,
  sort,
  handleChangeDisplay,
  handleSort,
  showFilters,
  setShowFilters,
}: IListActionsProps) => {
  const theme = useTheme() as Theme;
  return (
    <ActionsContainer>
      <ListDisplayContainer isMobile={isMobile}>
        {!isMobile && (
          <Actions>
            <NoStyleButton type="button" onClick={() => handleChangeDisplay('grid')} aria-label="Display items by grid">
              <ListGridIcon color={display === 'grid' ? theme.colors['GoldTips'] : theme.colors['SilverChalice']} />
            </NoStyleButton>
            <NoStyleButton
              type="button"
              onClick={() => handleChangeDisplay('tickets')}
              aria-label="Display items by tickets list"
            >
              <ListTicketsIcon
                color={display === 'tickets' ? theme.colors['GoldTips'] : theme.colors['SilverChalice']}
              />
            </NoStyleButton>
            <NoStyleButton
              type="button"
              onClick={() => handleChangeDisplay('list')}
              aria-label="Display items by table"
            >
              <ListTableIcon color={display === 'list' ? theme.colors['GoldTips'] : theme.colors['SilverChalice']} />
            </NoStyleButton>
          </Actions>
        )}
        <SortContainer>
          <NoStyleButton
            type="button"
            onClick={() => handleSort()}
            aria-label={`Sort by date (${sort === 'asc' ? 'ascending' : 'descending'})`}
          >
            <SortDateOutlineIcon sort={sort} color={theme.colors['SilverChalice']} />
          </NoStyleButton>
          <NoStyleButton type="button" onClick={() => setShowFilters(!showFilters)} aria-label={`Filter list`}>
            <SearchOutlineIcon color={showFilters ? theme.colors['GoldTips'] : theme.colors['SilverChalice']} />
          </NoStyleButton>
        </SortContainer>
      </ListDisplayContainer>
    </ActionsContainer>
  );
};

export default ListActions;
