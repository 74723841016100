import styled from 'styled-components';
import { min } from '../../../theme/breakpoints';

export const ModuleContainer = styled.div`
`;

export const ChartContainer = styled.div`
  width: 100%;
  padding: 1.6rem;
  border-radius: 1.6rem;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
  max-width: 162.8rem;
  margin: auto;

  ${min('tablet')} {
    padding: 3.2rem;
  }
`;
